import React, { Component } from 'react';
import {NavLink, withRouter} from "react-router-dom"
import {connect} from "react-redux";
import {logout, discover_pressed, open_nav, close_nav} from "../../actions"

class Nav_header extends Component {
    state = {  }

    onPress_link = () => {
        this.props.close_nav()
    }

    render_authed_links(){
        const {logged_in} = this.props;
        const {role} = this.props.user;

        if(logged_in === false){
            return (
               
                <ul className={`auth-links ${this.props.nav_open?"open":""}`}>
                    <li>
                        <NavLink className="link" exact to="/auth/login">Log In</NavLink>
                    </li>

                    <li>
                        <NavLink className="link" exact to="/auth/register">Register</NavLink>
                    </li>
                </ul>
            );
        }else if(logged_in === true){
            if(role){
                
                if(role.indexOf("admin") !== -1){
                    return (
                        <ul className={`auth-links logged-in admin ${this.props.nav_open?"open":""}`}>
                            <li>
                                <NavLink className="link" exact to="/admin/maps">Admin</NavLink>
                            </li>
                            <li>
                                <NavLink onClick={() => {this.props.logout()}} className="link" exact to="/">Logout</NavLink>
                            </li>
                        </ul>
                    )
                }
            }
            return (
                <ul className={`auth-links logged-in ${this.props.nav_open?"open":""}`}>
                    <li>
                        <NavLink className="link" exact to="/admin/index">Dashboard</NavLink>
                    </li>

                    <li>
                        <NavLink className="link" exact to="/admin/jobs">Jobs</NavLink>
                    </li>

                    <li>
                        <NavLink className="link" exact to="/admin/user-profile">Profile</NavLink>
                    </li>
                </ul>
            );
        }else{
            return (
                <ul className={`auth-links null ${this.props.nav_open?"open":""}`}>
                    ......
                </ul>
            );
        }
    }

    onPress_discover_link = (e) => {
        this.props.discover_pressed();
    }

    onPress_toggle = () => {
        if(this.props.nav_open){
            this.props.close_nav()
        }else{
            this.props.open_nav();
        }
    }

    render() { 
        return (
            <div className="nav-header-wrapper clearfix">
                <div className="nav-header">
                    <div className="left nav"> 
                    <div className="logo"><img src="/assets/images/Group 52@2x.png" style={{cursor: "pointer"}} onClick={() => {
                            this.props.history.push("/");
                        }}/>

                        

                        </div>
                        <i onClick={this.onPress_toggle} className="fas fa-bars mobile-sidebar-toggle" />
                    </div>

                    <div className="right auth" >
                          <ul className={`nav-links ${this.props.nav_open?"open":""}`}>
                            <li>
                                <NavLink className="link" exact to="/">Home</NavLink>
                            </li>

                            <li>
                                <NavLink onClick={this.onPress_discover_link} className="link" exact to="/discover">Discover</NavLink>
                            </li>
                            {/* <li>
                                <NavLink className="link" to="/leaderboard">Leaderboard</NavLink>
                            </li> */}
                            {/* <li>
                                <NavLink className="link" to="/mobile_app">App</NavLink>
                            </li>
                            <li>
                                <NavLink className="link" to="/contact">Contact</NavLink>
                            </li>
                            <li>
                                <NavLink className="link" to="/about">About</NavLink>
                            </li> */}
                             {this.render_authed_links() } 
                        </ul>
                       
                    </div>

                   
                </div>
            </div>
        );
    }
}

function map_state_to_props({auth, user, app}){
    return {logged_in: auth.logged_in, user, nav_open: app.open_nav};
}
 
export default withRouter(connect(map_state_to_props, {logout, discover_pressed, open_nav, close_nav})(Nav_header));