import React, { Component } from 'react';
import RMT_header from "../RMT_header";

class App extends Component {
    state = {  }

    render() { 
        return (
            <>
                <RMT_header />
                <h1>GET APP</h1>
            </>
        );
    }
}

export default App;