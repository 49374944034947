import {JOB_TYPE, FETCH_JOBS, SHOW_JOB_CREATION, CLOSE_JOB_CREATION, AFTER_PHOTO_UPLOAD, BEFORE_PHOTO_UPLOAD, START_JOB, STOP_JOB, INCREMENT_TIME, ARCHIVE_JOB, COMPLETE_JOB, RESTORE_JOB, DELETE_JOB, ALLOW_JOB_START, START_SEARCH, SEARCH_FORM_UPDATE, STOP_SEARCH, CLEAR_SEARCH, LOGOUT, FETCHING_JOBS, FETCH_JOBS_ERROR, ADD_JOB} from "../actions/types"

const initial_state = {type: "current", show_modal: false, data: [], active_job: {_id: null, time: 0, timer: null}, allow_job_start: false, searching: false, search_string: "", search_data: [], loading: false};

export default (state = initial_state, action) => {

    const new_state = {...state};
    let i;
    switch(action.type){
        case JOB_TYPE: // Switch job view
            new_state.searching = false;
            new_state.type = action.payload;
        break;

        case FETCH_JOBS:
            new_state.data = action.payload || [];
            new_state.loading = false;
        break;

        case ADD_JOB:
            new_state.data = [action.payload, ...new_state.data];
        break;

        case FETCHING_JOBS:
            new_state.loading = true;
        break;

        case FETCH_JOBS_ERROR:
            new_state.loading = false;
        break;

        case SHOW_JOB_CREATION:
            new_state.show_modal = true;
        break;

        case ALLOW_JOB_START:
            new_state.allow_job_start = true;
        break;

        case START_JOB:
            new_state.active_job._id = action.payload;
            i = new_state.data.findIndex((job) => {
                return job._id === action.payload;
            });
            if(i >= 0){
                new_state.data[i].started = true;
            }
        break;

        case INCREMENT_TIME:
            i = new_state.data.findIndex((job) => {
                return job._id === new_state.active_job._id;
            });
            
            if(i >= 0){
                new_state.data[i].total_time += 1;
            }
        break;

        case STOP_JOB:
            new_state.active_job._id = "";
            new_state.allow_job_start = false
            clearInterval(new_state.active_job.timer);
        break;

        case ARCHIVE_JOB:
            i = new_state.data.findIndex((job) => {
                return job._id === action.payload;
            });
            if(i >= 0){
                new_state.data = [...new_state.data];
                new_state.data[i].status = "archived";
            }
        break;

        case COMPLETE_JOB:
            i = new_state.data.findIndex((job) => {
                return job._id === action.payload;
            });
            if(i >= 0){
                new_state.data = [...new_state.data];
                new_state.data[i].status = "completed";
            }
        break;

        case RESTORE_JOB:
            i = new_state.data.findIndex((job) => {
                return job._id === action.payload;
            });

            if(i >= 0){
                new_state.data = [...new_state.data];
                new_state.data[i].status = "not started";
            }
        break;
        
        case DELETE_JOB:
            i = new_state.data.findIndex(job => {
                return job._id === action.payload;
            });
            new_state.data.splice(i, 1);
        break;

        case START_SEARCH:
            new_state.searching = true;
        break;

        case STOP_SEARCH:
            new_state.search_string = "";
            new_state.searching = false;
            new_state.search_data = [];
        break;

        case CLEAR_SEARCH:
            new_state.search_string = "";
        break;

        case SEARCH_FORM_UPDATE:
            new_state.search_string = action.payload;
            new_state.search_data = [];
            // console.log("search_form_update",new_state.search_string);
            new_state.data.forEach((job, i) => {
                if(job.title === undefined || job.description === undefined || job.category === undefined || job.location === undefined){
                    //console.log(i);
                }
                if(job.title.toUpperCase().indexOf(new_state.search_string.toUpperCase()) !== -1){
                    new_state.search_data.push(job);
                    return;
                }else if(job.description.toUpperCase().indexOf(new_state.search_string.toUpperCase()) !== -1){
                    new_state.search_data.push(job);
                    return;
                }else if(job.category.toUpperCase().indexOf(new_state.search_string.toUpperCase()) !== -1){
                    new_state.search_data.push(job);
                    return;
                }else if(job.location){ //TEMP
                    if(job.location.toUpperCase().indexOf(new_state.search_string.toUpperCase()) !== -1){
                        new_state.search_data.push(job);
                        return;
                    }
                }
            });
        break;

        case CLOSE_JOB_CREATION:
            new_state.show_modal = false;
        break;

        case AFTER_PHOTO_UPLOAD:
            i = new_state.data.findIndex((job) => {
                return job._id === action.payload.job_id;
            });

            new_state.data[i].image_urls.after.push({url: action.payload.file.filename, filename: action.payload.originalname});
        break;

        case BEFORE_PHOTO_UPLOAD:
            i = new_state.data.findIndex((job) => {
                return job._id === action.payload.job_id;
            });

            new_state.data[i].image_urls.before.push({url: action.payload.file.filename, filename: action.payload.file.originalname});
        break;

        case LOGOUT:
            return initial_state;

        default:
            return state;
    }

    return new_state;
}