import React, { Component } from 'react';
import Profile_skill from "./profile_skill";
import {connect} from "react-redux";

import {
    Modal,
    ModalBody,
    UncontrolledTooltip
} from "reactstrap"

import {close_discover_comp_modal} from "../../actions"

import config from '../../config';

class Company_modal extends Component {

    toggle_comp_modal = () => {
        this.props.close_discover_comp_modal();
    }

    onPress_vist_website = () => {
        const {company} = this.props;
        if(company.website){
            window.open(`${encodeURI(company.website)}`);
        }
    }

    render_company_image(){
        const {photo_url} = this.props.company;
        
        if(photo_url){
          return(
            <img
              alt="..."
              className="img-circle rounded-circle img-thumbnail company-image"
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                alt="..."
                className="img-circle rounded-circle img-thumbnail company-image"
                src={require("assets/images/company_avatar.png")}
              />
            )
        }
    }

    render_star(star, avg_rating){
        if(avg_rating > star){
            return <i className="fas fa-star" style={{color: "#f7bd4b"}} />
        }else{
            if(avg_rating - (star - 1) > 0){
                return <i className="fas fa-star-half-alt" style={{color: "#f7bd4b"}} />
            }else{
                return <i className="fas fa-star" style={{color: "#aaa"}} />
            }
        }
    }

    render() { 
        const {company, isOpen} = this.props;
        

        if(company){
            const {stars} = company;
            const {completed_jobs} = company;
            const {total_employees} = company;

            console.log("stars, total_employees", stars, total_employees)
            return (
                <Modal isOpen={isOpen} toggle={this.toggle_comp_modal}>
                    <ModalBody>
                    <div className="modal-body-content">
                        <div className="image-stats-info">
                            {this.render_company_image()}
                            <div className="stats-info">
                                <h1>{company.comp_name}</h1>
                                <h3 style={{color: "#44738e"}}>{company.address || "Location not specified"}</h3>

                                <span className="company-stats">
                                    {/* {(completed_jobs?(stars/completed_jobs):0).toFixed(1)} <i id={"stars-company-modal"+company._id} className="fas fa-star" style={{color: "#f7bd4b", fontSize: 30, marginRight: 15, marginLeft: 5}} /> */}

                                    <div id={"comp-modal-star"+company._id} style={{marginRight: 20}}>
                                    {this.render_star(1, (stars/completed_jobs))}
                                    {this.render_star(2, (stars/completed_jobs))}
                                    {this.render_star(3, (stars/completed_jobs))}
                                    {this.render_star(4, (stars/completed_jobs))}
                                    {this.render_star(5, (stars/completed_jobs))}
                                    </div>

                                    <UncontrolledTooltip delay={0} target={"comp-modal-star"+company._id}>
                                        {((stars)||0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b"}} />
                                    </UncontrolledTooltip>
                                
                                    {total_employees || 0} <img id={"employees-modal" + company._id} className="comp-modal-spanner-icon" src="/assets/images/icon_profile@2x.png" /> 

                                    <UncontrolledTooltip delay={0} target={"employees-modal" + company._id}>
                                        {total_employees} Employees
                                    </UncontrolledTooltip>
                                </span>
                            </div>
                        </div>

                        <p className="comp-modal-description">{company.description || "No Description"}</p>

                        <p className="company-contact-info">
                            Call: <span>{company.phone} &nbsp; &nbsp; &nbsp;</span>Email: <span>{company.email}</span>
                        </p>

                        <div className="visit-website-button-container">
                            <button onClick={this.onPress_vist_website} className="visit-website-button-slant"></button>
                            <button onClick={this.onPress_vist_website} className="visit-website-button">Visit Website</button>
                        </div>
                            
                        <button className="close-button" onClick={this.toggle_comp_modal}>X</button>
                    </div>
                    </ModalBody>
                </Modal>
            );
        }else{
            return null;
        }
    }
}

function map_state_to_props({discover}){
    return {company: discover.clicked_company, isOpen: discover.company_clicked};
}
 
export default connect(map_state_to_props, {close_discover_comp_modal})(Company_modal);