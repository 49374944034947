import {ITEM_CLICKED, CLOSE_MODAL} from "../actions/types";

const initial_state = { update: {}, clicked: false };

export default (state = initial_state, action) => {

    const new_state = {...state};

    switch(action.type){

        case ITEM_CLICKED:
            new_state.update = {...action.payload};
            new_state.clicked = true;
        break;

        case CLOSE_MODAL:
            new_state.clicked = false;
        break;
        
        default:
            return state
    }
    //console.log(new_state);
    return new_state;
}