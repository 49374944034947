import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import RMTHeader from "../RMT_header";
import RMTFOOTER from "../RMT_footer";
import Tech_modal from "../Requests/technician_modal";
// import Discover_search from "./discover_search";
import {discover_pressed as press_discover, discover_form_update, discover_search, dismiss_discover, discover_click_tecnician, set_attempted_url, set_history_technician, set_history_jobs, set_history_reviews, set_history_completed_jobs_count, set_history_from_discover, close_nav} from "../../actions";
import Cities from "./cities";
import Categories from "./categories";
import States from "./states";
import config from '../../config';

import "./index.css";
import "./App.css";

const window_width = window.innerWidth;
  
class Index extends Component {
    state = { searched: false, show_state_suggestion: false, show_city_suggestion: false, show_category_suggestion: false, state_selected: true, city_selected: true, category_selected: true, current_state_index: -1, current_city_index: -1, current_category_index: -1, search_error: "", top_tech_pagination_index: 0, top_tech_page_amount: window_width<600?2:window_width<1000?4:8};
    
    constructor(props){
        super(props);
        
        this.onKeyDown_category = this.onKeyDown_category.bind(this);
        this.onKeyDown_city = this.onKeyDown_city.bind(this);
        this.onKeyDown_state = this.onKeyDown_state.bind(this);
    }

    componentDidMount(){
        this.props.dismiss_discover();
        this.props.close_nav()
    }

    new_path_timeout = null;
    componentDidUpdate(){
        if(this.props.logged_in && this.props.attempted_url && !this.new_path_timeout && (this.props.location.pathname !== this.props.attempted_url)){
            this.new_path_timeout = setTimeout(() => {this.props.history.push(this.props.attempted_url);}, this.new_path_timeout = null, 200);
        }
    }
    
    match_states=[];
    match_cities=[];
    match_categories=[];

    onChange_city = ({target: {value}}) => {
        this.setState({city_selected: false, show_city_suggestion: true});
        this.props.discover_form_update("city", value);
    }

    onChange_category = ({target: {value}}) => {
        this.setState({category_selected: false, show_category_suggestion: true});
        this.props.discover_form_update("category", value);
    }

    onChange_state = ({target: {value}}) => {
        this.setState({state_selected:false, show_state_suggestion: true});
        this.props.discover_form_update("state", value);
    }

    onKeyDown_city(e){
        const {current_city_index} = this.state;

        if(e.which === 13 && current_city_index > -1){//enter
            this.props.discover_form_update("city", this.match_cities[current_city_index]);
            this.setState({show_city_suggestion: !this.state.show_city_suggestion, city_selected: !this.state.city_selected});
        }else if(e.which === 38){//up
            if(current_city_index <= 0){
                this.setState({current_city_index: this.match_cities.length-1});
            }else{
                this.setState({current_city_index: this.state.current_city_index-1});
            }
        }else if(e.which === 40){//down
            if(current_city_index >= this.match_cities.length-1){
                this.setState({current_city_index: 0});
            }else{
                this.setState({current_city_index: this.state.current_city_index+1});
            }
        }
    }

      onKeyDown_category(e){
        const {current_category_index} = this.state;

        if(e.which === 13 && current_category_index > -1){//enter
            this.props.discover_form_update("category", this.match_categories[current_category_index].label);
            this.setState({show_category_suggestion: !this.state.show_category_suggestion, category_selected: !this.state.category_selected});
        }else if(e.which === 38){//up
            if(current_category_index <= 0){
                this.setState({current_category_index: this.match_categories.length-1});
            }else{
                this.setState({current_category_index: this.state.current_category_index-1});
            }
        }else if(e.which === 40){//down
            if(current_category_index >= this.match_categories.length-1){
                this.setState({current_category_index: 0});
            }else{
                this.setState({current_category_index: this.state.current_category_index+1});
            }
        }
      }

      onKeyDown_state(e){
        const {current_state_index} = this.state;

        if(e.which === 13 && current_state_index > -1){//enter
            this.props.discover_form_update("state", this.match_states[current_state_index]);
            this.setState({show_state_suggestion: !this.state.show_state_suggestion, state_selected: !this.state.state_selected});
        }else if(e.which === 38){//up
            if(current_state_index <= 0){
                this.setState({current_state_index: this.match_states.length-1});
            }else{
                this.setState({current_state_index: this.state.current_state_index-1});
            }
        }else if(e.which === 40){//down
            if(current_state_index >= this.match_states.length-1){
                this.setState({current_state_index: 0});
            }else{
                this.setState({current_state_index: this.state.current_state_index+1});
            }
        }
      }

      render_city_suggestions(){
        const {city} = this.props.discover;
        const {show_city_suggestion, city_selected, current_city_index} = this.state;

        if(show_city_suggestion){
            this.match_cities = Cities.filter((c) => {
                return c.toUpperCase().includes(city.toUpperCase());
            });
            return this.match_cities.map((ci, i) => {
                return (
                    <p className={`suggestion ${i===current_city_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("city", ci); this.setState({city_selected: true, show_city_suggestion: false});}} key={ci+i}>{ci}</p>
                );
            })
        }
      }

      render_category_suggestions(){
        const {category} = this.props.discover;
        const {show_category_suggestion, category_selected, current_category_index} = this.state;

        if(show_category_suggestion){
            this.match_categories = Categories.filter((c) => {
                return c.label.toUpperCase().includes(category.toUpperCase());
            })
            return this.match_categories.map((ca, i) => {
                return (
                    <p className={`suggestion ${i===current_category_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("category", ca.label); this.setState({category_selected: true, show_category_suggestion: false});}} key={ca+i}>{ca.label}</p>
                );
            })
        }
      }

      render_state_suggestions(){
        const {state} = this.props.discover;
        const {show_state_suggestion, state_selected, current_state_index} = this.state;

        if(show_state_suggestion){
            this.match_states = States.filter((c) => {
                return c.toUpperCase().includes(state.toUpperCase());
            });
            return this.match_states.map((st, i) => {
                return (
                    <p className={`suggestion ${i===current_state_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("state", st); this.setState({state_selected: true, show_state_suggestion: false});}} key={st+i}>{st}</p>
                );
            })
        }
      }

      onPress_discover = () => {
        const {category, city, state} = this.props.discover;
        if(category){
          this.props.socket.emit("discover_pressed");
          this.props.press_discover();
          this.props.discover_search(city, category, state);

          setTimeout(() => {this.props.history.push("/discover");}, 600);
          return;
        }

        alert("You need to select a category");
      }

      onPress_view_profile = (tech) => {
        this.props.discover_click_tecnician(tech);

        this.props.set_history_technician(tech);
        this.props.set_history_jobs(tech.jobs);
        this.props.set_history_reviews(tech.reviews);
        this.props.set_history_completed_jobs_count(tech.completed_jobs);
        this.props.set_history_from_discover(true);
      }

      render_top_technician_image(photo_url, index){
          if(photo_url){
            return (
                <img className={`top-tech-image ${index < 3?"podium":""}`} src={`${config.real_backend_url}/uploads/images/${photo_url}`} alt="technician image" />
            )
          }else{
              return (
                <img className={`top-tech-image ${index < 3?"podium":""}`}  src={require("../../assets/images/profile_avatar.png")} alt="technician image" />
              )
          }
      }

    render_pagination_dots(){
        const dots_array = [];
        const {top_tech_pagination_index, top_tech_page_amount} = this.state;
        const {top_techs} = this.props.discover;
        const dots_amount = top_techs.length/top_tech_page_amount;

        for(let i = 0; i < dots_amount; i++){
            dots_array.push((
                <div className={`pagination-dot ${top_tech_pagination_index === i?"current":""}`} key={i.toString()} />
            ))
        }

        return dots_array;
    }

    onPress_left_pagination = () => {
        const {top_tech_pagination_index} = this.state;

        if(top_tech_pagination_index > 0){
            this.setState({top_tech_pagination_index: top_tech_pagination_index-1});
        }
    }

    onPress_right_pagination = () => {
        const {top_techs} = this.props.discover;
        const {top_tech_pagination_index, top_tech_page_amount} = this.state;
        const dots_amount = top_techs.length/top_tech_page_amount;

        if(top_tech_pagination_index < Math.floor(dots_amount)){
            this.setState({top_tech_pagination_index: top_tech_pagination_index+1});
        }
    }

    render() { 
        const {discover_pressed, discover: {category, city, state, top_techs}} = this.props;
        const {show_state_suggestion, show_category_suggestion, show_city_suggestion, state_selected, city_selected, category_selected, search_error, top_tech_pagination_index, top_tech_page_amount} = this.state;

        return (
            <>
                {/* <Discover_search /> */}
                <Tech_modal />
                <div>
                <RMTHeader />
                <div className="main">
                    <div className="img-background">
                        <div className="img-background-overlay">
                            <div className="img-background-overlay-content">
                                <h1>Find Your <span className="TITAN" style={{color: "white"}} >HairStylist.</span></h1>

                                <p>Get matched to talent in minutes through our</p> 
                                <p> nationwide network of skilled hair stylists.</p>

                                <div className="discover">
                                    {/* <button className="button button-discover" onClick={this.onPress_discover} >DISCOVER</button> */}

                                    <span className="discover-input">
                                        <span className="discover-search-input-wrapper">
                                            <input autoComplete="off" onFocus={() => {this.setState({category_selected: false, show_category_suggestion: true, show_city_suggestion: false, show_state_suggestion: false}); this.category_input.setSelectionRange(0, this.category_input.value.length)}} onKeyDown={this.onKeyDown_category} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} */ onChange={this.onChange_category} /* value={state} */ type="text" name="category" placeholder="All Categories" className="" ref={(input) => {this.category_input = input}} value={category}  />
                                            
                                            <i onClick={() => {
                                                if(!show_category_suggestion){
                                                    this.category_input.focus();
                                                }
                                                if(!category_selected){
                                                    this.category_input.value = "";
                                                }
                                                this.setState({show_category_suggestion: !this.state.show_category_suggestion, show_city_suggestion: false, show_state_suggestion: false});
                                            }} style={{verticalAlign: "sub", cursor: "pointer", backgroundColor: "white", height: "100%", display: "inline-block", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_category_suggestion/*  || state_selected===false */?"up":"down"}`} />
                                        </span>
                                        <span className="discover-search-input-wrapper">
                                        <input autoComplete="off" onFocus={() => {this.setState({state_selected: false, show_state_suggestion: true, show_state_suggestion: false}); this.state_input.setSelectionRange(0, this.state_input.value.length)}} onKeyDown={this.onKeyDown_state} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} */ onChange={this.onChange_state} /* value={state} */ type="text" name="state" placeholder="State" className="" ref={(input) => {this.state_input = input}} value={state}  />
                                            
                                            <i onClick={() => {
                                                if(!show_state_suggestion){
                                                    this.state_input.focus();
                                                }
                                                if(!state_selected){
                                                    this.state_input.value = "";
                                                }
                                                this.setState({show_state_suggestion: !this.state.show_state_suggestion, show_category_suggestion: false});
                                            }} style={{verticalAlign: "sub", cursor: "pointer", backgroundColor: "white", height: "100%", display: "inline-block", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_state_suggestion/*  || state_selected===false */?"up":"down"}`} />
                                        </span>
                                        <span className="discover-search-input-wrapper">
                                            <input autoComplete="off" onFocus={() => {this.setState({city_selected: false, show_city_suggestion: true, show_category_suggestion: false}); this.city_input.setSelectionRange(0, this.city_input.value.length)}} onKeyDown={this.onKeyDown_city} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} */ onChange={this.onChange_city} /* value={state} */ type="text" name="city" placeholder="City" className="" ref={(input) => {this.city_input = input}} value={city}  />
                                            
                                            <i onClick={() => {
                                                if(!show_city_suggestion){
                                                    this.city_input.focus();
                                                }
                                                if(!city_selected){
                                                    this.city_input.value = "";
                                                }
                                                this.setState({show_city_suggestion: !this.state.show_city_suggestion, show_category_suggestion: false});
                                            }} style={{verticalAlign: "sub", cursor: "pointer", backgroundColor: "white", height: "100%", display: "inline", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_city_suggestion/*  || state_selected===false */?"up":"down"}`} />
                                        </span>
                                       

                                        <div className="suggestion-container-hide-scrollbar"><div className={"suggestion-container " + (show_city_suggestion?"suggestion-open":"suggestion-close")}>{this.render_city_suggestions()}</div></div>
                                        <div className="suggestion-container-hide-scrollbar"><div className={"suggestion-container " + (show_state_suggestion?"suggestion-open":"suggestion-close")}>{this.render_state_suggestions()}</div></div>
                                        <div className="suggestion-container-hide-scrollbar"><div className={"suggestion-container " + (show_category_suggestion?"suggestion-open":"suggestion-close")}>{this.render_category_suggestions()}</div></div>

                                        <span className="discover-search-button-wrapper" onClick={this.onPress_discover}>
                                            <button className="discover-search-button"onClick={this.onPress_discover}>Search</button>
                                        </span>

                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="discover-search-mobile">

                        <div className="discover-search-input-wrapper-mobile">
                            <input autoComplete="off" onFocus={() => {this.setState({category_selected: false, show_category_suggestion: true, show_city_suggestion: false}); this.mobile_category_input.setSelectionRange(0, this.mobile_category_input.value.length)}} onKeyDown={this.onKeyDown_category} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} */ onChange={this.onChange_category} /* value={state} */ type="text" name="category" placeholder="All Categories" className="" ref={(input) => {this.mobile_category_input = input}} value={category} />

                            <i onClick={() => {
                                if(!show_category_suggestion){
                                    this.mobile_category_input.focus();
                                }
                                if(!category_selected){
                                    this.mobile_category_input.value = "";
                                }
                                this.setState({show_category_suggestion: !this.state.show_category_suggestion, show_city_suggestion: false});
                            }} style={{verticalAlign: "sub", cursor: "pointer", height: "100%", display: "inline-block", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_category_suggestion/*  || state_selected===false */?"up":"down"}`} />
                        </div>

                        <div className="discover-search-input-wrapper-mobile">
                            <input autoComplete="off" onFocus={() => {this.setState({city_selected: false, show_city_suggestion: true, show_category_suggestion: false}); this.mobile_city_input.setSelectionRange(0, this.mobile_city_input.value.length)}} onKeyDown={this.onKeyDown_city} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} */ onChange={this.onChange_city} /* value={state} */ type="text" name="city" placeholder="City" className="" ref={(input) => {this.mobile_city_input = input}} value={city} />

                            <i onClick={() => {
                                if(!show_city_suggestion){
                                    this.mobile_city_input.focus();
                                }
                                if(!city_selected){
                                    this.mobile_city_input.value = "";
                                }
                                this.setState({show_city_suggestion: !this.state.show_city_suggestion, show_category_suggestion: false});
                            }} style={{verticalAlign: "sub", cursor: "pointer", height: "100%", display: "inline-block", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_city_suggestion/*  || state_selected===false */?"up":"down"}`} />
                        </div>

                        <div className="suggestion-container-hide-scrollbar"><div className={"suggestion-container " + (show_city_suggestion?"suggestion-open":"suggestion-close")}>{this.render_city_suggestions()}</div></div>

                        <div className="suggestion-container-hide-scrollbar"><div className={"suggestion-container " + (show_category_suggestion?"suggestion-open":"suggestion-close")}>{this.render_category_suggestions()}</div></div>
                        
                        <div className="discover-search-mobile-button-wrapper" onClick={this.onPress_discover}>
                            <button onClick={this.onPress_discover} className="discover-search-mobile-button"><i className="fas fa-search" /> GO</button>
                        </div>
                    </div>

                    <div className="meet-techs">
                        <div className="meet-techs-text">
                            <h1>MEET YOUR <span style={{color: "#EAC200", fontFamily: "AvenirNext-bold"}}>HAIR STYLISTS</span></h1>
                            <h3>Not sure which Hair Stylist you want?</h3>
                            <h3>Here are our top 100 rated Stylists</h3>
                        </div>
                    </div>

                    <div className="top-technicians">
                        <div className="pagination-button-container left">
                            <button className="pagination-button" onClick={this.onPress_left_pagination}>
                                <i className="fas fa-chevron-left" />
                            </button>
                        </div>

                        <div className="pagination-button-container right">
                            <button className="pagination-button" onClick={this.onPress_right_pagination}>
                                <i className="fas fa-chevron-right" />
                            </button>
                        </div>

                        <ul>
                        {top_techs.slice(top_tech_pagination_index*top_tech_page_amount, (top_tech_pagination_index*top_tech_page_amount)+top_tech_page_amount).map((tech, index) => {
                            const stars = tech.reviews.map((r) => r.star_rating).reduce((p, c) => p+c, 0);
                            return (
                            <li className={`top-tech-card-container ${((top_tech_pagination_index*top_tech_page_amount)+index)===0?"top-one":""}`}>
                                  
                                <div className={`top-tech-card `}>
                                    
                                    <div className="top-tech-image-container">
                                        {this.render_top_technician_image(tech.photo_url, ((top_tech_pagination_index*top_tech_page_amount)+index))}
                                        {((top_tech_pagination_index*top_tech_page_amount)+index) < 3?<span className="rating-position">{((top_tech_pagination_index*top_tech_page_amount)+index+1)}</span>:null}
                                      
                                    </div>

                                    <div className="top-tech-info-container">
                                        <h2>{`${tech.name.first} ${tech.name.last}`}</h2>

                                        <p>{tech.companies[0]?tech.companies[0].comp_name:"No Company"}</p>

                                         <img className="img-stars" src="./assets/images/starsicon.svg" /><div className="stars-int"> {stars.toFixed(0)} </div>
                                        
                                         

                                        <hr className="hr" style={{margin: "10px auto", width: "100%"}} />

                                        <h3 className="skills-text" style={{fontWeight: "normal"}}>{tech.categories.length !== 0?tech.categories.map((c, i, arr) => {
                                            return i+1 === arr.length?c:c+", ";
                                    }):"No Skills Specified"}</h3>
                                    </div>
                                </div>
                                
                                <div className="view-profile-button-container">
                                    <button onClick={() => {this.onPress_view_profile(tech)}} className="view-profile-button">VIEW PROFILE</button>
                                </div>
                            </li>
                        )})}
                        </ul>

                        <div className="pagination-dots-container">
                            {this.render_pagination_dots()}
                        </div>
                        
                    </div>

                    <div className="categories-showcase-wrapper">
                        <p> BAD HAIR <span className="yellow-keyword">DAY?</span></p>
                        <p>Good News! There's a Stylist for Every Job!</p>
                        <div className="categories-showcase">
                            <ul className="categories">
                                <li className="category">
                                    <div className="icon-wrapper" onClick={async () => {await this.props.discover_form_update("category", "Plumbing");this.onPress_discover()}}>
                                        <img className="category-image" src={"/assets/images/Makeupkit.png"} />
                                        
                                        {/* <div className="real-icon-wrapper">
                                             <div>
                                            <i className="fas fa-shower icon" color="#1a3279" />
                                            <span>Makeup1</span>
                                            </div>
                                        </div>  */}
                                    </div>
                                </li>
                                <li className="category">
                                    <div className="icon-wrapper" onClick={async () => {await this.props.discover_form_update("category", "Heater");this.onPress_discover()}}>
                                        <img className="category-image" src={"/assets/images/makeupmirror.png"} />

                                        {/* <div className="real-icon-wrapper">
                                            <div>
                                            <i className="fas fa-thermometer-full icon" color="#1a3279" />
                                            <span>Makeup2</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </li>
                                <li className="category">
                                    <div className="icon-wrapper" onClick={async () => {await this.props.discover_form_update("category", "Air Condition");this.onPress_discover()}}>
                                        <img className="category-image" src={"/assets/images/makeupbrushes.png"} />

                                        {/* <div className="real-icon-wrapper">
                                            <div>
                                            <i className="fas fa-snowflake icon" color="#1a3279" />
                                            <span>Makeup3</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </li>
                                <li className="category">
                                    <div className="icon-wrapper" onClick={async () => {await this.props.discover_form_update("category", "HVAC");this.onPress_discover()}}>
                                        <img className="category-image" src={"/assets/images/brushandkit.png"} />
                                        
                                        {/* <div className="real-icon-wrapper">
                                            <div>
                                            <i className="fas fa-shower icon" color="#1a3279" />
                                            <span>Makeup4</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </li>
                                {/* <li className="category">
                                    <div className="icon-wrapper" onClick={async () => {await this.props.discover_form_update("category", "Flooring");this.onPress_discover()}}>
                                        <img className="category-image" src={"/assets/images/flooring(2).png"} />
                                        
                                        <div className="real-icon-wrapper">
                                            <div>
                                            <i className="fas fa-thermometer-full icon" color="#1a3279" />
                                            <span>Flooring</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="category">
                                    <div className="icon-wrapper" onClick={async () => {await this.props.discover_form_update("category", "Air Condition");this.onPress_discover()}}>
                                        <img className="category-image" src={"/assets/images/technology(2).png"} />
                                        <div className="real-icon-wrapper">
                                            <div>
                                            <i className="fas fa-snowflake icon" color="#1a3279" />
                                            <span>Air Conditioning</span>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>

                </div>
                </div>

                

                <RMTFOOTER />
            </>
        );
    }
}

function map_state_to_props({app, user, discover, auth}){
    return {type: app.account_type, socket: app.socket, discover_pressed: app.discover_pressed, email: user.email, role: user.role, discover, logged_in: auth.logged_in, attempted_url: app.attempted_url, set_attempted_url}
}
 
export default withRouter(connect(map_state_to_props, {press_discover, discover_form_update, discover_search, dismiss_discover, discover_click_tecnician, set_history_technician, set_history_jobs, set_history_reviews, set_history_completed_jobs_count, set_history_from_discover, close_nav})(Index));