import React, { Component } from 'react';
import config from "../../config"

class Title_header extends Component {
    state = {  }
    render() { 
        return (
            <div className="title-header-wrapper clearfix">
                <div className="title-header">
                    <div className="title">
                        <h2 className="app-name">{ config.app_name_caps.concat()} {}</h2>
                    </div>

                    <ul className="horizontal-list">
                        <li className="location">
                            <p><i className="material-icons" style={{color: "#f5c85b"}}>location_on</i> 25<sup>th</sup> Street Avenue, Los Angeles, CA</p>
                        </li>
                        <li className="email">
                            <p><i className="material-icons" style={{color: "#f5c85b"}}>email</i> info@myservicetitans.com</p>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
 
export default Title_header;