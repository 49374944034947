import React, { Component } from 'react';

class Search_list extends Component {
    state = { pressed_index: -1, typing: false, add_input_value: "", match_selected: false, matches: []}

    constructor(props){
        super(props);
        this.onPress_add = this.onPress_add.bind(this);
        this.onPress_item = this.onPress_item.bind(this);
        this.onPress_cancel = this.onPress_cancel.bind(this);
        this.onPress_remove = this.onPress_remove.bind(this)
        this.onPress_add_item = this.onPress_add_item.bind(this);
        this.onChange_add_input_text = this.onChange_add_input_text.bind(this);
    }

    type_search(){
        const {search_array, search_property} = this.props;
        const {add_input_value} = this.state;
        const regex = new RegExp(`^${add_input_value}`, "i");

        const matches = search_array.filter((item,i)=>{
            const regex_matches = search_property?item[search_property].match(regex):item.match(regex);

            if(regex_matches){
                return true;
            }else{
                return false;
            }
        });
        
        if(matches){
            this.setState({matches});
        }else{
            this.setState({matches: []});
        }

        // alert(match);
    }

    onChange_add_input_text({target: {value:new_value}}){
        this.setState({add_input_value: new_value, match_selected: false}, ()=>{
            this.type_search();
        });
    }

    onPress_item(i){
        this.setState({pressed_index: i})
    }

    onPress_add(){
        this.setState({typing: true}, ()=>{this.add_input.focus();});
        console.log("cross focused");
    }

    onPress_add_item(){
        const {add_func} = this.props;
        const {match_selected, add_input_value} = this.state;
        if(match_selected){
            if(add_func && typeof(add_func) === typeof(Function)){
                add_func(add_input_value);
                console.log("item added");
            }
            this.setState({typing: false, add_input_value: ""});
        }
    }

    onPress_cancel(){
        this.setState({typing: false, add_input_value: "", matches: []});
    }

    onPress_remove(e){
        e.preventDefault();
        
        const {remove_func, items} = this.props;
        const {pressed_index} = this.state;

        if(remove_func && typeof(remove_func) === typeof(Function)){
            remove_func(pressed_index, items[pressed_index])
        }
    }

    render_items(){
        const {text_container_style, text_style, remove_button_style} = styles;
        const {pressed_index} = this.state;
        const {items, render_property:render_prop} = this.props;
        return items.map((item, i) => {
            if(pressed_index === i){
                return (
                    <div key={render_prop?item[render_prop]:item} style={{...text_container_style, boxSizing: "content-box"}}>
                        <div style={{flexDirection: "row", alignItems: "center", display: "flex", overflow: "visible"}}>
                        <button onClick={()=>{this.onPress_item(-1)}} style={{display: "flex", flexDirection: "column", height: text_container_style.height, justifyContent: "center", border: "none", background: "transparent"}}>
                            <span style={text_style}>{render_prop?item[render_prop].toString():item.toString()}</span>
                        </button>
                        
                        <button type="button" onClick={this.props.disabled === true?(e)=>{e.preventDefault()}:this.onPress_remove} style={{...remove_button_style, backgroundColor: this.props.disabled === true?"grey":"gold"}}>
                            <span style={{color: "white", fontSize: 16, fontWeight: "bold"}}>X</span>
                        </button>
                        </div>
                    </div>
                );
            }

            return (
                <div key={render_prop?item[render_prop]:item} style={{...text_container_style, boxSizing: "content-box"}}>
                    <button onClick={()=>{this.onPress_item(i)}} style={{display: "flex", flexDirection: "column", height: text_container_style.height, justifyContent: "center", border: "none", background: "transparent"}}>
                        <span style={text_style}>{render_prop?item[render_prop].toString():item.toString()}</span>
                    </button>
                </div>
            )
        });
    }

    render_add(){
        const {typing, add_input_value} = this.state;
        const {add_style, text_style, text_container_style, remove_button_style} = styles;
        if(typing){
            return (
                <div style={{...text_container_style, flexDirection: "row", marginRight: 50, display: "flex", overflow: "visible"}}>

                <div style={{...add_style, borderWidth: 0, width: remove_button_style.width, height: remove_button_style.height, marginLeft: 4, overflow: "hidden"}}>
                    <button onClick={this.onPress_cancel} style={{display: "flex", height: add_style.height, width: add_style.height, justifyContent: "center", alignItems: "center", backgroundColor: "red", border: "none"}}>
                        <span style={{...text_style, color: "white", fontWeight: "bold", fontSize: 20}}>x</span>
                    </button>
                </div>

                <input onChange={this.onChange_add_input_text} value={add_input_value} ref={(input) => {this.add_input = input}} style={{minWidth: 20, border: "none"}} type="text" />

                <div style={{...add_style, borderWidth: 0, width: remove_button_style.width, height: remove_button_style.height, marginRight: 4, marginLeft: 8, overflow: "hidden"}}>
                    <button onClick={this.onPress_add_item} style={{height: add_style.height, width: add_style.height, justifyContent: "center", alignItems: "center", backgroundColor: "gold", border: "none"}}>
                        <span style={{...text_style, color: "white", fontWeight: "bold", fontSize: 20}}>+</span>
                    </button>
                </div>
                </div>
            );
        }else{
            return (
                <div style={add_style}>
                    <button onClick={this.onPress_add} style={{display: "flex", height: add_style.height, width: add_style.height, justifyContent: "center", alignItems: "center", border: "none", background: "transparent", overflow: "visible"}}>
                        <span style={text_style}>+</span>
                        
                    </button>
                </div>
            );
        }
    }

    render() {
        const {matches, typing, match_selected} = this.state;
        const {search_property:search_prop} = this.props;
        const {view_style, text_container_style, text_style} = styles;
        return (
            <>
                <div style={{...view_style, ...this.props.style}}>
                    {this.render_items()}
                    {this.props.disabled === true?null:this.render_add()}
                </div>
                {((matches.length > 0 && typing)&&!match_selected)?<div style={{marginVertical: 5,width: "75%", alignSelf: "center", display: "flex", overflowX: "auto", ...this.props.results_style}}>
                    {matches.map((match, i) => {
                        return (
                            <div style={{...text_container_style, height: 30, alignSelf: "flex-start", marginLeft: 0}}>
                                <button style={{border: "none", background: "transparent", whiteSpace: "nowrap"}} onClick={()=>{this.setState({match_selected: true, add_input_value: search_prop?match[search_prop]:match});}}>
                                    <span style={text_style}>{search_prop?match[search_prop]:match}</span>
                                </button>
                            </div>
                        )
                    })
                    }
                </div>:null}
            </>
        );
    }
}

const styles = {
    view_style: {
        display: "flex",
        backgroundColor: "#e9ecef",
        height: 80,
        width: "100%",
        alignSelf: "center",
        borderRadius: 8,
        flexDirection: "row",
        justifyContent: "flex-start",
        overflowX: "auto",
        overflowY: "hidden",
        marginBottom: 10
    },
    text_container_style: {
        height: "80%",
        borderRadius: 50,
        overflow: "visible",
        justifyContent: "center",
        alignItems: "center",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "rgb(0, 150, 250)",
        alignSelf: "center",
        marginLeft: 5,
        marginRight: 5,
        backgroundColor: "white"
    },
    text_style: {
        color: "grey",
        fontSize: 16,
        padding: "0 10px"
    },
    add_style: {
        display: "flex",
        width: 40,
        height: 40,
        borderRadius: 40,
        overflow: "visible",
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: "rgb(0, 150, 250)",
        alignItems: "center",
        alignSelf: "center",
        justifyContent: "center",
        marginRight: 8,
        marginLeft: 5
    },
    remove_button_style: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "gold",
        width: 30,
        height: 30,
        justifyContent: "center",
        borderRadius: 30,
        marginRight: 4,
        border: "none"
    }
}

export default Search_list;