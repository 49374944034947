import {FETCH_REVIEWS, FETCHING_REVIEWS, SET_STARS} from "./types";
import Axios from "axios";
import config from "../config";

const set_stars = async (res, call) => {
    const count = (await res.data.reviews.map((review) => {
        return review.star_rating
    })).reduce((prev, curr) => prev + curr, 0);
    call({type: SET_STARS, payload: count});
}

export const fetch_reviews = () => async (dispatch) => {
    dispatch({type: FETCHING_REVIEWS});
    const res = await Axios.get(`${config.real_backend_url}/api/user/reviews`, {withCredentials: true}).catch((err) => {
        console.log(err);
    })

    if(!res){
        return null;
    }

    //console.log(res.data.reviews)
    dispatch({type: FETCH_REVIEWS, payload: res.data.reviews});
    await set_stars(res, dispatch);
    return;
}
