import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import {connect} from "react-redux";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Profile from "../views/examples/Profile.jsx"
// import Sidebar from "components/Sidebar/Sidebar.jsx";
import Sidebar from "../components/Custom_sidebar";
import Requests from "../components/Requests";
import Job_requests from "../components/Job_requests";
import Search from "../views/examples/Search";
import Map from "../views/examples/Maps";

import {set_attempted_url} from "../actions";

import RMT_header from "../components/RMT_header";

import ProtectedRoute from "../components/protected_route";

import routes from "routes.js";

class Admin extends React.Component {
  componentDidUpdate(pp, ps) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;

    if(!this.props.socket.hasListeners("reconnect")){
      this.props.socket.on("reconnect", () => {
          this.props.socket.emit("add client", this.props.user.email);
      });
    }

    if(this.props.logged_in && this.props.user.type && this.props.attempted_url && (this.props.location.pathname === this.props.attempted_url)){
      this.props.set_attempted_url("");
    }else if(this.props.logged_in && this.props.user.type && this.props.attempted_url && (this.props.location.pathname !== this.props.attempted_url)){
      this.props.history.push(this.props.attempted_url);
    } 
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <ProtectedRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    const {role, type} = this.props.user;
    return (
      <>
        <RMT_header />
        
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/argon-react.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <Switch>
          {role || type?(role.indexOf("admin") !== -1 ||type === "comp"?<ProtectedRoute path="/admin/maps" component={Map} />:null):null}
          
          {role?(role.indexOf("admin") !== -1?<ProtectedRoute path="/admin/search" component={Search} />:null):null}

          {type === "comp"?<ProtectedRoute path="/admin/requests" component={Requests} />:null}
          
          {type === "comp"?<ProtectedRoute path="/admin/job_requests" component={Job_requests} />:null}

          <ProtectedRoute path="/admin/edit-profile" component={Profile} />

          {this.getRoutes(routes)}
          <Redirect from="/admin/" to="/admin/index" />
          </Switch>
          <Container fluid>
            {/* <AdminFooter /> */}
          </Container>
        </div>
      </>
    );
  }
}

function map_state_to_props({user, app, auth}){
  return {user, attempted_url: app.attempted_url, logged_in: auth.logged_in, socket: app.socket}
}

export default withRouter(connect(map_state_to_props, {set_attempted_url})(Admin));
