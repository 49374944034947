import {REVIEW_JOB, FETCH_REVIEWS, LOGOUT, FETCHING_REVIEWS, FETCH_REVIEW_ERROR} from "../actions/types";

const INITIAL_STATE =  {reviews: [], loading: false};

export default (state = INITIAL_STATE, {type, payload}) => {
    let new_state = {...state};

    switch(type){

        case FETCH_REVIEWS:
            new_state.reviews = payload;
            new_state.loading = false;
        break;

        case FETCHING_REVIEWS:
            new_state.loading = true;
        break;
        
        case REVIEW_JOB:
            new_state.reviews.push(payload);
        break;

        case FETCH_REVIEW_ERROR:
            new_state.loading = false;
        break;

        case LOGOUT:
            new_state = INITIAL_STATE;
        break;

        default:
            return state;

    }

    return new_state;
}