import React, { Component } from 'react';
import {connect} from "react-redux";
import config from '../../config';
import Skill from "../Profile/profile_skill";

import {open_job_request_modal} from "../../actions";

// import "./profile.css"

import {
  UncontrolledTooltip
} from "reactstrap"

class History_header extends Component {
    state = {}

    onPress_request_job = () => {
      this.props.open_job_request_modal();
    }

    render_profile_image(){
        const {photo_url, type} = this.props.technician;
        
        if(photo_url){
          return(
            <img
              alt="..."
              className={"img-circle rounded-circle img-thumbnail" + (type==="tech"?" technician-image":" company-image")}
              style={{objectFit: "cover", maxWidth: 180, maxHeight: 180, minWidth: 100, minHeight: 100, height: 180}}
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
          if(type === "tech"){
            return(
              <img
                alt="..."
                className={"img-circle rounded-circle img-thumbnail" + (type==="tech"?" technician-image":" company-image")}
                style={{objectFit: "cover", maxWidth: 180, maxHeight: 180, minWidth: 100, minHeight: 100, height: 180}}
                src={require("assets/images/profile_avatar.png")}
              />
            )
          }else if(type === "comp"){
            return(
              <img
                alt="..."
                className={"img-circle rounded-circle img-thumbnail" + (type==="tech"?" technician-image":" company-image")}
                style={{objectFit: "cover", maxWidth: 180, maxHeight: 180, minWidth: 100, minHeight: 100, height: 180}}
                src={require("assets/images/company_avatar.png")}
              />
            )
          }
        }
    }

    render_name(){
        const {technician: {name}, company} = this.props;

        return (
            <>
                <h1 className="profile-name">{`${name.first} ${name.last}`}</h1>
                <p className="profile-comp-name">{company?company.comp_name:"No Company"}</p>
            </>
        )
    }

    render_star(star, avg_rating){
      if(avg_rating > star){
          return <i className="fas fa-star" style={{color: "#f7bd4b"}} />
      }else{
          if(avg_rating - (star - 1) > 0){
              return <i className="fas fa-star-half-alt" style={{color: "#f7bd4b"}} />
          }else{
              return <i className="fas fa-star" style={{color: "#aaa"}} />
          }
      }
    }
    
    render() { 
        const {technician: {type, _id, categories}, completed_jobs, reviews, referrals, from_discover} = this.props;
        const total_stars = reviews.map((rev) => rev.star_rating).reduce((prev, curr) => prev + curr, 0);
        return (
            <div className="profile-header">
              <div className="profile-header-overlay">
                <div className="profile-info-and-image">
                    {this.render_profile_image()}
                    <span className="profile-info">
                        {this.render_name()}
                    </span>
                </div>

                <div className="profile-stats">
                    {/* {(jobs?(stars/jobs):0).toFixed(1)} <i className="fas fa-star" color="#f7bd4b"/> */}

                    {window.innerWidth >= 900?<span id={"header-total_stars"+_id} style={{marginRight: 20}}>
                    {this.render_star(1, (total_stars/completed_jobs))}
                    {this.render_star(2, (total_stars/completed_jobs))}
                    {this.render_star(3, (total_stars/completed_jobs))}
                    {this.render_star(4, (total_stars/completed_jobs))}
                    {this.render_star(5, (total_stars/completed_jobs))}
                    </span>:<span id={"header-total_stars"+_id}>{(total_stars||0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b", fontSize: 20}} /></span>}

                    {window.innerWidth >= 900?<UncontrolledTooltip delay={0} target={"header-total_stars"+_id}>
                      {(total_stars||0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b"}} />
                    </UncontrolledTooltip>:<UncontrolledTooltip delay={0} target={"header-total_stars"+_id}>
                      {this.render_star(1, (total_stars/completed_jobs))}
                      {this.render_star(2, (total_stars/completed_jobs))}
                      {this.render_star(3, (total_stars/completed_jobs))}
                      {this.render_star(4, (total_stars/completed_jobs))}
                      {this.render_star(5, (total_stars/completed_jobs))}
                    </UncontrolledTooltip>}

                    <span>{completed_jobs} <img className="spanner-icon" src="/assets/images/spanner_icon@2x.png" /> {/* <i className="fas fa-wrench" color="#f7bd4b"/> */}</span>
                </div>
                </div>

                <div className="skills-request">
                  <div className='skills'>
                    {categories.map((category) => {
                      return <Skill skill={category} />
                    })}
                  </div>

                  {from_discover?<div>
                    <button onClick={this.onPress_request_job} className="request-button">Request Job</button>
                  </div>:null}
                </div>
            </div>
        );
    }
}


export default connect(null, {open_job_request_modal})(History_header);