import React, { Component } from 'react';
import {connect} from "react-redux";
import RMT_header from "../RMT_header";
import Profile_history from "./profile_history_column";
import Profile_header from "./Profile_header";
import Profile_info_tech from "./profile_info_column";
import Profile_info_comp from "./profile_comp_info_column";
import Profile_employees from "./profile_employee_column";

class Profile extends Component {
    state = {  }
    render() { 
        const {type} = this.props.user;
        return (
            <>
            {/* <RMT_header /> */}
            <div className="profile-container" style={{paddingTop: 78}}>

                <Profile_header />
                
                <div className="profile-wrapper">
                    <div className="profile columns-2">
                        <div className="column info-column">
                            {type === "tech"?<Profile_info_tech />:type === "comp"?<Profile_info_comp />:null}
                        </div>

                        <div className="column history-column">
                        {type === "tech"?<Profile_history />:type === "comp"?<Profile_employees />:null}
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

function map_state_to_props({user}){
    return {user};
}
 
export default connect(map_state_to_props)(Profile);