import React, { Component } from 'react';

class Profile_skill extends Component {
    state = {  }
    render() { 
        const {skill} = this.props
        return (
            <p className="profile-skill">
                {skill}
            </p>
        );
    }
}
 
export default Profile_skill;