import React, { Component } from 'react';
import {connect} from "react-redux";
import RMT_header from "../RMT_header"
import Technician_modal from "./technician_modal";

import config from '../../config';
import {accept_employee, reject_employee, discover_click_tecnician} from "../../actions";

import "./requests.css"

class Requests extends Component {
    state = {  }

    render_technician_image(photo_url){
        
        if(photo_url){
          return(
            <img
              alt="..."
              className="img-circle rounded-circle img-thumbnail employee-image"
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                alt="..."
                className="img-circle rounded-circle img-thumbnail employee-image"
                src={require("assets/images/profile_avatar.png")}
              />
            )
        }
    }

    onPress_technician = async (technician) => {
        this.props.discover_click_tecnician(technician);
    }

    render_requests(){
        const {incoming_employees, employees, requests} = this.props

        return incoming_employees.map((employee) => {
            const request = (requests.find((req) => {return req.employee === employee._id}));
            return (
                <>
                <Technician_modal />
                <div className="employee-request-card-container">
                    <div className="employee-request-card">
                        <span style={{display: "flex"}}>
                        <div style={{cursor: "pointer"}} onClick={() => {this.onPress_technician(employee)}} className="employee-info">
                            {this.render_technician_image(employee.photo_url)}
                        </div>

                        <div className="employee-info-details">
                            <h2>{`${employee.name.first} ${employee.name.last}`}</h2>
                            <p><i style={{marginRight: 10}} className="far fa-envelope" /> {employee.email}</p>
                            <p><i style={{marginRight: 10}} className="fas fa-phone" /> {employee.phone}</p>
                        </div>
                        </span>

                        <div className="request-actions-container">
                            <button onClick={() => {if(!request.accepted && !request.rejected){this.onPress_accept(request._id)}}} className="accept-button">Accept</button>
                            <button onClick={() => {if(!request.accepted && !request.rejected){this.onPress_reject(request._id)}}} className="decline-button">Decline</button>
                        </div>

                        <div className="slant-helper"></div>
                        <div className="slant"></div>
                    </div>
                </div>
                </>
            )
        })
    }

    onPress_accept = async (request_id) => {
        
        await this.props.accept_employee(request_id);

        return;
    }

    onPress_reject = async (request_id) => {
        
        await this.props.reject_employee(request_id);

        return;
    }

    render() { 
        return (
            <>
            {/* <RMT_header /> */}

            <div className="requests-container-wrapper" style={{paddingTop: 78}}>
                <div className="requests-options-wrapper">
                    <div className="requests-options">
                        <span className="options-buttons">
                            <button className="option-current">Imcoming Request</button>
                            <button className="option-archive">Something Else</button>
                        </span>
                    </div>
                </div>

                <div className="requests-container">
                    <ul className="requests">
                        {this.render_requests()}
                    </ul>
                </div>
            </div>
            </>
        );
    }
}

function map_state_to_props({user, requests, employees}){
    return {requests: requests.requests, employees: employees.employees, incoming_employees: employees.pending_employees, user};
}
 
export default connect(map_state_to_props, {accept_employee, reject_employee, discover_click_tecnician})(Requests);