import React from "react";
import {connect} from "react-redux";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class Header extends React.Component {
  render() {
    const {current_users, total_activated_users, total_unactivated_users, discover_presses, discover_searches, customer_traffic, job_request_count} = this.props;
    return (
      <>
        <div style={{background: "linear-gradient(175deg, #1f4364 0%, #1a3279 100%)"}} className="header pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0 bg-white">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Current Users
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {current_users}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-nowrap">Unique users</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0 bg-white">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total users
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {total_activated_users}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> {total_unactivated_users}
                        </span>{" "}
                        <span className="text-nowrap">{`other${total_unactivated_users === 1?"":"s"} not activated`}</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0 bg-white">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Discover usage
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{discover_presses}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-nowrap">Job Requests: {job_request_count}</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0 bg-white">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Customer Traffic
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {customer_traffic}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-nowrap">Total</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

function map_state_to_props({admin_search}){
  return {current_users: admin_search.current_users, total_activated_users: admin_search.total_activated_users, total_unactivated_users: admin_search.total_unactivated_users, discover_presses: admin_search.discover_presses, discover_searches: admin_search.discover_searches, customer_traffic: admin_search.customer_traffic, job_request_count: admin_search.job_request_count}
}

export default connect(map_state_to_props, null)(Header);
