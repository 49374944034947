import React, { Component } from 'react';
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media
} from "reactstrap";
import {connect} from "react-redux"

import {terminate_employee} from "../../actions"

import config from '../../config';

class Employee_item extends Component {
    state = { stars: 0, referrals: 0 }

    componentDidMount(){
        const {reviews, referrals, employee: {_id}} = this.props;
        
        const s = reviews.map((review) => review.user === _id?review.star_rating:0).reduce((prev, curr) => {return prev+curr}, 0);

        const r = referrals.filter((referral) => referral.technician === _id).length;

        this.setState({stars: s, referrals: r});
    }

    onPress_terminate(){
      const {employee} = this.props;
      this.props.terminate_employee(employee);
    }

    render_technician_image(){
        const {photo_url} = this.props.employee;
        if(photo_url){
            return(
              <img
                  className="img-circle rounded-circle img-thumbnail"
                  style={{height: "inherit", objectFit: "cover", padding: 0}}
                  alt="profile image"
                  src={`${config.real_backend_url}/uploads/images/${photo_url}`}
              />
            )
          }else{
              return(
                <img
                  className="img-circle rounded-circle img-thumbnail"
                  style={{height: "inherit", objectFit: "cover", padding: 0}}
                  alt="profile image"
                  src={require("assets/images/profile_avatar.png")}
                />
              )
          }
    }

    render() {
        const {name, email, phone} = this.props.employee;
        const {stars, referrals} = this.state;
        return (
            <tr>
                <th scope="row">
                    <Media className="align-items-center">
                        <a
                        className="avatar rounded-circle mr-3"
                        onClick={e => e.preventDefault()}
                        >
                            {this.render_technician_image()}
                        </a>
                        <Media>
                        <span className="mb-0 text-sm">
                            {`${name.first} ${name.last}`}
                        </span>
                        </Media>
                    </Media>
                </th>

                <td>{referrals}</td>

                <td>{email}</td>

                <td>{phone}</td>

                <td>
                    <i className="fas fa-star" style={{color: "#e7b136"}} /> {stars.toFixed(1)}
                </td>

                <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={this.onPress_terminate.bind(this)}
                            >
                              Terminate
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
            </tr>
        );
    }
}

function map_state_to_props({reviews, referrals}){
    return {reviews: reviews.reviews, referrals: referrals.referrals};
}
 
export default connect(map_state_to_props, {terminate_employee})(Employee_item);