import {combineReducers} from "redux";
import app_reducer from "./app_reducer";
import user_reducer from "./user_reducer";
import auth_reducer from "./auth_reducer";
import jobs_reducer from "./Jobs_reducer";
import reviews_reducer from "./reviews_reducer";
import history_reducer from "./history_reducer";
import updates_reducer from "./updates_reducer";
import requests_reducer from "./request_reducer";
import review_reducer from "./job_review_reducer";
import discover_reducer from "./discover_reducer";
import rankings_reducer from "./rankings_reducer";
import referrals_reducer from "./referrals_reducer";
import dashboard_reducer from "./dashboard_reducer";
import create_reducer from "./job_creation_reducer";
import user_edit_reducer from "./user_edit_reducer";
import companies_reducer from "./companies_reducer";
import employees_reducer from "./employees_reducer";
import admin_search_reducer from "./admin_reducer";
import password_edit_reducer from "./change_password_reducer";
import forgot_password_reducer from "./forgot_password_reducer";

export default combineReducers({
    app: app_reducer,
    auth: auth_reducer,
    jobs: jobs_reducer,
    user: user_reducer,
    dash: dashboard_reducer,
    updates: updates_reducer,
    reviews: reviews_reducer,
    history: history_reducer,
    job_review: review_reducer,
    job_create: create_reducer,
    requests: requests_reducer,
    discover: discover_reducer,
    rankings: rankings_reducer,
    user_edit: user_edit_reducer,
    companies: companies_reducer,
    employees: employees_reducer,
    referrals: referrals_reducer,
    forgot: forgot_password_reducer,
    admin_search: admin_search_reducer,
    password_edit: password_edit_reducer
});