import React, { Component } from 'react';
import {connect} from "react-redux";
import RMT_header from "../../components/RMT_header"
import {Input} from "reactstrap"
import Job_item from "./job_item";
import Job_creation_modal from "./Job_creation_modal";

import {start_search, clear_search, search_form_update, stop_search, show_job_creation_modal, switch_job_view} from "../../actions";

import "./jobs.css"

class Jobs extends Component {
    state = {  }

    onChange_search_text = ({target: {value}}) => {
        this.props.search_form_update(value);
    }

    onPress_search_icon = () => {
        this.search_input.focus();
    }

    onPress_x_icon = () => {
        this.search_input.blur();
        this.props.stop_search();
    }

    onPress_create_job = () => {
        this.props.show_job_creation_modal();
    }

    onPress_job_type = (type) => {
        if(this.props.job_type !== type){
            this.props.switch_job_view(type);
        }
    }

    render() { 
        const {jobs, searched_jobs, searching, search_string, user_type, job_type} = this.props;
        const jobs_to_use = searching?searched_jobs:jobs;
        return (
            <>
            {/* <RMT_header/> */}

            <div className="jobs-container-wrapper" style={{paddingTop: 78}}>

                {user_type === "tech"?<div className="create-job-button-container">
                    <button onClick={this.onPress_create_job} className="button job-create-button"><img src="/assets/images/icon_jobs.png" /> Create New Job</button>
                </div>:null}

                <div className="jobs-options-wrapper">
                    <div className="jobs-options">
                        <span className="options-buttons">
                            <button className={`option-current ${job_type === "current"?"selected-job-type":""}`} onClick={() => {this.onPress_job_type("current")}}>Current</button>
                            <button className={`option-archive ${job_type === "archived"?"selected-job-type":""}`} onClick={() => {this.onPress_job_type("archived")}}>Archived</button>
                        </span>

                        <span className="options-search">
                            <Input className="jobs-search" placeholder="Search Jobs" innerRef={(input) => {this.search_input = input}} onChange={this.onChange_search_text} value={search_string} />
                            <i className={`fas fa-${searching?"times":"search"} jobs-search-icon`} style={{cursor: "pointer"}} onClick={searching?this.onPress_x_icon:this.onPress_search_icon} />
                        </span>
                    </div>
                </div>

                <div className={"jobs-container " + (job_type === "current"?"current-jobs":"archived-jobs")}>
                    <ul>
                    {jobs_to_use.filter((j) => {
                        if(job_type === "current"){
                            return j.status === "not started" || j.status === "started";
                        }else if(job_type === "archived"){
                            return j.status === "archived" || j.status === "completed";
                        }
                    }).map((job, i, jobs_arr) => {
                        if(i % 2 == 0){
                            return(
                            <li>
                                <div className="jobs-row">
                                    <Job_item job={jobs_arr[i]} />
                                    {((jobs_arr.length - 1) >= (i + 1))?<Job_item job={jobs_arr[i+1]} />:null}
                                </div>
                            </li>
                            );
                        }else{
                            return null
                        }
                    })}
                    </ul>
                </div>
            </div>

            <Job_creation_modal />
            </>
        );
    }
}

function map_state_to_props({jobs, user}){
    return {jobs: jobs.data, searching: jobs.searching, search_string: jobs.search_string, searched_jobs: jobs.search_data, user_type: user.type, job_type: jobs.type};
}
 
export default connect(map_state_to_props, {start_search, search_form_update, clear_search, stop_search, show_job_creation_modal, switch_job_view})(Jobs);