import {SEARCH_USERS, SEARCH_TEXT_UPDATE, SET_CURRENT_USERS, SET_TOTAL_USERS, DISCOVER_PRESSED_USAGE, DISCOVER_SEARCH_USAGE, SET_CUSTOMER_TRAFFIC, SET_JOB_REQUEST_COUNT} from "../actions/types";

const INITIAL_STATE = {users: [], current_users: 0, total_activated_users: 0, total_unactivated_users: 0, search_text: "", searched: false, discover_presses: 0, discover_searches: 0, customer_traffic: 0, job_request_count: 0};

export default (state = INITIAL_STATE, {type, payload}) => {
    const new_state = {...state};
    
    switch(type){
        case SEARCH_USERS:
            new_state.searched = true;
            new_state.users = payload.users;
        break;

        case SEARCH_TEXT_UPDATE:
            new_state.search_text = payload;
        break;

        case SET_CURRENT_USERS:
            new_state.current_users = payload;
        break;

        case SET_TOTAL_USERS:
            new_state.total_activated_users = payload[0]._id === "activated"?payload[0].total:payload[1].total;

            new_state.total_unactivated_users = payload[1]._id === "unactivated"?payload[1].total:payload[0].total;
        break;

        case DISCOVER_PRESSED_USAGE:
            new_state.discover_presses = payload;
        break;

        case DISCOVER_SEARCH_USAGE:
            new_state.discover_searches = payload;
        break;

        case SET_CUSTOMER_TRAFFIC:
            new_state.customer_traffic = payload;
        break;

        case SET_JOB_REQUEST_COUNT:
            new_state.job_request_count = payload;
        break;

        default:
            return state
    }

    return new_state;
}