import axios from "axios";
import {FORGOT_PASSWORD, FORGOT_FORM_UPDATE, FORGOT_EMAIL_VERIFIED, FORGOT_PASSWORD_CHANGED, FORGOT_PASSWORD_NOT_CHANGED, FORGOT_INVALID_EMAIL} from "./types";
import valid_email from "../utils/valid-email";
import config from "../config";


export const forgot_password = () => {
    return ({type: FORGOT_PASSWORD});
}

export const forgot_form_update = (key, value) => {
    return ({type: FORGOT_FORM_UPDATE, payload: {key, value}});
}

export const is_email_verified = (email) => async (dispatch) => {
    if(valid_email(email)){
        const Email = await encodeURIComponent(email);
        const res = await axios.get(`${config.real_backend_url}/api/isactivated/${Email}`).catch((err) => {
            alert(JSON.stringify(err));
            return null;
        });

        if(res.data.activated){
            dispatch({type: FORGOT_EMAIL_VERIFIED});
            return true;
        }else{
            return false;
        }
    }else{
        dispatch({type: FORGOT_INVALID_EMAIL})
    }
}

export const change_forgotten_password = (email, new_pass) => async (dispatch) => {
    const res = await axios.post(`${config.real_backend_url}/api/changepassword`, {email, new_pass}).catch((err) => {
        alert(JSON.stringify(err));
        return null;
    });

    if(res.data.success === true){
        dispatch({type: FORGOT_PASSWORD_CHANGED});
        return true;
    }else{
        dispatch({type: FORGOT_PASSWORD_NOT_CHANGED});
        return false;
    }
}