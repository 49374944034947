import React, { Component } from 'react';
import {connect} from "react-redux";

import {archive_job, delete_job, restore_job} from "../../actions";

import config from '../../config';

import {UncontrolledDropdown, Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";

class Job_item extends Component {
    state = {  }

    onPress_archive = () => {
        const {job} = this.props;
        this.props.archive_job(job._id);
    }

    onPress_delete = () => {
        const {job} = this.props;
        this.props.delete_job(job._id);
    }

    onPress_restore = () => {
        const {job} = this.props;
        this.props.restore_job(job._id);
    }

    render_job_actions(){
        const {job_type, job} = this.props;
        if(job_type === "current"){
            return (
                <DropdownMenu>
                    <DropdownItem onClick={this.onPress_archive}>
                        Archive
                    </DropdownItem>

                    <DropdownItem onClick={this.onPress_delete}>
                        Delete
                    </DropdownItem>
                </DropdownMenu>
            );
        }else if(job_type === "archived"){
            if(job.status === "completed"){
                return (
                    <DropdownMenu>
                        <DropdownItem>
                            No Actions
                        </DropdownItem>
                    </DropdownMenu>
                );
            }else{
                return (
                    <DropdownMenu>
                        {/* <DropdownItem onClick={this.onPress_restore}>
                            Restore
                        </DropdownItem>

                        <DropdownItem onClick={this.onPress_delete}>
                            Delete
                        </DropdownItem> */}
                    </DropdownMenu>
                );
            }
        }
    }

    render() { 
        const {job, job_type} = this.props;
        return (
            <div className="job-item-wrapper">
                <div className="job-item">
                    <div className="job-details-wrapper">
                        <span className="job-icon">
                            <i className="fas fa-sign-language" />
                        </span>

                        <span className="job-details">
                            <h4 className="job-title">{job.title}</h4>
                            <small className="job-category text-muted">{job.category}</small>
                        </span>
                    </div>

                    <div className="job-description">
                        <div className="job-images">
                            {job.started && job.image_urls.before[0]?<img alt="before image" src={`${config.real_backend_url}/uploads/images/${job.image_urls.before[0].url}`} />:null}
                            
                            {job_type === "archived" && job.status === "completed" && job.image_urls.after[0]?<img alt="after image" src={`${config.real_backend_url}/uploads/images/${job.image_urls.after[0].url}`} />:null}
                        </div>

                        <p>{job.description}</p>
                    </div>
                </div>

                <UncontrolledDropdown className="ucdropdown">
                <DropdownToggle className="job-elipses">
                    ...
                </DropdownToggle>

                {this.render_job_actions()}
                </UncontrolledDropdown>
            </div>
        );
    }
}

function map_state_to_props({jobs}) {
    return ({job_type: jobs.type});
}
 
export default connect(map_state_to_props, {archive_job, delete_job, restore_job})(Job_item);