import React, { Component, Fragment } from 'react';
import Comp_profile_modal from "../Profile/company_modal";
import Tech_profile_modal from "../Profile/technician_modal";
import Request_sent_modal from "./job_request_success_modal";
import Job_request_modal from "./job_request_modal";
import Skill from "../Profile/profile_skill";

import Categories from "../index/categories";
import Cities from "../index/cities";
import States from "../index/states";

import {
    Col,
    Row,
    Media,
    Progress,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    UncontrolledTooltip
} from "reactstrap";

import {connect} from "react-redux";

import config from '../../config';

import {request_job, discover_click_tecnician, discover_click_company, discover_form_update, set_requested_technician, set_history_technician, set_history_jobs, set_history_reviews, set_history_completed_jobs_count, set_history_from_discover, open_job_request_modal, discover_result_click_technician, discover_results_close_technician, discover_results_change_view} from "../../actions";

import cities from '../index/cities';
import states from '../index/states';

class Discover_search_table_item extends Component {
    state = { show_category_suggestion: false, category_selected: true, current_category_index: -1, show_state_suggestion: false, state_selected:true, current_state_index: -1, show_city_suggestion: false, city_selected: true, current_city_index: -1, stars: 0, open_modal: false, customer_name: "", customer_name_error: false, city_location_error: false, category_error: false, email_error: false, description_error: false, phone_error: false, selected_tech: false, clicked: false }

  date_diff(date1, date2){
    const day = (1000 * 60 * 60 * 24);
    let time_diff = Math.abs(date1 - date2);
    return Math.ceil(time_diff/day);
  }

  relative_day_string(date){
      let diff = this.date_diff(date, new Date().getTime());

      //console.log("day diff",diff);
      const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday" ]
      if(diff === 1 || diff === 0){
          return "Today,"
      }else if(diff === 2){
          return "Yesterday,"
      }else{
          const day = new Date(date).getDay();
          return days[day] + ",";
      }
  }

  short_month_name(date){
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
      return months[(new Date(date).getMonth() - 1)];
  }

  date_with_suffix(date){
      let day = new Date(date).getDate();

      if(day.toString().slice(-1) === "1" && (day.toString().slice(-2, -1) !== "1")){
          return day+"st"
      }else if(day.toString().slice(-1) === "2" && (day.toString().slice(-2, -1) !== "1")){
          return day+"nd"
      }else if(day.toString().slice(-1) === "3" && (day.toString().slice(-2, -1) !== "1")){
          return day+"rd"
      }else{
          return day+"th"
      }
  }

  local_time_str(date){
      let time = (new Date(date).toLocaleTimeString("en-US", {hour12: false})).substr(0,5);
      let hours = Number(time.substr(0, 2));

      if(hours > 12){
          time += " PM";
          time = (hours - 12).toString().padStart(2, 0) + time.substr(2)
      }else{
          time += " AM";
      }
      return time;
  }

    componentDidMount(){
        const {reviews} = this.props.technician;
        const a = reviews.map((review)=>review.star_rating).reduce((prev, curr) => {return prev+curr}, 0);
        this.setState({stars: a});
    }

    toggle_model = () => {
      this.setState({open_modal: !this.state.open_modal});
    }

    onPress_request = (e) => {
      e.stopPropagation();
      this.props.discover_click_tecnician(this.props.technician);
      this.props.set_requested_technician(this.props.technician._id);
      this.props.open_job_request_modal();
      // this.toggle_model();
    }

    onPress_technician = () => {
      const {technician} = this.props;
      
      this.props.discover_click_tecnician(technician);
      this.props.set_history_technician(technician);
      this.props.set_history_jobs(technician.jobs);
      this.props.set_history_reviews(technician.reviews);
      this.props.set_history_completed_jobs_count(technician.completed_jobs);
      this.props.set_history_from_discover(true);
    }

    onPress_company = (e) => {
      e.preventDefault()

      const {companies} = this.props.technician;

      this.props.discover_click_company(companies[0]);
    }

    onChange_category = ({target: {value}}) => {
      this.setState({category_selected: false, show_category_suggestion: true, category_error: false});
      this.props.discover_form_update("category", value);
    }

    onChange_city = ({target: {value}}) => {
      this.setState({city_selected: false, show_city_suggestion: true, city_location_error: false});
      this.props.discover_form_update("city", value);
    }
    onChange_state = ({target: {value}}) => {
      this.setState({state_selected: false, show_state_suggestion: true, state_location_error: false});
      this.props.discover_form_update("state", value);
    }

    onChange_email = ({target: {value}}) => {
      this.setState({email_error: false});
      this.props.discover_form_update("email", value);
    }

    onChange_phone = ({target: {value}}) => {
      this.setState({phone_error: false})
      this.props.discover_form_update("phone", value);
    }

    onChange_description = ({target: {value}}) => {
      this.setState({description_error: false});
      this.props.discover_form_update("description", value);
    }

    onKeyDown_city = (e) => {
      const {current_city_index} = this.state;

      if(e.which === 13 && current_city_index > -1){//enter
          this.props.discover_form_update("city", this.match_cities[current_city_index]);
          this.setState({show_city_suggestion: !this.state.show_city_suggestion, city_selected: !this.state.city_selected});
      }else if(e.which === 38){//up
          if(current_city_index <= 0){
              this.setState({current_city_index: this.match_cities.length-1});
          }else{
              this.setState({current_city_index: this.state.current_city_index-1});
          }
      }else if(e.which === 40){//down
          if(current_city_index >= this.match_cities.length-1){
              this.setState({current_city_index: 0});
          }else{
              this.setState({current_city_index: this.state.current_city_index+1});
          }
      }
  }

    onKeyDown_category = (e) => {
      const {current_category_index} = this.state;

      if(e.which === 13 && current_category_index > -1){//enter
          this.props.discover_form_update("category", this.match_categories[current_category_index].label);
          this.setState({show_category_suggestion: !this.state.show_category_suggestion, category_selected: !this.state.category_selected});
      }else if(e.which === 38){//up
          if(current_category_index <= 0){
              this.setState({current_category_index: this.match_categories.length-1});
          }else{
              this.setState({current_category_index: this.state.current_category_index-1});
          }
      }else if(e.which === 40){//down
          if(current_category_index >= this.match_categories.length-1){
              this.setState({current_category_index: 0});
          }else{
              this.setState({current_category_index: this.state.current_category_index+1});
          }
      }
    }
    onKeyDown_state = (e) => {
      const {current_state_index} = this.state;

      if(e.which === 13 && current_state_index > -1){//enter
          this.props.discover_form_update("state", this.match_state[current_state_index]);
          this.setState({show_state_suggestion: !this.state.show_state_suggestion, state_selected: !this.state.state_selected});
      }else if(e.which === 38){//up
          if(current_state_index <= 0){
              this.setState({current_state_index: this.match_state.length-1});
          }else{
              this.setState({current_state_index: this.state.current_state_index-1});
          }
      }else if(e.which === 40){//down
          if(current_state_index >= this.match_state.length-1){
              this.setState({current_state_index: 0});
          }else{
              this.setState({current_state_index: this.state.current_state_index+1});
          }
      }
    }

    onPress_request_job = async () => {
      const {technician, request_job} = this.props;
      const {state, city, category, email, phone, description} = this.props.discover;
      const {customer_name} = this.state;

      if(customer_name !== "" && city !== "" && category !== "" && state !=="" && email !== "" && phone !== "" && description !== ""){
        await request_job(technician, {customer_name, city, category, email, phone, description});
        this.toggle_model();
        this.props.socket.emit("customer_request");
      }else{
        if(customer_name === ""){
          this.setState({customer_name_error: true});
        }else if(city === ""){
          this.setState({city_location_error: true});
        }else if(category === ""){
          this.setState({category_error: true});
        }else if(state === ""){
          this.setState({state_error: true});
        }else if(email === ""){
          this.setState({email_error: true});
        }else if(phone === ""){
          this.setState({phone_error: true});
        }else if(description === ""){
          this.setState({description_error: true});
        }
      }
    }

    render_request_modal(){
      // const {request_sent, state, city, email, phone, description, category} = this.props.discover;
      
      // const {open_modal, customer_name, current_state_index, current_category_index, current_city_index, customer_name_error, city_location_error, category_error, show_category_suggestion, category_selected, show_city_suggestion, city_selected, phone_error, email_error, description_error} = this.state;

      // const {technician} = this.props;

      const {open_job_request_modal} = this.props.discover
      const {technician, clicked_technician} = this.props;

      if(open_job_request_modal && technician._id === (clicked_technician && clicked_technician._id)){
        return(
          <Job_request_modal />
        );
      }
  }

    render_company_image({photo_url, _id, comp_name}){
        if(photo_url){
          return(
            <Fragment key={_id}>
                <a
                    id={"TT"+_id}
                    onClick={e => e.preventDefault()}
                >
                    <img
                        className="rounded-circle"
                        alt="company image"
                        src={`${config.real_backend_url}/uploads/images/${photo_url}`}
                    />
                </a>
                <UncontrolledTooltip
                    delay={0}
                    target={"TT"+_id}
                >
                    {comp_name}
                </UncontrolledTooltip>
            </Fragment>
          )
        }else{
            return(
            <Fragment key={_id}>
              <a
                id={"TT"+_id}
                onClick={e => e.preventDefault()}
              >
                <img
                    className="rounded-circle"
                    alt="company image"
                    src={require("assets/images/company_avatar.png")}
                />
              </a>
              <UncontrolledTooltip
                delay={0}
                target={"TT"+_id}
              >
                {comp_name}
              </UncontrolledTooltip>
            </Fragment>
            )
        }
    }

    render_profile_image(){
        const {photo_url} = this.props.technician;
        if(photo_url){
          return(
            <img
                className="img-circle rounded-circle img-thumbnail"
                style={{objectFit: "cover", padding: 0}}
                alt="profile image"
                src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                className="img-circle rounded-circle img-thumbnail"
                style={{objectFit: "cover", padding: 0}}
                alt="profile image"
                src={require("assets/images/profile_avatar.png")}
              />
            )
        }
    }

    render_modal_technician_image(){
      const {photo_url} = this.props.technician;
      if(photo_url){
        return(
          <img
              className="img-circle rounded-circle img-thumbnail"
              style={{width: 75, height: 75, objectFit: "cover", padding: 0, marginRight: 15}}
              alt="profile image"
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
          />
        )
      }else{
          return(
            <img
              className="img-circle rounded-circle img-thumbnail"
              style={{width: 75, height: 75, objectFit: "cover", padding: 0, marginRight: 15}}
              alt="profile image"
              src={require("assets/images/profile_avatar.png")}
            />
          )
      }
  }

    render_category_suggestions(){
      const {category} = this.props.discover;
      const {show_category_suggestion, category_selected, current_category_index} = this.state;

      if(((category.length > 0 || show_category_suggestion) && category_selected===false) || show_category_suggestion){
          this.match_categories = Categories.filter((c) => {
              return c.label.toUpperCase().includes(category.toUpperCase());
          })
          return this.match_categories.map((ca, i) => {
              return (
                  <p className={`suggestion ${i===current_category_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("category", ca.label); this.setState({category_selected: true, show_category_suggestion: false});}} key={ca+i}>{ca.label}</p>
              );
          })
      }
    }

    render_city_suggestions(){
      const {city} = this.props.discover;
      const {show_city_suggestion, city_selected, current_city_index} = this.state;

      if(((city.length > 0 || show_city_suggestion) && city_selected===false) || show_city_suggestion){
          this.match_cities = Cities.filter((c) => {
              return c.toUpperCase().includes(city.toUpperCase());
          });
          return this.match_cities.map((ci, i) => {
              return (
                  <p className={`suggestion ${i===current_city_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("city", ci); this.setState({city_selected: true, show_city_suggestion: false});}} key={ci+i}>{ci}</p>
              );
          })
      }
  }

  render_state_suggestions(){
    const {state} = this.props.discover;
    const {show_state_suggestion, state_selected, current_state_index} = this.state;

    if(((state.length > 0 || show_state_suggestion) && state_selected===false) || show_state_suggestion){
        this.match_state = States.filter((s) => {
            return s.toUpperCase().includes(state.toUpperCase());
        });
        return this.match_states.map((st, i) => {
            return (
                <p className={`suggestion ${i===current_state_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("state", st); this.setState({state_selected: true, show_state_suggestion: false});}} key={st+i}>{st}</p>
            );
        })
    }
}
    render_star(star, avg_rating){
      if(avg_rating > star){
          return <i className="fas fa-star" style={{color: "#f7bd4b"}} />
      }else{
          if(avg_rating - (star - 1) > 0){
              return <i className="fas fa-star-half-alt" style={{color: "#f7bd4b"}} />
          }else{
              return <i className="fas fa-star" style={{color: "#aaa"}} />
          }
      }
    }

    onPress_card = () => {
      this.props.discover_result_click_technician(this.props.technician);
    }

    onPress_close = () => {
      this.props.discover_results_close_technician();
    }

    render_history(){
      if(this.props.results_clicked_technician && this.props.results_clicked_technician._id === this.props.technician._id){
        const {technician: {reviews, jobs}} = this.props;
        const jobs_to_use = jobs.filter((job) => {
          return job.status === "completed";
        })
        return (
          <div className="technician-history-container">
            {this.render_tabs()}

            <small>Showing {reviews.length} jobs</small>

            {jobs_to_use.map((job, i) => {
              const review = reviews.find((rev) => {
                return job._id === rev.job;
              });

              const before_image = job.image_urls.before[0]?`${config.real_backend_url}/uploads/images/${job.image_urls.before[0].url}`:"";
              
              const after_image = job.image_urls.after[0]?`${config.real_backend_url}/uploads/images/${job.image_urls.after[0].url}`:"";

              if(review){
                return (
                  <div className="job-review-container">
                    <div className="images-columns">
                      {/* <img className="selected-job-image" /> */}
                      <div className="job-images">
                        <img className="before-image" src={before_image} alt="before-image" />
                        <img className="after-image" src={after_image} alt="after-image" />
                      </div>
                    </div>

                    <div className="job-review">
                      <h3 className="job-name">{job.title} #{i+1}</h3>
                      <small>{job.category} . {job.location || "Unknown Location"} . {this.relative_day_string(job.start_time)} @ {this.local_time_str(job.start_time)}</small>

                      <div className="tech-image-review-info">
                        {this.render_profile_image()}

                        <div className="review-info">
                          <span>{review.star_rating.toFixed(1)} rating </span> 
                          <span>
                            {this.render_star(1, review.star_rating)} 
                            {this.render_star(2, review.star_rating)} 
                            {this.render_star(3, review.star_rating)} 
                            {this.render_star(4, review.star_rating)} 
                            {this.render_star(5, review.star_rating)} 
                          </span>

                          <div className="review-text">
                            "{review.review_text}"
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        );
      }
    }

    onPress_tab = (view) => {

      if(view !== this.props.tab)
        this.props.discover_results_change_view(view);
    }

    render_tabs(){
      const {tab} = this.props;
      return (
        <div className="card-tabs-container">
          <span onClick={() => {this.onPress_tab("history")}} className={`card-tab ${tab==="history"?"selected-tab":""}`}>Job Reviews</span>
          <span onClick={() => {this.onPress_tab("company")}} className={`card-tab ${tab==="company"?"selected-tab":""}`}>Company Profile</span>
        </div>
      );
    }

    render_company(){
      const {companies} = this.props.technician;
      if(this.props.results_clicked_technician && this.props.results_clicked_technician._id === this.props.technician._id){
      return (
        <div className="discover-company-container">
          {this.render_tabs()}

          {companies.length > 0?<div className="discover-company">
            <div className="company-image-name">
              {this.render_company_image(companies[0])}

              <p className="company-name">{companies[0].comp_name}</p>
            </div>

            <div className="company-description">
              <p>{companies[0].description || "No Description."}</p>
            </div>

            {companies[0].website?<a className="visit-company-website" href={companies[0].website} target="_blank">Visit Website ></a>:null}

          </div>:<p>No Company.</p>}
        </div>
      );
      }
    }

    render_history_company(){
      const {tab} = this.props;
      if(tab === "history"){
        return this.render_history()
      }else if(tab === "company"){
        return this.render_company()
      }
    }

    render() { 
        const {name, email, phone, companies, completed_jobs, description, _id, categories} = this.props.technician;
        const {requested_technician_id, results_clicked_technician} = this.props;
        const {stars, selected_tech} = this.state;

        const selected = results_clicked_technician && (results_clicked_technician._id === _id);
        return (
            <>
              {requested_technician_id === _id?<Request_sent_modal technician={this.props.technician} />:null}

                {_id === (this.props.clicked_technician && this.props.clicked_technician._id)?<Tech_profile_modal onPress_request_job={this.onPress_request} />:null}

                {companies[0]?(companies[0]._id === (this.props.clicked_company && this.props.clicked_company._id)?<Comp_profile_modal />:null):null}
                
                {this.render_request_modal()}
                
                <div onClick={selected?()=>{}:this.onPress_card} className={`discover-result-container ${results_clicked_technician?(results_clicked_technician._id === _id?"clicked-technician":"technician-clicked"):""}`} ref={(div) => {this.tech_card_div = div;}}>
                  {selected?<div className="close-button"><i onClick={this.onPress_close} className="fas fa-times" style={{cursor: "pointer"}} /></div>:null}
                  <div className="image-info-container">
                    {this.render_profile_image()}

                    <div className="info-container">
                      <h1>{name.first} {name.last} 
                      <small>-{states[0] || "none"},</small>
                      <small>{cities[0] || "none"} </small>
                      </h1>

                      <span style={{margin:"2px"}}> 
                        {this.render_star(1, (stars/completed_jobs))} 
                        {this.render_star(2, (stars/completed_jobs))} 
                        {this.render_star(3, (stars/completed_jobs))} 
                        {this.render_star(4, (stars/completed_jobs))} 
                        {this.render_star(5, (stars/completed_jobs))} 

                        <span> {stars.toFixed(0)}</span>
                        <br></br>
                        {categories.map((cat) => {
                        return <Skill skill={cat.toString()} />
                      })}
                      </span>

                      </div>
                    </div>
                    
                    <div className="technician-city-category-container">
                  
                    <div className="technician-city-category">
                    
                     

                   
<hr></hr>
                    </div>

                    <div className="technician-description">
                      {description || "No Description"}
                    </div>

                    <div className="company-info">
                      <h3 style={{fontFamily:"AvenirNext-DemiBold", fontSize:"16px", color:"#000000", textAlign:"left", letterSpacing:"0.34"}}>COMPANY </h3>
                      <h3 style={{fontFamily:"AvenirNext-Medium", marginLeft: "2px", fontSize:"13px", color:"#666666", textAlign:"left", letterSpacing:"0.32"}}>{companies[0]?companies[0].comp_name:"No Company"}</h3>
                    </div>

                    {this.render_history_company()}

                  
                    </div>
                    <div className="request-tech-button-container">
                        <button onClick={this.onPress_request}>Request this Hair Stylist</button>
                    </div>
                </div>
            </>
        );
    }
}

function map_state_to_props({discover, app}){
  return ({discover, socket: app.socket, clicked_technician: discover.clicked_technician, clicked_company: discover.clicked_company, requested_technician_id: discover.requested_technician_id, results_clicked_technician: discover.discover_results_clicked_technician, tab: discover.tab});
}
 
export default connect(map_state_to_props, {request_job, discover_click_tecnician, discover_click_company, discover_form_update, set_requested_technician, set_history_technician, set_history_jobs, set_history_reviews, set_history_completed_jobs_count, set_history_from_discover, open_job_request_modal, discover_result_click_technician, discover_results_close_technician, discover_results_change_view})(Discover_search_table_item);