import React, { Component, Fragment } from 'react';
import {
    Badge,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Progress,
    UncontrolledTooltip
} from "reactstrap";

import config from '../../config';

class Discover_search_item extends Component {
    state = { stars: 0 }

    render_profile_image(){
        const {photo_url, type} = this.props.user;
        if(photo_url){
          return(
            <img
                className="img-circle rounded-circle img-thumbnail"
                style={{height: "inherit", objectFit: "cover", padding: 0}}
                alt="profile image"
                src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            if(type === "tech"){
                return(
                <img
                    className="img-circle rounded-circle img-thumbnail"
                    style={{height: "inherit", objectFit: "cover", padding: 0}}
                    alt="profile image"
                    src={require("assets/images/profile_avatar.png")}
                />
                )
            }else if(type === "comp"){
                return(
                    <img
                        className="img-circle rounded-circle img-thumbnail"
                        style={{height: "inherit", objectFit: "cover", padding: 0}}
                        alt="profile image"
                        src={require("assets/images/company_avatar.png")}
                    />
                );
            }
        }
    }

    render() { 
        const {name, email, phone, comp_name, type, website, birth, activated, completed_jobs} = this.props.user;
        return (
            <tr>
                <th scope="row">
                <Media className="align-items-center">
                    <a
                    className="avatar rounded-circle mr-3"
                    onClick={e => e.preventDefault()}
                    >
                    {this.render_profile_image()}
                    </a>
                    <Media>
                    <span className="mb-0 text-sm">
                        {comp_name || `${name.first} ${name.last}`}
                    </span>
                    </Media>
                </Media>
                </th>
                <td>{completed_jobs}</td>
                <td>{email}</td>
                <td>
                <Badge color="" className="badge-dot mr-4">
                    <i className="bg-warning" />
                    {phone}
                </Badge>
                </td>
                <td>
                    {type === "tech"?"Technician":"Company"}
                </td>
                <td>
                    {activated.toString()}
                </td>
                <td>
                {website?<a href={website} className="text-warning">{website}</a>:new Date(birth).toLocaleDateString()}
                </td>
                <td className="text-right">
                <UncontrolledDropdown>
                    <DropdownToggle
                    className="btn-icon-only text-light"
                    role="button"
                    size="sm"
                    color=""
                    onClick={e => e.preventDefault()}
                    >
                    <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                        onClick={!activated?e => e.preventDefault():e => e.preventDefault()}
                        disabled={activated}
                    >
                        {!activated?"Activate":"No Actions"}
                    </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                </td>
            </tr>
        );
    }
}
 
export default Discover_search_item;