import React, { Component } from 'react';
import {connect} from "react-redux";

import {
    Card,
    Input,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Label
} from "reactstrap";

import Search_item from "./Search_item";
import {search_users} from "../../actions";

class Search extends Component {
    state = {  }

    constructor(props){
        super(props);

        this.onChange_search = this.onChange_search.bind(this);
        props.search_users("");
    }

    onChange_search({target: {value:new_value}}){
      this.props.search_users(new_value);
    }

    render_users(){
        const {users, searched} = this.props.admin_search;
        if(users.length > 0){
        return (
        <>
        <div className="form-control">
          <Label htmlFor="Companies">Companies</Label>
          <Input id="Companies" type="checkbox" name="Companies" value="companies" />
          
          <Label htmlFor="Technicians">Technicians</Label>
          <Input id="Technicians" type="checkbox" name="Technicians" value="technician" />
        </div>
        <Container className="mt-3" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Users</h3>

                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Completed Jobs</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">type</th>
                      <th scope="col">activated</th>
                      <th scope="col">website/birth</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {searched?users.map((user, i) => {
                      return (
                          <Search_item user={user} i={i} />
                      );}):null
                    }
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
          </Container>
          </>
        )
        }else if(searched){
            return <p>No users found.</p>
        }
    }

    render() { 
        const {search_text} = this.props.admin_search;
        return (
            <div className={"col-md-12 pt-6"} style={{textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>

            <div className="col-md-11 ml-md-5 mr-md-5">
                <Input autoComplete="off" onChange={this.onChange_search} value={search_text} type="text" name="search" placeholder="Search User" className="" style={{border: "none", padding: 0}} innerRef={(input) => {this.search_input = input}}  />
            </div>
                
            {/* <div className="input-group" style={{textAlign: "center"}}>
                <button className="button button-discover" onClick={this.onPress_search}>SEARCH</button>
            </div> */}
            <ul>
                {this.render_users()}
            </ul>
        </div>
        );
    }
}

function map_state_to_props({admin_search}){
    return {admin_search};
}
 
export default connect(map_state_to_props, {search_users})(Search);