import React, { Component, Fragment } from 'react';
import {
    Badge,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Progress,
    UncontrolledTooltip
} from "reactstrap";

import config from '../../config';

class Discover_search_item extends Component {

    render_company_image({photo_url, _id, comp_name}){
        if(photo_url){
          return(
            <Fragment key={_id}>
                <a
                    className="avatar avatar-sm"
                    id={"TT"+_id}
                    onClick={e => e.preventDefault()}
                >
                    <img
                        className="rounded-circle"
                        alt="company image"
                        src={`${config.real_backend_url}/uploads/images/${photo_url}`}
                    />
                </a>
                <UncontrolledTooltip
                    delay={0}
                    target={"TT"+_id}
                >
                    {comp_name}
                </UncontrolledTooltip>
            </Fragment>
          )
        }else{
            return(
            <Fragment key={_id}>
              <a
                className="avatar avatar-sm"
                id={"TT"+_id}
                onClick={e => e.preventDefault()}
              >
                <img
                    className="rounded-circle"
                    alt="company image"
                    src={require("assets/images/company_avatar.png")}
                />
              </a>
              <UncontrolledTooltip
                delay={0}
                target={"TT"+_id}
              >
                {comp_name}
              </UncontrolledTooltip>
            </Fragment>
            )
        }
    }

    render_profile_image(){
        const {photo_url} = this.props.ranking.technician;
        if(photo_url){
          return(
            <img
                className="img-circle rounded-circle img-thumbnail"
                style={{height: "inherit", objectFit: "cover", padding: 0}}
                alt="profile image"
                src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                className="img-circle rounded-circle img-thumbnail"
                style={{height: "inherit", objectFit: "cover", padding: 0}}
                alt="profile image"
                src={require("assets/images/profile_avatar.png")}
              />
            )
        }
    }

    render_star(star, avg_rating){
        if(avg_rating > star){
            return <i className="fas fa-star" style={{color: "#f7bd4b"}} />
        }else{
            if(avg_rating - (star - 1) > 0){
                return <i className="fas fa-star-half-alt" style={{color: "#f7bd4b"}} />
            }else{
                return <i className="fas fa-star" style={{color: "#aaa"}} />
            }
        }
      }

    render() { 
        const {technician: {name, email, phone, _id}, job_completed, total_stars} = this.props.ranking;
        return (
            <tr>
                <th scope="row">
                <Media className="align-items-center leaderboard-technician-name-image">
                    <a
                    className="avatar rounded-circle mr-3"
                    // onClick={this.onPress_technician}
                    >
                    {this.render_profile_image()}
                    </a>
                    <Media>
                    <span className="mb-0">
                        {`${name.first} ${name.last}`}
                    </span>
                    </Media>
                </Media>
                </th>
                <td>
                <div className="align-items-center">
                    <div className="leaderboard-table-stats-container">
                        {/* {(completed_jobs?(stars/completed_jobs):0).toFixed(1)} <i id={"stars"+_id} className="fas fa-star" style={{color: "#f7bd4b", marginRight: 25}} /> */}

                        {window.innerWidth >= 700?<span className="leaderboard-table-stars-container" id={"leaderboard-stars"+_id} >
                        {this.render_star(1, (total_stars/job_completed))}
                        {this.render_star(2, (total_stars/job_completed))}
                        {this.render_star(3, (total_stars/job_completed))}
                        {this.render_star(4, (total_stars/job_completed))}
                        {this.render_star(5, (total_stars/job_completed))}
                        </span>:<span id={"leaderboard-stars"+_id} className="leaderboard-table-star-mobile">{(total_stars || 0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b"}} /></span>}

                        {window.innerWidth >= 700?<UncontrolledTooltip delay={0} target={"leaderboard-stars"+_id}>
                            {((total_stars)||0).toFixed(1)} <i id={"stars"+_id} className="fas fa-star" style={{color: "#f7bd4b", marginRight: 25}} />
                        </UncontrolledTooltip>:<UncontrolledTooltip delay={0} target={"leaderboard-stars"+_id}>
                        {this.render_star(1, (total_stars/job_completed))}
                        {this.render_star(2, (total_stars/job_completed))}
                        {this.render_star(3, (total_stars/job_completed))}
                        {this.render_star(4, (total_stars/job_completed))}
                        {this.render_star(5, (total_stars/job_completed))}
                        </UncontrolledTooltip>}

                        {/* {"10"} <img className="leaderboard-curve-arrow-icon" src="/assets/images/curved_arrow@2x.png" /> <i className="fas fa-redo-alt" style={{color: "#f7bd4b"}} /> */}
                    </div>
                </div>
                </td>

                <td className="jobs-td">
                    {job_completed} <img className="leaderboard-spanner-icon" src="/assets/images/spanner_icon@2x.png" />
                </td>
            </tr>
        );
    }
}
 
export default Discover_search_item;