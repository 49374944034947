import React, { Component } from 'react';
import {connect} from "react-redux";
import RMT_header from "../RMT_header"
import Technician_modal from "../Requests/technician_modal";

import config from '../../config';
import {accept_job_request, reject_job_request, discover_click_tecnician} from "../../actions";

import {UncontrolledDropdown, Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";


import "./job_request.css";

class Job_requests extends Component {
    state = {  }

    render_technician_image(photo_url){
        
        if(photo_url){
          return(
            <img
              alt="..."
              className="img-circle rounded-circle img-thumbnail technician-image"
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                alt="..."
                className="img-circle rounded-circle img-thumbnail technician-image"
                src={require("assets/images/profile_avatar.png")}
              />
            )
        }
    }

    onPress_technician = async (technician) => {
        this.props.discover_click_tecnician(technician);
    }

    render_job_requests(){
        const {updates, referrals, employees} = this.props

        return updates.filter((update) => update.update_type === "job_request").map((ref_update) => {
            const referral = (referrals.find((ref) => {return ref._id === ref_update.referral}));
            const employee = (employees.find((emp) => {return emp._id === referral.technician}))

            if(referral && employee && !referral.accepted && !referral.rejected){
                return (
                    <>
                    <Technician_modal />
                    <div className="job-request-card-container">
                        <div className="job-request-card">
                            <div className="customer-info">
                                <div className="job-info">
                                    <i className="ni ni-circle-08" style={{color: "#104470"}} />
                                </div>

                                <div className="job-info-details">
                                    <h2>{referral.customer_details.customer_name}</h2>
                                    <p><i style={{marginRight: 10}} className="far fa-envelope" /> {referral.customer_details.email || "email@email.com"}</p>
                                    <p><i style={{marginRight: 10}} className="fas fa-phone" /> {referral.customer_details.phone || "123456789"}</p>
                                </div>
                            </div>

                            <p>{referral.customer_details.category}</p>

                            <p style={{height: 100, overflowY: "auto"}}>{referral.customer_details.description || "Hello this is supposed to be a description. When the request modal is done this will display the customers description."}</p>

                            <div className="technician-image">
                                {this.render_technician_image(employee.photo_url)}

                                <div>
                                    <small>Requested Technician</small>
                                    <p style={{margin: 0}}>{`${employee.name.first} ${employee.name.last}`}</p>
                                </div>
                            </div>

                            <div className="job-request-actions-container">
                                <button onClick={() => {this.onPress_accept_job(referral._id)}} className="job-request-button-slant"></button>
                                <button onClick={() => {this.onPress_accept_job(referral._id)}} className="job-request-button">Accept Job</button>
                            </div>

                            <UncontrolledDropdown className="ucdropdown">
                                <DropdownToggle className="job-request-elipses">
                                    ...
                                </DropdownToggle>

                                {this.render_job_request_actions(referral)}
                            </UncontrolledDropdown>
                        </div>
                    </div>
                    </>
                )
            }
        })
    }

    render_job_request_actions(referral){
        return (
            <DropdownMenu>
                <DropdownItem onClick={() => {this.onPress_accept_job(referral._id)}}>
                    Accept
                </DropdownItem>

                <DropdownItem onClick={() => {this.onPress_reject_job(referral._id)}}>
                    Deny
                </DropdownItem>
            </DropdownMenu>
        );
    }

    onPress_accept_job = async (referral_id) => {
        
        await this.props.accept_job_request(referral_id);

        return;
    }

    onPress_reject_job = async (referral_id) => {
        
        await this.props.reject_job_request(referral_id);

        return;
    }

    render() { 
        return (
            <>
            {/* <RMT_header /> */}

            <div className="job-requests-container-wrapper" style={{paddingTop: 78}}>
                <div className="job-requests-options-wrapper">
                    <div className="job-requests-options">
                        <span className="options-buttons">
                            <button className="option-current">Imcoming Request</button>
                            <button className="option-archive">Something Else</button>
                        </span>
                    </div>
                </div>

                <div className="job-requests-container">
                    <ul className="job-requests">
                        {this.render_job_requests()}
                    </ul>
                </div>
            </div>
            </>
        );
    }
}

function map_state_to_props({user, referrals, employees, updates}){
    return {referrals: referrals.referrals, employees: employees.employees, updates: updates.updates, user};
}
 
export default connect(map_state_to_props, {accept_job_request, reject_job_request, discover_click_tecnician})(Job_requests);