import React, { Component } from 'react';
import TitleHeader from "./title_header";
import NavHeader from "./nav_header";
import "./header.css"

class Header extends Component {
    state = {  }
    render() { 
        return (
            <div className="rmt-header">
                {/* <TitleHeader /> */}
                <NavHeader />
            </div>
        );
    }
}
 
export default Header;