import {FETCH_EMPLOYEES, ACCEPT_REQUEST, EMPLOYEE_TYPE, FETCH_PENDING_EMPLOYEES, EMPLOYMENT_UNLINK_UPDATE, EMPLOYMENT_TERMINATE_UPDATE, FETCH_UNLINKED_EMPLOYEES, FETCH_TERMINATED_EMPLOYEES, INCREMENT_EMPLOYEE_TABLE_INDEX, DECREMENT_EMPLOYEE_TABLE_INDEX} from "../actions/types";

const INITIAL_STATE = {employees: [], view: "current", seaching: false, search_string: "", pending_employees: [], terminated_employees: [], unlinked_employees: [], employee_table_index: 0, employee_table_rows: 10};

export default (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    let MAX_INCREMENT;

    const new_state = {...state};
    let i;
    switch(type){
        case FETCH_EMPLOYEES:
            new_state.employees = payload;
        break;

        case FETCH_UNLINKED_EMPLOYEES:
            new_state.unlinked_employees = payload;
        break;

        case FETCH_TERMINATED_EMPLOYEES:
            new_state.terminated_employees = payload;
        break;

        case ACCEPT_REQUEST:
            new_state.employees = [payload.employee, ...new_state.employees];
            i = new_state.pending_employees.findIndex((pe) => {
                return pe._id === payload.employee._id;
            });

            if(i > 0){
                new_state.pending_employees.splice(i, 1);
            }

            new_state.pending_employees = [...new_state.pending_employees];
        break;

        case EMPLOYEE_TYPE:
            new_state.view = payload
        break;

        case EMPLOYMENT_UNLINK_UPDATE:
            i = new_state.employees.findIndex((employee) => {
                return employee._id === payload.employee;
            });

            if(i !== -1){
                const unlinked_employee = new_state.employees.splice(i, 1);

                new_state.unlinked_employees.push(unlinked_employee[0]);
                new_state.unlinked_employees = [unlinked_employee, ...new_state.unlinked_employees];
            }

        break;

        case EMPLOYMENT_TERMINATE_UPDATE:
            i = new_state.employees.findIndex((employee) => {
                return employee._id === payload.employee;
            });

            if(i !== -1){
                const terminated_employee = new_state.employees.splice(i, 1);

                new_state.terminated_employees = [terminated_employee[0], ...new_state.terminated_employees];
            }
        break;

        case FETCH_PENDING_EMPLOYEES:
            new_state.pending_employees = payload;
        break;

        case INCREMENT_EMPLOYEE_TABLE_INDEX:
            MAX_INCREMENT = (new_state.employees.length / (new_state.employee_table_rows))-1;

            new_state.employee_table_index = new_state.employee_table_index < MAX_INCREMENT ? new_state.employee_table_index + 1 : new_state.employee_table_index;
        break;

        case DECREMENT_EMPLOYEE_TABLE_INDEX:
            new_state.employee_table_index = new_state.employee_table_index === 0 ? 0 : new_state.employee_table_index - 1;
        break;

        default:
            return state;
    }

    return new_state;
}