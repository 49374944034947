import React, { Component } from 'react';
import config from '../../config';

class Job_history_item extends Component {
    state = {  }

    date_string(date){
        const options = {weekday: "long", month: "short", day: "2-digit"};
        return new Date(date).toLocaleString("default", options);
    }

    render_company_image(photo_url){

        if(photo_url){
          return(
            <img
              alt="..."
              className="img-circle rounded-circle img-thumbnail"
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                alt="..."
                className="img-circle rounded-circle img-thumbnail"
                src={require("assets/images/company_avatar.png")}
              />
            )
        }
    }

    render() {
        const {company, job, review} = this.props;
        return (
            <div className="history-job">
                <div className="job-company-info-container">
                    <div className="job-company-info">
                        {this.render_company_image(company?company.photo_url:"")}

                        <div className="job-info">
                            <h3 className="job-title">{job.title}</h3>
                            <small className="job-category">{job.category}</small>
                        </div>
                    </div>

                    <div className="job-rating-date">

                        {review?<div className="job-rating">
                            {window.innerWidth >= 1200?[null, null, null, null, null].map((n, index) => {
                                if(review.star_rating >= (index+1)){
                                    return <i key={index} className="fas fa-star" style={{color: "#f7bd4b"}} />
                                }else{
                                    if(review.star_rating - index > 0){
                                        return <i key={index} className="fas fa-star-half" style={{color: "#f7bd4b"}} />
                                    }else{
                                        return <i key={index} className="fas fa-star" style={{color: "#efefef"}} />
                                    }
                                }
                            }):<>{(review.star_rating || 0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b"}} /></>}
                        </div>:<p>No Review</p>}

                        <div className="job-date">
                            <small>{this.date_string(job.start_time)}</small>
                        </div>

                    </div>

                </div>

                <div className="job-images">
                    <img  className="before-image" alt="before" src={`${config.real_backend_url}/uploads/images/${job.image_urls.before[0]?job.image_urls.before[0].url:'null'}`} />

                    <img className="after-image" alt="after" src={`${config.real_backend_url}/uploads/images/${job.image_urls.after[0]?job.image_urls.after[0].url:'null'}`} />
                </div>

                <div className="customer-info-review-options">
                    <div className="job description">
                        <p>{job.description}</p>
                    </div>

                    <div className="review-options">
                        <button disabled className="mic-button"><i style={{color: "white"}} className="fas fa-microphone-alt" /></button>

                        <button disabled className="video-button"><i style={{color: "white"}} className="fas fa-video" /></button>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Job_history_item;