import React, { Component } from 'react';
import {connect} from "react-redux";
import Profile_skill from "./profile_skill";

import config from '../../config';

class Profile_info_column extends Component {
    state = {  }

    render_company_image(){
        const {photo_url} = this.props.companies[0];
        
        if(photo_url){
          return(
            <img
              alt="..."
              className="img-circle rounded-circle img-thumbnail"
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                alt="..."
                className="img-circle rounded-circle img-thumbnail"
                src={require("assets/images/company_avatar.png")}
              />
            )
        }
    }

    render() { 
        const {description, categories, cities, states, type} = this.props.user;
        const {companies} = this.props;

        return (
            <>
                <div className="profile-about-me">
                    <h1 style={{color: "#104464"}}>About Me</h1>

                    <p className="profile-description">{description || "No Description"}</p>
                </div>

                <h1 style={{margin: "25px 0"}}>Skills</h1>
                <div className="profile-skills">
                    {categories.map((skill) => {
                        return <Profile_skill skill={skill} />
                    })}
                </div>

                <h1 style={{margin: "25px 0"}}>Locations</h1>
                <div className="profile-location">
                    {cities.map((city) => {
                        return <Profile_skill skill={city} />
                    })}
                </div>
                <h1 style={{margin: "25px 0"}}>States</h1>
                <div className="profile-state">
                    {states.map((state) => {
                        return <Profile_skill skill={state} />
                    })}
                </div>
                <hr />

                <div className="profile-company-display">
                    {companies[0]?
                    <><div className="company-info-stats">
                        {this.render_company_image()}
                        <span className="stats-container">
                            <div className="stats">
                                102 <i className="fas fa-star" />
                                27 <i className="fas fa-wrench" />
                            </div>

                            <p>45 Technicians</p>
                        </span>

                        </div>

                        <h2>{companies[0].comp_name}</h2>

                        <p>{companies[0].description || "No Description"}</p>
                    </>:<p>No Companies</p>}
                </div>
            </>
        );
    }
}

function map_state_to_props({user, companies}){
    return {user, companies: companies.companies}
}

export default connect(map_state_to_props)(Profile_info_column);