import {FETCH_USER, FETCHING_USER, FETCH_USER_ERROR, SET_JOBS,SET_STARS, COMPLETE_JOB, REVIEW_JOB, UPLOAD_PROFILE_IMG, LOGOUT, SAVE_USER_EDIT} from "../actions/types";

const initial_state = {account_type: "tech", loading: false, jobs: 0, stars: 0}

export default (state = initial_state, action) => {
    let new_state = {...state};

    switch(action.type){
        case FETCH_USER:
            new_state = {...state, account_type: state.account_type, ...action.payload, loading: false};
        break;

        case FETCHING_USER:
            new_state.loading = true;
        break;

        case FETCH_USER_ERROR:
            new_state.loading = false;
        break;

        case SET_JOBS:
            new_state.jobs = action.payload;
        break

        case SET_STARS:
            new_state.stars = Math.floor(action.payload);
        break;

        case COMPLETE_JOB:
            new_state.jobs += 1;
        break;

        case REVIEW_JOB:
            new_state.stars += action.payload.star_rating
        break;

        case UPLOAD_PROFILE_IMG:
            new_state = {...new_state, photo_url: action.payload}
        break;

        case SAVE_USER_EDIT:
            new_state = {...new_state, ...action.payload}
        break;

        case LOGOUT:
            return initial_state;
        
        default:
            return state;
    }

    return new_state
}