import React, { Component } from 'react';
import {connect} from "react-redux";

import {
    Col,
    Row,
    Media,
    Progress,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    UncontrolledTooltip
} from "reactstrap";

import {request_job, discover_form_update, set_requested_technician, close_job_request_modal, discover_verify_email, discover_verify_name, discover_verify_phone} from "../../actions";

import config from '../../config';

import Categories from "../index/categories";
import Cities from "../index/cities";

class Job_request_modal extends Component {
    state = { show_category_suggestion: false, category_selected: true, current_category_index: -1, show_city_suggestion: false, city_selected: true, current_city_index: -1, stars: 0, open_modal: false, customer_name: "", customer_name_error: false, city_location_error: false, category_error: false, email_error: false, description_error: false, phone_error: false, selected_tech: false }

    onChange_category = ({target: {value}}) => {
      this.setState({category_selected: false, show_category_suggestion: true, category_error: false});
      this.props.discover_form_update("category", value);
    }

    onChange_city = ({target: {value}}) => {
      this.setState({city_selected: false, show_city_suggestion: true, city_location_error: false});
      this.props.discover_form_update("city", value);
    }

    onChange_email = ({target: {value}}) => {
      this.setState({email_error: false});
      this.props.discover_form_update("email", value);
    }
    
    onChange_name = ({target: {value}}) => {
      this.setState({customer_name_error: false});
      this.props.discover_form_update("name", value);
    }

    onChange_phone = ({target: {value}}) => {
      this.setState({phone_error: false})
      this.props.discover_form_update("phone", value);
    }

    onChange_description = ({target: {value}}) => {
      this.setState({description_error: false});
      this.props.discover_form_update("description", value);
    }

    onKeyDown_city = (e) => {
      const {current_city_index} = this.state;

      if(e.which === 13 && current_city_index > -1){//enter
          this.props.discover_form_update("city", this.match_cities[current_city_index]);
          this.setState({show_city_suggestion: !this.state.show_city_suggestion, city_selected: !this.state.city_selected});
      }else if(e.which === 38){//up
          if(current_city_index <= 0){
              this.setState({current_city_index: this.match_cities.length-1});
          }else{
              this.setState({current_city_index: this.state.current_city_index-1});
          }
      }else if(e.which === 40){//down
          if(current_city_index >= this.match_cities.length-1){
              this.setState({current_city_index: 0});
          }else{
              this.setState({current_city_index: this.state.current_city_index+1});
          }
      }
  }

    onKeyDown_category = (e) => {
      const {current_category_index} = this.state;

      if(e.which === 13 && current_category_index > -1){//enter
          this.props.discover_form_update("category", this.match_categories[current_category_index].label);
          this.setState({show_category_suggestion: !this.state.show_category_suggestion, category_selected: !this.state.category_selected});
      }else if(e.which === 38){//up
          if(current_category_index <= 0){
              this.setState({current_category_index: this.match_categories.length-1});
          }else{
              this.setState({current_category_index: this.state.current_category_index-1});
          }
      }else if(e.which === 40){//down
          if(current_category_index >= this.match_categories.length-1){
              this.setState({current_category_index: 0});
          }else{
              this.setState({current_category_index: this.state.current_category_index+1});
          }
      }
    }

    onPress_request_job = async () => {
      const {discover: {clicked_technician:technician}, request_job, discover_verify_email, discover_verify_name, discover_verify_phone} = this.props;
      const {state, name, city, category, email, phone, description} = this.props.discover;
      const {customer_name} = this.state;

      if(name !== "" && city !== "" && category !== "" && email !== "" && phone !== "" && description !== ""){
        if(await discover_verify_email(email) && await discover_verify_name(name) && await discover_verify_phone(phone)){
          await request_job(technician, {customer_name: name, city, category, email, phone, description});
          this.toggle_modal();
          this.props.socket.emit("customer_request");
        }
      }else{
        if(name === ""){
          this.setState({customer_name_error: true});
        }else if(city === ""){
          this.setState({city_location_error: true});
        }else if(category === ""){
          this.setState({category_error: true});
        }else if(email === ""){
          this.setState({email_error: true});
        }else if(phone === ""){
          this.setState({phone_error: true});
        }else if(description === ""){
          this.setState({description_error: true});
        }
      }
    }

    render_category_suggestions(){
      const {category} = this.props.discover;
      const {show_category_suggestion, category_selected, current_category_index} = this.state;

      if(((category.length > 0 || show_category_suggestion) && category_selected===false) || show_category_suggestion){
          this.match_categories = Categories.filter((c) => {
              return c.label.toUpperCase().includes(category.toUpperCase());
          })
          return this.match_categories.map((ca, i) => {
              return (
                  <p className={`suggestion ${i===current_category_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("category", ca.label); this.setState({category_selected: true, show_category_suggestion: false});}} key={ca+i}>{ca.label}</p>
              );
          })
      }
    }

    render_city_suggestions(){
      const {city} = this.props.discover;
      const {show_city_suggestion, city_selected, current_city_index} = this.state;

      if(((city.length > 0 || show_city_suggestion) && city_selected===false) || show_city_suggestion){
          this.match_cities = Cities.filter((c) => {
              return c.toUpperCase().includes(city.toUpperCase());
          });
          return this.match_cities.map((ci, i) => {
              return (
                  <p className={`suggestion ${i===current_city_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("city", ci); this.setState({city_selected: true, show_city_suggestion: false});}} key={ci+i}>{ci}</p>
              );
          })
      }
  }

    render_modal_technician_image(){
        const {photo_url} = this.props.discover.clicked_technician;
        if(photo_url){
          return(
            <img
                className="img-circle rounded-circle img-thumbnail"
                style={{width: 75, height: 75, objectFit: "cover", padding: 0, marginRight: 15}}
                alt="profile image"
                src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                className="img-circle rounded-circle img-thumbnail"
                style={{width: 75, height: 75, objectFit: "cover", padding: 0, marginRight: 15}}
                alt="profile image"
                src={require("assets/images/profile_avatar.png")}
              />
            )
        }
    }

    toggle_modal = () => {
      this.props.close_job_request_modal();
    }

    render() { 
        const {name, request_sent, state, city, email, phone, description, category, open_job_request_modal, clicked_technician:technician, phone_validation_error, email_validation_error, name_validation_error} = this.props.discover;

        const {customer_name, current_state_index, current_category_index, current_city_index, customer_name_error, city_location_error, category_error, show_category_suggestion, category_selected, show_city_suggestion, city_selected, phone_error, email_error, description_error} = this.state;

        if(technician){
        return (
            <Modal isOpen={open_job_request_modal} toggle={this.toggle_modal}>
                <ModalBody className="discover-request-modal">
                  <div style={{display:"flex", justifyContent: "space-between"}}>
                    <div>
                      <h1 style={{marginBottom: 20}}><span style={{borderBottom: "2px solid gray", paddingBottom: 10}}>Req</span>uest A New Job</h1>
                      <h2 style={{marginBottom: 40}}>Tell us a bit about the job</h2>
                    </div>
  
                    <div style={{display: "flex", alignItems: "center"}}>
                      {this.render_modal_technician_image()}
                      <div>
                          <small>Requested Technician</small>
                          <p style={{margin: 0}}>{`${technician.name.first} ${technician.name.last}`}</p>
                      </div>
                    </div>
                  </div>
  
                    <Row>
                        <Col lg="6">
                            <label>Name</label>
                            <Input placeholder="name"  type="text" value={name} onChange={this.onChange_name} />
                            {customer_name_error?<p className="text-danger">Please enter a name.</p>:null}
                            {name_validation_error?<p className="text-danger">{name_validation_error}</p>:null}
                        </Col>
  
                        <Col lg="6">
                          <div style={{position: "relative"}}>
                            <label>Location</label>
                            <span className="form-control" style={{display: "inline-block", position: "relative"}}>
                            <input autoComplete="off" onFocus={() => {this.setState({city_selected: false, show_city_suggestion: true})}} type="text" name="location" placeholder="Location" onKeyDown={this.onKeyDown_city} value={city} onChange={this.onChange_city} ref={(input) => {this.city_input = input}} style={{width: "100%", height: "100%", border: "none"}} />
  
                            {city_location_error?<p className="text-danger">Please select a Location.</p>:null}
                            
                            <i onClick={() => {
                                if(!show_city_suggestion){
                                    this.city_input.focus();
                                }
                                this.setState({show_city_suggestion: !this.state.show_city_suggestion , city_selected: !this.state.city_selected});
                            }} style={{verticalAlign: "sub", cursor: "pointer", position: "absolute", right: 10, top: 15}} className={`ni ni-bold-${show_city_suggestion  || city_selected===false?"up":"down"} text-muted`} />
                            </span>
  
                            <div className="suggestion-container-hide-scrollbar"><div className="suggestion-container" style={{border: show_city_suggestion?"1px solid #aaa":"none", marginTop: 5, borderRadius: "0.375rem", zIndex: 2}}>{this.render_city_suggestions()}</div></div>
                          </div>
                        </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <label>Email</label>
                        <Input placeholder="Email" type="text" value={email} onChange={this.onChange_email} />
                        
                        {email_error?<p className="text-danger">Please enter an email.</p>:null}
                        {email_validation_error?<p className="text-danger">{email_validation_error}</p>:null}
                      </Col>
  
                      <Col lg="6">
                        <div style={{position: "relative"}}>
                            <label>Category</label>
                            <span className="form-control" style={{display: "inline-block", position: "relative"}}>
                            <input autoComplete="off" onFocus={() => {this.setState({category_selected: false, show_category_suggestion: true})}} type="text" name="category" placeholder="Category" onKeyDown={this.onKeyDown_category} value={category} onChange={this.onChange_category} ref={(input) => {this.category_input = input}} style={{width: "100%", height: "100%", border: "none"}} />
  
                            {category_error?<p className="text-danger">Please select a category.</p>:null}
                            
                            <i onClick={() => {
                                if(!show_category_suggestion){
                                    this.category_input.focus();
                                }
                                this.setState({show_category_suggestion: !this.state.show_category_suggestion , category_selected: !this.state.category_selected});
                            }} style={{verticalAlign: "sub", cursor: "pointer", position: "absolute", right: 10, top: 15}} className={`ni ni-bold-${show_category_suggestion  || category_selected===false?"up":"down"} text-muted`} />
                            </span>
  
                            <div className="suggestion-container-hide-scrollbar"><div className="suggestion-container" style={{border: show_category_suggestion?"1px solid #aaa":"none", marginTop: 5, borderRadius: "0.375rem", zIndex: 1}}>{this.render_category_suggestions()}</div></div>
                          </div>
                        </Col>
                    </Row>
  
                    <Row>
                      <Col lg="6">
                        <label>Contact Number</label>
                        <Input placeholder="Phone" type="text" value={phone} onChange={this.onChange_phone} />
                        
                        {phone_error?<p className="text-danger">Please enter a phone Number.</p>:null}
                        {phone_validation_error?<p className="text-danger">{phone_validation_error}</p>:null}
                      </Col>
  
                      <Col lg="6">
                        <label>Description</label>
                        <Input style={{marginBottom: 30, transition: "none"}} placeholder="Description" type="textarea" value={description} onChange={this.onChange_description} />
                        
                        {description_error?<p className="text-danger">Please enter a description.</p>:null}
                        </Col>
                    </Row>
  
                    <button className="close-button" onClick={this.toggle_modal}>X</button>
                    
                    <div className="request-button-container">
                      <button onClick={this.onPress_request_job} className="request-button-slant"></button>
                      <button onClick={this.onPress_request_job} className="request-button">Request</button>
                    </div>
                    <Modal isOpen={request_sent === false}>
                        <ModalBody>
                            Sending Request........
                        </ModalBody>
                    </Modal>
                </ModalBody>
            </Modal>
        );
        }
    }
}

function map_state_to_props({discover, app}){
    return ({discover, socket: app.socket});
}
 
export default connect(map_state_to_props, {request_job, discover_form_update, set_requested_technician, close_job_request_modal, discover_verify_email, discover_verify_phone, discover_verify_name})(Job_request_modal);