import {PASSWORD_FORM_UPDATE, CHANGE_PASSWORD} from "../actions/types";

const INITIAL_STATE =  {current_password: "", new_password: "", confirm_password: ""};

export default (state = INITIAL_STATE, {type, payload}) => {
    const new_state = {...state};

    switch(type){

        case CHANGE_PASSWORD:
            return INITIAL_STATE;
        
        case PASSWORD_FORM_UPDATE:
            // alert(payload.value);
            new_state[payload.key] = payload.value;
        break;

        default:
            return state;

    }

    return new_state;
}