// assumption: A valid phone number should be ten digits

const regex = /^[0-9]{9,10}$/

export default (phone = "") => {
    if(regex.test(phone)){
        return true;
    }else{
        return false;
    }
}