import Axios from "axios"
import config from "../config"
import {JOB_TYPE, FETCH_JOBS, CREATE_JOB, CREATE_JOB_ERROR, JOB_CREATION_FORM_UPDATE, SHOW_JOB_CREATION, CLOSE_JOB_CREATION, AFTER_PHOTO_UPLOAD, BEFORE_PHOTO_UPLOAD, START_JOB, STOP_JOB, INCREMENT_TIME, ARCHIVE_JOB, COMPLETE_JOB, RESTORE_JOB, DELETE_JOB, ALLOW_JOB_START, DELETE_JOB_UPDATES, SEARCH_FORM_UPDATE, START_SEARCH, CLEAR_SEARCH, STOP_SEARCH, CREATING_JOB, FETCHING_JOBS, FETCH_JOBS_ERROR, STOP_CREATING_JOB, SHOW_JOB_CREATION_MODAL, DISMISS_JOB_CREATION_MODAL} from "./types"


// const set_job = async (res, call) => {
//     const count = (await res.data.jobs.map((job) => {
//         if(job.status === "completed"){
//             return 1;
//         }else{
//             return 0;
//         }
//     })).reduce((prev, curr) => prev + curr, [0]);

//     call({type: SET_JOBS, payload: count});
// }

export const fetch_jobs = () => async (dispatch) => {
    dispatch({type: FETCHING_JOBS});
    const res = await Axios.get(`${config.real_backend_url}/api/user/jobs`, {withCredentials: true}).catch((err) => {
        alert(err);
        dispatch({type: FETCH_JOBS_ERROR});
        return false;
    });

    //console.log(res.data.jobs);
    //set_job(res, dispatch);

    dispatch({type: FETCH_JOBS, payload: res.data.jobs})
    return true;
}

export const fetch_company_jobs = () => async (dispatch) => {
    dispatch({type: FETCHING_JOBS});
    const res = await Axios.get(`${config.real_backend_url}/api/user/company/jobs`, {withCredentials: true}).catch((err) => {
        alert(err);
        dispatch({type: FETCH_JOBS_ERROR});
        return false;
    });

    dispatch({type: FETCH_JOBS, payload: res.data.jobs});
    // alert(JSON.stringify(res.data.jobs));
    return true;
}

export const create_job = ({name, company, category, description, location}) => async (dispatch) =>{

    dispatch({type: CREATING_JOB})
    //alert(category)
    if(name === "" || description === "" || category === "select" || category === "" || company === "" || company === "select"){
        if(category === "select" || category === ""){
            alert("Please select a category!");
            dispatch({type: STOP_CREATING_JOB});
            return false;
        }
        if(company === "select" || company === ""){
            alert("Please select a company!");
            dispatch({type: STOP_CREATING_JOB});
            return false;
        }
        dispatch({type: STOP_CREATING_JOB});
        alert("all fields must be filled");
        return false;
    }
    const res = await Axios.post(`${config.real_backend_url}/api/user/job/create`, { title: name, description, category, location, start_time: null, company }, {withCredentials: true}).catch((err) => {console.log(err); alert(err); dispatch({type: CREATE_JOB_ERROR, payload: err}); return false});
    
    // console.log({name, company, category, description, location})
    dispatch({type: CREATE_JOB, payload: res.data.status});
    return true;
}

export const increment_time = () => {
    return ({type: INCREMENT_TIME});
}

export const job_creation_form_update = (key, value) => {
    return ({ type: JOB_CREATION_FORM_UPDATE, payload: {key, value} });
}

export const show_job_creation_modal = () => {
    return ({type: SHOW_JOB_CREATION_MODAL});
}

export const dismiss_job_creation_modal = () => {
    return ({type: DISMISS_JOB_CREATION_MODAL});
}

export const show_job_creation = () => {
    return ({type: SHOW_JOB_CREATION});
}

export const close_job_creation = () => {
    return ({type: CLOSE_JOB_CREATION});
}

export const switch_job_view = (view) => {
    // alert("job started");
    return({type: JOB_TYPE, payload: view});
}

export const start_job = (job_id, started) => async (dispatch) => {
    if(!started){
        const res = await Axios.get(`${config.real_backend_url}/api/job/start/${job_id}`, {withCredentials: true}).catch((err) => {
            alert(JSON.stringify(err));
        });

        if(res.data.started){
            dispatch({type: START_JOB, payload: job_id});
            return true;
        }else{
            return false;
        }
    }else{
        dispatch({type: START_JOB, payload: job_id});
        return true;
    }


    // let timer = setInterval(() => {
    //     dispatch({type: INCREMENT_TIME});
    // }, 1000);

    // return;
}

export const stop_job = (_id) => {
    return({type: STOP_JOB, payload: _id});
}

export const archive_job = (job_id) => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/job/archive/${job_id}`, {withCredentials: true}).catch((err) => {alert(JSON.stringify(err))});

    if(res.data.archived){
        console.log("job archived");
        dispatch({type: ARCHIVE_JOB, payload: job_id});
        return true;
    }else{
        return false;
    }
}

export const complete_job = (job_id) => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/job/complete/${job_id}`, {withCredentials: true}).catch((err) => {alert(JSON.stringify(err))});

    if(res.data.completed){
        dispatch({type: COMPLETE_JOB, payload: job_id});
        return true;
    }else{
        return false;
    }
}

export const restore_job = (job_id) => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/job/restore/${job_id}`, {withCredentials: true}).catch((err) => {alert(JSON.stringify(err))});

    if(res.data.restored){
        dispatch({type: RESTORE_JOB, payload: job_id});
        return true;
    }else{
        return false;
    }

}

export const delete_job = (job_id) => async (dispatch) => {
    let res = await Axios.get(`${config.real_backend_url}/api/update/job/dismiss/${job_id}`, {withCredentials: true}).catch((err) => {alert(JSON.stringify(err))});

    if(res.data.dismissed){
        await dispatch({type: DELETE_JOB_UPDATES, payload: job_id});

        res = await Axios.get(`${config.real_backend_url}/api/job/delete/${job_id}`, {withCredentials: true}).catch((err) => {alert(JSON.stringify(err))});

        if(res.data.deleted){
            dispatch({type: DELETE_JOB, payload: job_id});
        }
    }
    return;
}

export const create_form_data = (photo_form_name, photo_uri, body) => {
    const data = new FormData();

    console.log(photo_uri.split('\\').pop().split('/').pop(),
    "image/"+photo_uri.split(".").pop(),
    photo_uri);

    let photo_form = document.getElementById("photo_form");

    console.log(photo_form);
    let photo_data = new FormData(photo_form)

    // data.append(photo_form_name, JSON.stringify({
    //     name: photo_uri.split('\\').pop().split('/').pop(),
    //     type: "image/"+photo_uri.split(".").pop(),
    //     uri: photo_uri
    // }));

    // console.log({...photo_data})

    Object.keys(body).forEach((key) => {
        photo_data.append(key, body[key]);
    });

    // for(var item of data.entries()) {
    //     // console.log(JSON.parse(item[1])); 
    //     body._parts.push([item[0], {
    //         name: photo_uri.split('\\').pop().split('/').pop(),
    //         type: "image/"+photo_uri.split(".").pop(),
    //         uri: photo_uri
    //     }]);
    //     // body[item[0]] = item[1];
    // }

    // data.forEach((value, key) => {
    //     console.log("value: ", value, "key: ", key);
    // })

    photo_data.forEach((value, key) => {
        console.log("value: ", value, "key: ", key);
    })

    return photo_data;
}

export const allow_job_start = () => {
    return({type: ALLOW_JOB_START});
}

export const start_search = () => {
    return ({type: START_SEARCH});
}

export const clear_search = () => {
    return ({type: CLEAR_SEARCH});
}

export const stop_search = () => {
    return ({type: STOP_SEARCH});
}

export const search_form_update = (value = "") => async (dispatch) => {
    if(value !== ""){
        await dispatch({type: START_SEARCH});
    }

    dispatch ({type: SEARCH_FORM_UPDATE, payload: value});
    return;
}

export const upload_after = (data, job_id, {uri, name}) => async (dispatch) => {
    const res = await Axios.post(`${config.real_backend_url}/api/job/${job_id}/upload_after_image`, data, {withCredentials: true}).catch((err) => {console.log(err); return false});

    //console.log(data);
    if(res.data.file){
        //console.log(res.data.file)
        dispatch({type: AFTER_PHOTO_UPLOAD, payload: {file: res.data.file, job_id}});
    }
}

export const upload_before = (data, job_id, {uri, name}) => async (dispatch) => {
    const res = await Axios.post(`${config.real_backend_url}/api/job/${job_id}/upload_before_image`, data, {withCredentials: true}).catch((err) => {console.log(err); return false});

    //console.log("data", data);
    if(res.data.file){
        //console.log(res.data.file)
        dispatch({type: BEFORE_PHOTO_UPLOAD, payload: {file: res.data.file, job_id}});
    }
}