import React, { Component, Fragment } from 'react';
import {
    Col,
    Row,
    Button,
    Badge,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Progress,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    UncontrolledTooltip
} from "reactstrap";

import {connect} from "react-redux";

import config from '../../config';

import {request_job} from "../../actions";

class Discover_search_item extends Component {
    state = { stars: 0, open_modal: false, customer_name: "", customer_name_error: false }

    componentDidMount(){
        const {reviews} = this.props.technician;
        console.log(reviews);
        const a = reviews.map((review)=>review.star_rating).reduce((prev, curr) => {return prev+curr}, 0);
        console.log(a);
        this.setState({stars: a});
    }

    toggle_model = () => {
      this.setState({open_modal: !this.state.open_modal});
    }

    onPress_request = () => {
      this.toggle_model();
    }

    onPress_request_job = async () => {
      const {technician, request_job} = this.props;
      const {state, city, category} = this.props.discover;
      const {customer_name} = this.state;

      if(customer_name !== "" && city !== "" && category !== "" && state !==""){
        await request_job(technician, {customer_name, city, category, state});
        this.toggle_model();
        this.props.socket.emit("customer_request");
      }else{
        if(customer_name === ""){
          this.setState({customer_name_error: true});
        }
      }
    }

    render_request_modal(){
      const {request_sent, state, city, category} = this.props.discover;
      const {open_modal, customer_name, current_state_index, current_category_index, current_city_index, customer_name_error} = this.state;
      return(
          <div>
          <Modal isOpen={open_modal} toggle={this.toggle_model}>
              <ModalHeader toggle={this.toggle_model}>Request Job</ModalHeader>
              <ModalBody>
                  <Row>
                      <Col lg="12">
                          <label>Name</label>
                          <Input placeholder="name"  type="text" value={customer_name} onChange={({target: {value}}) => {this.setState({customer_name: value, customer_name_error: false})}} />
                          {customer_name_error?<p className="text-danger">Please enter a name.</p>:null}
                      </Col>
                  </Row>
                  <Row>
                      <Col lg="6">
                          <label>City</label>
                          <Input placeholder="Location" type="text" defaultValue={city} />
                      </Col>
                      <Col lg="6">
                            <label>State</label>
                            <Input placeholder="State" type="text" defaultValue={state} />
                      </Col>

                      <Col lg="6">
                          <label>Category</label>
                          <Input placeholder="Category" type="text" defaultValue={category} />
                      </Col>
                  </Row>
                  <Modal isOpen={request_sent === false}>
                      <ModalBody>
                          Sending Request........
                      </ModalBody>
                  </Modal>
              </ModalBody>
              <ModalFooter>
                  <Button onClick={this.toggle_model} color="danger">CANCEL</Button>

                  <Button onClick={this.onPress_request_job} color="warning" style={{backgroundColor: "#e7b136", border: "none"}}>REQUEST</Button>
              </ModalFooter>
          </Modal>
          </div>
      )
  }

    render_company_image({photo_url, _id, comp_name}){
        if(photo_url){
          return(
            <Fragment key={_id}>
                <a
                    className="avatar avatar-sm"
                    id={"TT"+_id}
                    onClick={e => e.preventDefault()}
                >
                    <img
                        className="rounded-circle"
                        alt="company image"
                        src={`${config.real_backend_url}/uploads/images/${photo_url}`}
                    />
                </a>
                <UncontrolledTooltip
                    delay={0}
                    target={"TT"+_id}
                >
                    {comp_name}
                </UncontrolledTooltip>
            </Fragment>
          )
        }else{
            return(
            <Fragment key={_id}>
              <a
                className="avatar avatar-sm"
                id={"TT"+_id}
                onClick={e => e.preventDefault()}
              >
                <img
                    className="rounded-circle"
                    alt="company image"
                    src={require("assets/images/company_avatar.png")}
                />
              </a>
              <UncontrolledTooltip
                delay={0}
                target={"TT"+_id}
              >
                {comp_name}
              </UncontrolledTooltip>
            </Fragment>
            )
        }
    }

    render_profile_image(){
        const {photo_url} = this.props.technician;
        if(photo_url){
          return(
            <img
                className="img-circle rounded-circle img-thumbnail"
                style={{height: "inherit", objectFit: "cover", padding: 0}}
                alt="profile image"
                src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                className="img-circle rounded-circle img-thumbnail"
                style={{height: "inherit", objectFit: "cover", padding: 0}}
                alt="profile image"
                src={require("assets/images/profile_avatar.png")}
              />
            )
        }
    }

    render() { 
        const {name, email, phone, companies, completed_jobs} = this.props.technician;
        const {stars} = this.state;
        return (
            <>
            {this.render_request_modal()}
            <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            onClick={e => e.preventDefault()}
                          >
                            {this.render_profile_image()}
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              {`${name.first} ${name.last}`}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{email}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          {phone}
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          {companies.map((comp) => {
                              return this.render_company_image(comp);
                          })}
                        </div>
                      </td>
                      <td>
                        <div className="align-items-center">
                          <div>
                              <i className="fas fa-star" style={{color: "#e7b136"}} /> {stars.toFixed(1)} Stars / {completed_jobs} Jobs <i className="fas fa-wrench" style={{color: "#e7b136"}} />
                          </div>

                          <div className="d-flex align-items-center">
                            <span className="mr-2">{((stars/completed_jobs) || 0).toFixed(1)}</span>
                            <div>
                                <Progress
                                max="5"
                                value={(stars/completed_jobs) || 0.0}
                                barClassName="bg-yellow"
                                />
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <button onClick={this.onPress_request} className="btn btn-warning" style={{backgroundColor: "#e7b136", border: "none"}}>Request Job</button>
                      </td>

                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    </>
        );
    }
}

function map_state_to_props({discover, app}){
  return ({discover, socket: app.socket});
}
 
export default connect(map_state_to_props, {request_job})(Discover_search_item);