import React from "react";
import ReactDOM from "react-dom";
import {createStore, applyMiddleware} from "redux"
import {Provider} from "react-redux";
import Thunk from "redux-thunk";
import Routing from "./routing_index";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'assets/fonts/Avenir-Next/AvenirNext-Bold.ttf';
import 'assets/fonts/Avenir-Next/AvenirNext-Medium.ttf';
import 'assets/fonts/Avenir-Next/AvenirNext-Medium.css';
import 'assets/fonts/Avenir-Next/AvenirNext-Bold.css';
import 'assets/fonts/Avenir-Next/AvenirNext-DemiBold.ttf';
import 'assets/fonts/Avenir-Next/AvenirNext-DemiBold.css';

import reducers from "./reducers";

const store = createStore(reducers, {}, applyMiddleware(Thunk));

ReactDOM.render(
  <Provider store={store}>
    <Routing />
  </Provider>,
  document.getElementById("root")
);
