import {FETCH_UPDATES, FETCH_UPDATES_ERROR, DISMISS_UPDATE, VIEW_UPDATE, DELETE_JOB_UPDATES, CREATED_UPDATE, LOGOUT, FETCHING_UPDATES, ADD_UPDATE, ADD_COMPANY_AND_UPDATE, REMOVE_LATEST_UPDATE} from "../actions/types";

const initial_state = { updates: [], loading: false, latest: null }

export default (state = initial_state, action) => {

    const new_state = {...state};

    switch(action.type){

        case ADD_UPDATE:
            new_state.updates = [action.payload, ...new_state.updates];
            new_state.latest = action.payload;
        break;

        case REMOVE_LATEST_UPDATE:
            new_state.latest = null;
        break;

        case ADD_COMPANY_AND_UPDATE:
            new_state.updates = [action.payload.req_update, ...new_state.updates];
        break;

        case FETCH_UPDATES:
            new_state.updates = action.payload || [];
            new_state.loading = false;
            // console.log(new_state.updates);
        break;

        case FETCHING_UPDATES:
            new_state.loading = true;
            // console.log(new_state.updates);
        break;

        case CREATED_UPDATE:
            new_state.updates = [action.payload, ...state.updates];
        break;

        case DISMISS_UPDATE:
            
            let i = new_state.updates.findIndex((update) => {
                return update._id === action.payload
            });

            new_state.updates.splice(i, 1);
            new_state.updates = [...new_state.updates];
        break;

        case DELETE_JOB_UPDATES:
            let updates_to_remove = [];
            new_state.updates.forEach((update, i) => {
                if(update.job === action.payload){
                    updates_to_remove.push(i);
                }
            });

            for(let i = updates_to_remove.length -1; i>=0; i--){
                new_state.updates.splice(updates_to_remove[i], 1);
            }

            new_state.updates = [...new_state.updates];
        break;

        case LOGOUT:
            return initial_state;
        // break;

        case VIEW_UPDATE:
            return state;
        // break;

        case FETCH_UPDATES_ERROR:
            new_state.loading = false;
        break;

        default:
            return state;
    }

    return new_state;

}