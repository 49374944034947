import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import History_header from "./header";
import Job_history_item from "./job_history_item";
import Job_request_modal from "../index/job_request_modal";
import RMT_header from "../RMT_header";

import {connect} from "react-redux";

import "./technician_history.css"

class Technician_history extends Component {
    state = {  }

    render_request_modal(){
        if(this.props.open_req_modal){
            return <Job_request_modal />
        }
    }

    render() {
        const {technician} = this.props;
        if(technician){
            const {jobs, reviews, completed_jobs, from_discover} = this.props;
            return (
                <>
                <RMT_header />
                <div className="job-history-container" style={{paddingTop: 78}}>
                    {this.render_request_modal()}
                    <History_header technician={technician} completed_jobs={completed_jobs} jobs={jobs} reviews={reviews} from_discover={from_discover} company={from_discover?technician.companies[0]:(this.props.user.type === "comp"?this.props.user:{})} />

                    <div className="job-history-container jobs-history">
                        <ul className="job-history-items">
                            {jobs.map((job, i) => {
                                const review = reviews.find((rev) => {
                                    return rev.job === job._id;
                                });

                                return (
                                    <li key={job._id}>
                                        <Job_history_item job={job} review={review} company={from_discover?technician.companies[0]:(this.props.user.type === "comp"?this.props.user:{})} />
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                </>
            );
        }else{
            this.props.history.push("/");
            return null;
        }
    }
}

function map_state_to_props({history, user, discover}){
    return {technician: history.technician, jobs: history.jobs, reviews: history.reviews, completed_jobs: history.completed_jobs, from_discover: history.from_discover, user, open_req_modal: discover.open_job_request_modal};
}
 
export default withRouter(connect(map_state_to_props)(Technician_history));