import {PROCESS_INPUT, ADD_CITY_SIGN_UP, ADD_CATEGORY_SIGN_UP, REMOVE_CITY_SIGN_UP, REMOVE_CATEGORY_SIGN_UP, ADD_STATE_SIGN_UP, REMOVE_STATE_SIGN_UP, LOGGED_IN, LOGOUT, FORM_VIEW, LOGGING_IN, LOGIN_ERROR, SIGNUP_ERROR, RETRY, CHOOSE_ACCOUNT_TYPE, INVALID_FORMAT} from "../actions/types";
const initial_state = {name: {first: "", last: ""}, email: "", signup_password: "", signup_confirm_password: "", agree_to_policy: false, login_password: ""/* "qwerty" */, phone: "", email_phone: ""/* "kylebellle@gmail.com" */, birth: "",  logged_in: null, login_or_signup: null, logging_in: false, login_error_msg: "", signup_error_msg: "", address: "", website: "", type: "", comp_name: "", description: "", cities: [], categories: [], states: [], opening: "", closing: "", firstname_validation_err: "", lastname_validation_err: "", email_validation_err: "", email_phone_validation_err: "", phone_validation_err: "", pass_validation_err: "", confirm_pass_validation_err: "", birth_validation_err: "", website_validation_err: ""};


export default (state = initial_state, {type, payload}) => {
    let new_state = {...state};

    switch(type){
        case PROCESS_INPUT:
            const {key, value} = payload;
            if(key === "firstname"){
                new_state["name"]["first"] = value;
                new_state.firstname_validation_err = "";
            }else if(key === "lastname"){
                new_state["name"]["last"] = value;
                new_state.lastname_validation_err = "";
            }else if(key === "email_phone"){
                new_state[key] = value;
                new_state.email_phone_validation_err = "";
            }else if(key === "email"){
                new_state[key] = value;
                new_state.email_validation_err = "";
            }else if(key === "phone"){
                new_state[key] = value;
                new_state.phone_validation_err = "";
            }else if(key === "signup_password"){
                new_state[key] = value;
                new_state.pass_validation_err = "";
            }else if(key === "signup_confirm_password"){
                new_state[key] = value;
                new_state.confirm_pass_validation_err = "";
            }else if(key === "birth"){
                new_state[key] = value;
                new_state.birth_validation_err = "";
            }else if(key === "website"){
                new_state[key] = value;
                new_state.website_validation_err = "";
            }else{
                new_state[key] = value;
            }
        break;

        case ADD_CITY_SIGN_UP:
            new_state.cities.push(payload);
        break;
        case ADD_STATE_SIGN_UP:
            new_state.states.push(payload);
        break;
        case ADD_CATEGORY_SIGN_UP:
            new_state.categories.push(payload);
        break;

        case REMOVE_CITY_SIGN_UP:
            new_state.cities.splice(payload.index, 1);
        break;
        case REMOVE_STATE_SIGN_UP:
            new_state.states.splice(payload.index, 1);
        break;

        case REMOVE_CATEGORY_SIGN_UP:
            new_state.categories.splice(payload.index, 1);
        break;

        case LOGGED_IN:
            new_state.logged_in = payload;
        break;

        case LOGGING_IN:
            new_state.logging_in = payload;
        break;

        case CHOOSE_ACCOUNT_TYPE:
            //alert(payload);
            new_state.type = payload;
        break;

        case LOGIN_ERROR:
            new_state.login_error_msg = payload;
            new_state.logging_in = false;
        break;

        case SIGNUP_ERROR:
            new_state.signup_error_msg = payload;
        break;

        case RETRY:
            new_state.signup_error_msg = "";
            new_state.login_error_msg = "";
            new_state.logging_in = false;
        break;

        case LOGOUT:
            new_state = {...initial_state, login_or_signup: state.login_or_signup, logged_in: false};
        break;

        case FORM_VIEW: 
            new_state.login_or_signup = payload.choice;
            // new_state.email_phone = new_state.email || new_state.phone;
        break;

        case INVALID_FORMAT:
            if(payload === "email"){
                new_state.email_validation_err = "Please use a valid email address";
            }else if(payload === "email_phone"){
                new_state.email_phone_validation_err = "Please use a valid email or phone number";
            }else if(payload === "password"){
                new_state.pass_validation_err = "A valid password contains at least 1 lowercase character, 1 uppercase character, 1 number and must be at least 5 characters long.";
            }else if(payload === "confirm_password"){
                new_state.confirm_pass_validation_err = "Passwords don't match";
            }else if(payload === "phone"){
                new_state.phone_validation_err = "A valid phone number is 9 - 10 digits only.";
            }else if(payload === "firstname"){
                new_state.firstname_validation_err = "A valid firstname contains letters only.";
            }else if(payload === "lastname"){
                new_state.lastname_validation_err = "A valid lastname contains letters only.";
            }else if(payload === "birth"){
                new_state.birth_validation_err = "Must be 18 or over to use the app";
            }else if(payload === "website"){
                new_state.website_validation_err = "Website url not valid.";
            }
            
            new_state.logging_in = false;
        break;

        default:
            return new_state;
    }

    return new_state;
}