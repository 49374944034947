import {DISCOVER_SEARCH, DISCOVER_FORM_UPDATE, DISCOVER_SEARCH_SUGGESTIONS, DISCOVER_CLICKED_TECHNICIAN, CLOSE_DISCOVER_TECH_MODAL, DISCOVER_CLICKED_COMPANY, CLOSE_DISCOVER_COMP_MODAL, GET_TOP_TECHS, DISMISS_DISCOVER, INCREMENT_DISCOVER_TABLE_INDEX, DECREMENT_DISCOVER_TABLE_INDEX, JOB_REQUEST_FULLY_COMPLETE, SET_REQUESTED_TECHNICIAN, OPEN_JOB_REQUEST_MODAL, CLOSE_JOB_REQUEST_MODAL, DISCOVER_INVALID_NAME, DISCOVER_INVALID_EMAIL, DISCOVER_INVALID_PHONE, DISCOVER_RESULTS_CLICKED_TECHNICIAN, DISCOVER_RESULTS_CLOSE_TECHNICIAN, DISCOVER_RESULTS_CHANGE_VIEW} from "./types";
import Axios from "axios";
import config from "../config";
import valid_name from "../utils/valid_name";
import valid_email from "../utils/valid-email";
import valid_phone from "../utils/valid_phone_number";


export const discover_search = (city, category, state) => async (dispatch) => {
    console.log(state,category,city);
    const res = await Axios.post(`${config.real_backend_url}/api/discover`, {city, category, state}).catch((err) => {
        console.log(err);
    });
   
    if(res.data.searched){
        dispatch({type: DISCOVER_SEARCH, payload: {technicians: res.data.technicians}});

     

        return true;
    }else{
        return false;
    }
}

export const dismiss_discover = () => {
    return {type: DISMISS_DISCOVER};
}

export const discover_form_update = (key, value, suggest) => async (dispatch) => {

    dispatch({type: DISCOVER_FORM_UPDATE, payload: {key, value}});
    
    // if(suggest){
    //     if(key === "city"){

    //         const res = await Axios.post(`${config.real_backend_url}/api/discover/suggest`, {city: value});

    //         if(res.data.suggested){
    //             dispatch({type: DISCOVER_SEARCH_SUGGESTIONS, payload: {suggestions: res.data.city_suggest, key: "city_suggestions"}});

    //             // console.log(res.data);

    //             return true;
    //         }

    //     }else if(key === "category"){

    //         const res = await Axios.post(`${config.real_backend_url}/api/discover/suggest`, {category: value});

    //         if(res.data.suggested){
    //             dispatch({type: DISCOVER_SEARCH_SUGGESTIONS, payload: {suggestions: res.data.category_suggest, key: "category_suggestions"}});

    //             // console.log(res.data);

    //             return true;
    //         }

    //         return false;
    //     }
    // }

    return false;
}

export const discover_verify_name = (name) => (dispatch) => {
    if(valid_name(name)){
        return true;
    }else{
        dispatch({type: DISCOVER_INVALID_NAME});
        return false;
    }
}

export const discover_verify_email = (email) => (dispatch) => {
    if(valid_email(email)){
        return true;
    }else{
        dispatch({type: DISCOVER_INVALID_EMAIL});
        return false;
    }
}

export const discover_verify_phone = (phone) => (dispatch) => {
    if(valid_phone(phone)){
        return true;
    }else{
        dispatch({type: DISCOVER_INVALID_PHONE});
        return false;
    }
}

export const discover_result_click_technician = (technician) => {
    return {type: DISCOVER_RESULTS_CLICKED_TECHNICIAN, payload: technician};
}

export const discover_results_close_technician = () => {
    return {type: DISCOVER_RESULTS_CLOSE_TECHNICIAN};
}

export const discover_results_change_view = (view) => {
    return {type: DISCOVER_RESULTS_CHANGE_VIEW, payload: view};
}

export const discover_click_tecnician = (technician) => {
    return {type: DISCOVER_CLICKED_TECHNICIAN, payload: technician};
}

export const discover_click_company = (company) => {
    return {type: DISCOVER_CLICKED_COMPANY, payload: company};
}

export const close_discover_tech_modal = () => {
    return {type: CLOSE_DISCOVER_TECH_MODAL};
}

export const close_discover_comp_modal = () => {
    return {type: CLOSE_DISCOVER_COMP_MODAL};
}

export const get_top_techs = () => async (dispatch) => {

    const res = await Axios.get(`${config.real_backend_url}/api/top_technicians`);

    dispatch ({type: GET_TOP_TECHS, payload: res.data.top_techs});
}

export const increment_discover_table_index = () => {
    return {type: INCREMENT_DISCOVER_TABLE_INDEX};
}

export const decrement_discover_table_index = () => {
    return {type: DECREMENT_DISCOVER_TABLE_INDEX};
}

export const fully_complete_job_request = () => {
    return {type: JOB_REQUEST_FULLY_COMPLETE}
}

export const set_requested_technician = (technician_id) => {
    return {type: SET_REQUESTED_TECHNICIAN, payload: technician_id};
}

export const open_job_request_modal = () => {
    return {type: OPEN_JOB_REQUEST_MODAL};
}

export const close_job_request_modal = () => {
    return {type : CLOSE_JOB_REQUEST_MODAL};
}