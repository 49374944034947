import {SET_HISTORY_TECHNICIAN, SET_HISTOTY_JOBS, SET_HISTORY_COMPLETED_JOBS, SET_HISTORY_REVIEWS, SET_HISTORY_FROM_DISCOVER} from "../actions/types";

const INITIAL_STATE = {technician: null, jobs: [], reviews: [], completed_jobs: null, from_discover: false};

export default (state = INITIAL_STATE, {type, payload}) => {
    let new_state = {...state}
    switch(type){
        case SET_HISTORY_TECHNICIAN:
            new_state.technician = payload;
        break;

        case SET_HISTOTY_JOBS:
            new_state.jobs = payload;
            new_state.completed_jobs = payload.length;
        break;

        case SET_HISTORY_REVIEWS:
            new_state.reviews = payload;
        break;

        case SET_HISTORY_COMPLETED_JOBS:
            new_state.completed_jobs = payload;
        break;

        case SET_HISTORY_FROM_DISCOVER:
            new_state.from_discover = payload;
        break;

        default:
            return state;
    }

    return new_state;
}