import React from "react";
import { NavLink as NavLinkRRD, Link, withRouter } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import Request from "../Requests";

import {connect} from "react-redux";

import {logout} from "../../actions";

import "./custom_sidebar.css";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

var ps;

class Sidebar extends React.Component {
  state = {
    collapseOpen: false
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.onPress_logout = this.onPress_logout.bind(this);
  }

  async onPress_logout(){
    if((await this.props.logout())){
        this.props.history.push("/");
    }
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = routes => {
    const {role} = this.props.user;
    return routes.map((prop, key) => {
      if(prop.layout !== "/auth" && !(role && role.indexOf("admin") !== -1)){
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={this.closeCollapse}
              activeClassName="active active-rmt-nav-link"
              className="rmt-nav-link"
            >
              <i className={prop.icon} />
              {window.innerWidth > 600?prop.name:""}
            </NavLink>
          </NavItem>
        );
        }
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;

    const {role, type} = this.props.user;

    return (
      <div className="custom-sidebar-wrapper">
        <div className="custom-sidebar">
            {/* Navigation */}
            <Nav navbar>
              {(role && role.indexOf("admin") !== -1) || type === "comp"?<NavItem>
                <NavLink
                  to="/admin/maps"
                  tag={NavLinkRRD}
                  onClick={this.closeCollapse}
                  activeClassName="active active-rmt-nav-link"
                  className="rmt-nav-link"
                >
                  <i className="ni ni-pin-3" />
                  {window.innerWidth > 600?"Admin Map":""}
                </NavLink>
              </NavItem>:null}
              {(role && role.indexOf("admin") !== -1)?<NavItem>
                <NavLink
                  to="/admin/search"
                  tag={NavLinkRRD}
                  onClick={this.closeCollapse}
                  activeClassName="active active-rmt-nav-link"
                  className="rmt-nav-link"
                >
                  <i className="ni ni-zoom-split-in" />
                  {window.innerWidth > 600?"Admin Search":""}
                </NavLink>
              </NavItem>:null}

              {this.createLinks(routes)}

              {type === "comp"?<NavItem>
                <NavLink
                  to="/admin/requests"
                  tag={NavLinkRRD}
                  onClick={this.closeCollapse}
                  activeClassName="active active-rmt-nav-link"
                  className="rmt-nav-link"
                >
                  <i className="ni ni-badge" />
                  {window.innerWidth > 600?"Tech Requests":""}
                </NavLink>
              </NavItem>:null}

              {type === "comp"?<NavItem>
                <NavLink
                  to="/admin/job_requests"
                  tag={NavLinkRRD}
                  onClick={this.closeCollapse}
                  activeClassName="active active-rmt-nav-link"
                  className="rmt-nav-link"
                >
                  <i className="ni ni-single-02" />
                  {window.innerWidth > 600?"Job Requests":""}
                </NavLink>
              </NavItem>:null}

              <NavItem>
                <NavLink
                  to="#"
                  tag={Link}
                  onClick={this.onPress_logout}
                  className="rmt-nav-link"
                >
                  <i className="ni ni-button-power" />
                  {window.innerWidth > 600?"Logout":""}
                </NavLink>
              </NavItem>
            </Nav>
            {/* Divider */}
            <hr className="my-3" />
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

function map_state_to_props({user}) {
  return {user};
}

export default withRouter(connect(map_state_to_props, {logout})(Sidebar));
