import {FETCH_RANKINGS, CHANGE_RANKING_VIEW} from "./types";
import Axios from "axios";
import config from "../config";

export const fetch_rankings = () => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/rankings/technicians`).catch((err) => console.log(err));

    if(res.data){
        dispatch({type: FETCH_RANKINGS, payload: res.data});
    }
}

export const change_ranking_view = (view) => {
    return ({type: CHANGE_RANKING_VIEW, payload: view});
}