import React, { Component } from 'react';
import {connect} from "react-redux";
import Profile_skill from "./profile_skill";

import config from '../../config';

class Profile_comp_info_column extends Component {
    state = {  }

    render() { 
        const {description, website, email, phone} = this.props.user;

        return (
            <>
                <div>

                    <div className="profile-about">
                        
                        <h1 style={{color: "#104464", marginBottom: 45}}>About</h1>

                        <div style={{display: "flex"}}><i class="far fa-file-alt" style={{fontSize: 20, color: "#104464"}} /> <a href={website}><h3 style={{marginBottom: 30, display: "inline-block"}}>{website}</h3></a></div>

                        <div style={{display: "flex"}}><i class="far fa-envelope" style={{fontSize: 20, color: "#104464"}} /> <h3 style={{marginBottom: 30, display: "inline-block"}}>{email}</h3></div>

                        <div style={{display: "flex"}}><i class="fas fa-phone" style={{fontSize: 20, color: "#104464"}} /> <h3 style={{marginBottom: 30, display: "inline-block"}}>{phone}</h3></div>

                    </div>


                    <hr />

                    <p className="profile-description">{description || "No Description"}</p>

                </div>
            </>
        );
    }
}

function map_state_to_props({user}){
    return {user}
}

export default connect(map_state_to_props)(Profile_comp_info_column);