import {NAV_HEADER, BACK_BUTTON, ACCOUNT_TYPE, SET_SOCKET, LOGOUT, FETCHED_INFO, DISCOVER_PRESSED, CLOSE_DISCOVER, DISMISS_DISCOVER, SET_ATTEMPTED_URL, OPEN_NAV, CLOSE_NAV} from "../actions/types";

const initial_state = {nav_title: "", has_back_button: false, account_type: "", onPress_back: () => {}, socket: null, info_fetched: null, discover_pressed: false, attempted_url: "", open_nav: false}

export default (state = initial_state, action) => {

    const new_state = {...state}
    
    switch(action.type){
        case NAV_HEADER:
            new_state.nav_title = action.payload;
        break;
        case BACK_BUTTON:
            new_state.has_back_button = action.payload.has_back;
            new_state.onPress_back = action.payload.press_back || state.onPress_back;
        break;
        case ACCOUNT_TYPE:
            new_state.account_type = action.payload;
        break;

        case SET_SOCKET:
            new_state.socket = action.payload;
        break;

        case FETCHED_INFO:
            new_state.info_fetched = true
        break;

        case DISCOVER_PRESSED:
            new_state.discover_pressed = true;
        break;
        
        case CLOSE_DISCOVER:
            new_state.discover_pressed = false;
        break;

        case DISMISS_DISCOVER:
            new_state.discover_pressed = false;
        break;

        case SET_ATTEMPTED_URL:
            new_state.attempted_url = action.payload;
        break;

        case OPEN_NAV:
            new_state.open_nav = true;
        break

        case CLOSE_NAV:
            new_state.open_nav = false;
        break;

        case LOGOUT:
            if(new_state.socket){
                new_state.socket.close()
            }
            
            new_state.info_fetched = null
        break;

        default:
            return state;
        }

        return new_state;
}