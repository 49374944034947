import React, { Component } from 'react';
import {connect} from "react-redux";

import {dismiss_update} from "../../actions";

import config from '../../config';

import {UncontrolledDropdown, Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";

class Update extends Component {
    state = {  }

    onPress_dismiss = () => {
        const {update} = this.props;
        this.props.dismiss_update(update._id);
    }

    date_string(date){
        const options = {weekday: "long", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit", hour12: true};
        return new Date(date).toLocaleString("default", options);
    }

    render_update_actions(){
        return (
            <DropdownMenu>
                <DropdownItem onClick={this.onPress_dismiss}>
                    Dismiss
                </DropdownItem>
            </DropdownMenu>
        );
    }

    render() { 
        const {update} = this.props;
        return (
            <div className="job-item-wrapper">
                <div className="job-item">
                    <div className="job-details-wrapper">
                        <span className="job-icon">
                            <i className="fas fa-sign-language" />
                        </span>

                        <span className="job-details">
                            <h4 className="job-title">{this.date_string(update.time)}</h4>
                        </span>
                    </div>

                    <div className="job-description">
                        <p style={{height: 100, overflow: "auto"}}>{update.text_content}</p>
                    </div>
                </div>

                <UncontrolledDropdown className="ucdropdown">
                <DropdownToggle className="job-elipses">
                    ...
                </DropdownToggle>

                {this.render_update_actions()}
                </UncontrolledDropdown>
            </div>
        );
    }
}

// function map_state_to_props({updates}) {
//     return ({updates: updates.updates});
// }
 
export default connect(null, {dismiss_update})(Update);