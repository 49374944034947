import Axios from "axios";
import {SEARCH_USERS, SEARCH_TEXT_UPDATE, SET_CURRENT_USERS, SET_TOTAL_USERS, DISCOVER_PRESSED_USAGE, DISCOVER_SEARCH_USAGE, SET_CUSTOMER_TRAFFIC, SET_JOB_REQUEST_COUNT} from "./types";
import config from "../config";

export const search_users = (search_text) => async (dispatch) => {
    dispatch({type: SEARCH_TEXT_UPDATE, payload: search_text});
    let trim_text = search_text.trim();

    const res = await Axios.post(`${config.real_backend_url}/api/admin/search`, {search_text: trim_text}, {withCredentials: true});

    if(res.data.searched){
        dispatch({type: SEARCH_USERS, payload: {users: res.data.users}});
        return true;
    }
}

export const set_current_users = (current_users) => {
    return ({type: SET_CURRENT_USERS, payload: current_users});
}

export const set_total_users = (total_users) => {
    return ({type: SET_TOTAL_USERS, payload: total_users});
}

export const discover_pressed_usage = (presses) => {
    return ({type: DISCOVER_PRESSED_USAGE, payload: presses});
}

export const discover_search_usage = (searches) => {
    return ({type: DISCOVER_SEARCH_USAGE, payload: searches});
}

export const set_customer_traffic = (traffic) => {
    return ({type: SET_CUSTOMER_TRAFFIC, payload: traffic});
}

export const set_job_request_count = (request_count) => {
    return ({type: SET_JOB_REQUEST_COUNT, payload: request_count});
}