import {FORGOT_FORM_UPDATE, FORGOT_EMAIL_VERIFIED, FORGOT_PASSWORD, FORGOT_PASSWORD_CHANGED, FORGOT_PASSWORD_NOT_CHANGED, FORGOT_INVALID_EMAIL} from "../actions/types";

const INITIAL_STATE =  {email: "", password: "", verified: null, email_validation_err: ""};

export default (state = INITIAL_STATE, {type, payload}) => {
    const new_state = {...state};

    switch(type){

        case FORGOT_PASSWORD_CHANGED:
            return INITIAL_STATE;

        case FORGOT_EMAIL_VERIFIED:
            new_state.verified = true;
        break;
        
        case FORGOT_FORM_UPDATE:
            // alert(payload.value);
            new_state[payload.key] = payload.value;
            new_state.email_validation_err = "";
        break;

        case FORGOT_PASSWORD: 
            return state;

        case FORGOT_PASSWORD_NOT_CHANGED:
            return state;

        case FORGOT_INVALID_EMAIL:
            new_state.email_validation_err = "Please use a valid email address"
        break;

        default:
            return state;

    }

    return new_state;
}