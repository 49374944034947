export const RETRY = "RETRY";
export const LOGOUT = "LOGOUT";
export const FORM_VIEW = "FORM_VIEW";
export const LOGGED_IN = "LOGGED_IN";
export const LOGGING_IN = "LOGGING_IN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESEND_EMAIL = "RESEND_EMAIL";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const PROCESS_INPUT = "PROCESS_INPUT";
export const INVALID_FORMAT = "INVALID FORMAT";
export const ADD_CITY_SIGN_UP = "ADD_CITY_SIGN_UP";
export const ACCOUNT_ACTIVATED = "ACCOUNT_ACTIVATED";
export const REMOVE_CITY_SIGN_UP = "REMOVE_CITY_SIGN_UP";
export const CHOOSE_ACCOUNT_TYPE = "CHOOSE_ACCOUNT_TYPE";
export const ADD_CATEGORY_SIGN_UP = "ADD_CATEGORY_SIGN_UP";
export const REMOVE_CATEGORY_SIGN_UP = "REMOVE_CATEGORY_SIGN_UP";
export const ADD_STATE_SIGN_UP = "ADD_ STATE_SIGN_UP";
export const REMOVE_STATE_SIGN_UP = "REMOVE_STATE_SIGN_UP";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_FORM_UPDATE = "FORGOT_FORM_UPDATE";
export const FORGOT_INVALID_EMAIL = "FORGOT_INVALID_EMAIL";
export const FORGOT_EMAIL_VERIFIED = "FORGOT_EMAIL_VERIFIED";
export const FORGOT_PASSWORD_CHANGED = "FORGOT_PASSWORD_CHANGED";
export const FORGOT_PASSWORD_NOT_CHANGED = "FORGOT_PASSWORD_CHANGED";

export const SET_JOBS = "SET_JOBS";
export const SET_STARS = "SET_STARS";
export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER = "UPDATE_USER";
export const ACCOUNT_TYPE = "ACCOUNT_TYPE";
export const FETCHING_USER = "FETCHING_USER";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";
export const UPLOAD_PROFILE_IMG = "UPLOAD_PROFILE_IMG";

export const CLOSE_MODAL = "CLOSE_MODAL";
export const ITEM_CLICKED = "ITEM_CLICKED";

export const ADD_JOB = "ADD_JOB";
export const JOB_TYPE = "JOB_TYPE";
export const STOP_JOB = "STOP_JOB";
export const START_JOB = "START_JOB";
export const CREATE_JOB = "CREATE_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const FETCH_JOBS = "FETCH_JOBS";
export const STOP_SEARCH = "STOP_SEARCH";
export const ARCHIVE_JOB = "ARCHIVE_JOB";
export const RESTORE_JOB = "RESTORE_JOB";
export const CREATING_JOB = "CREATING_JOB";
export const START_SEARCH = "START_SEARCH";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const COMPLETE_JOB = "COMPLETE_JOB";
export const FETCHING_JOBS = "FETCHING_JOBS";
export const INCREMENT_TIME = "INCREMENT_TIME";
export const ALLOW_JOB_START = "ALLOW_JOB_START";
export const FETCH_JOBS_ERROR = "FETCH_JOB_ERROR";
export const CREATE_JOB_ERROR = "CREATE_JOB_ERROR";
export const STOP_CREATING_JOB = "STOP_CREATING_JOB";
export const SHOW_JOB_CREATION = "SHOW_JOB_CREATION";
export const SEARCH_FORM_UPDATE = "SEARCH_FORM_UPDATE";
export const CLOSE_JOB_CREATION = "CLOSE_JOB_CREATION";
export const AFTER_PHOTO_UPLOAD = "AFTER_PHOTO_UPLOAD";
export const BEFORE_PHOTO_UPLOAD = "BEFORE_PHOTO_UPLOAD";
export const SHOW_JOB_CREATION_MODAL = "SHOW_JOB_CREATION_MODAL";
export const JOB_CREATION_FORM_UPDATE = "JOB_CREATION_FORM_UPDATE";
export const DISMISS_JOB_CREATION_MODAL = "DISMISS_JOB_CREATION_MODAL";

export const ADD_UPDATE = "ADD_UPDATE";
export const VIEW_UPDATE = "VIEW_UPDATE";
export const FETCH_UPDATES = "FETCH_UPDATES";
export const CREATED_UPDATE = "CREATED_UPDATE";
export const DISMISS_UPDATE = "DISMISS_UPDATE";
export const FETCHING_UPDATES = "FETCHING_UPDATES";
export const DELETE_JOB_UPDATES = "DELETE_JOB_UPDATES";
export const FETCH_UPDATES_ERROR = "FETCH_UPDATES_ERROR";
export const REMOVE_LATEST_UPDATE = "REMOVE_LATEST_UPDATE";

export const OPEN_NAV = "OPEN_NAV";
export const CLOSE_NAV = "CLOSE_NAV";
export const CUSTOMER_PHOTO_SIGNUP = "CUSTOMER_PHOTO_SIGNUP";
export const SET_SOCKET = "SET_SOCKET";
export const NAV_HEADER = "NAV_HEADER";
export const BACK_BUTTON = "BACK BUTTON";
export const FETCHED_INFO = "FETCHED_INFO";
export const CLOSE_DISCOVER = "CLOSE_DISCOVER";
export const DISCOVER_PRESSED = "DISCOVER_PRESSED";
export const SET_ATTEMPTED_URL = "SET_ATTEMPTED_URL";
export const INCREMENT_DISCOVER_TABLE_INDEX = "INCREMENT_DISCOVER_TABLE";
export const DECREMENT_DISCOVER_TABLE_INDEX = "DECREMENT_DISCOVER_TABLE";

export const REVIEW_JOB = "REVIEW_JOB";
export const START_REVIEW = "START_REVIEW";
export const FETCH_REVIEWS = "FETCH_REVIEWS";
export const SET_TOTAL_ARR = "SET_TOTAL_ARR";
export const SET_STAR_RATING = "SET_STAR_RATING";
export const FETCHING_REVIEWS = "FETCHING_REVIEWS";
export const FETCH_REVIEW_ERROR = "FETCH_REVIEW_ERROR";
export const JOB_REVIEW_FROM_UPDATE = "JOB_REVIEW_FORM_UPDATE";

export const ADD_CITY = "ADD_CITY";
export const REMOVE_CITY = "REMOVE_CITY";
export const CANCEL_EDIT = "CANCEL_EDIT";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const SAVE_USER_EDIT = "SAVE_EDITS";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const USER_EDIT_INIT_FORMS = "USER_EDIT_INIT_FORMs";
export const USER_EDIT_FORM_UPDATE = "USER_EDIT_FORM_UPDATE";
export const ADD_STATE = "ADD_STATE";
export const REMOVE_STATE = "REMOVE_STATE";
export const CHANGE_PASSWORD = "CHANGE_PASWORD";
export const PASSWORD_FORM_UPDATE = "PASSWORD_FORM_UPDATE";

export const UNLINK_COMPANY = "UNLINK_COMPANY";
export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const SEARCH_COMPANIES = "SEARCH_CAMPANIES";
export const REQUEST_EMPLOYMENT = "REQUEST_EMPLOYMENT";
export const SEARCHING_COMPANIES = "SEARCHING_COMPANIES";
export const SEARCH_STRING_UPDATE = "SEARCH_STRING_UPDATE";
export const ADD_COMPANY_AND_UPDATE = "ADD_COMPANY_AND_UPDATE";
export const FETCH_UNLINKED_COMPANIES = "FETCH_UNLINKED_COMPANIES";
export const SHOW_EMPLOYER_COMPANY_MODAL = "SHOW_EMPLOYER_COMPANY_MODAL";
export const CLOSE_EMPLOYER_COMPANY_MODAL = "CLOSE_EMPLOYER_COMPANY_MODAL";

export const ADD_REQUEST = "ADD_REQUEST";
export const EMPLOYEE_TYPE = "EMPLOYEE_TYPE";
export const FETCH_REQUESTS = "FETCH_REQUESTS";
export const ACCEPT_REQUEST = "ACCEPT_REQUEST";
export const REJECT_REQUEST = "REJECT_REQUEST";
export const FETCH_EMPLOYEES = "FETCH_EMPLOYEES";
export const FETCH_PENDING_EMPLOYEES = "FETCH_PENDING_EMPLOYEES";
export const TERMINATE_EMPLOYEE = "TERMINATE_EMPLOYEE";
export const EMPLOYMENT_UNLINK_UPDATE = "EMPLOYMENT_UNLINK_UPDATE";
export const FETCH_UNLINKED_EMPLOYEES = "FETCH_UNLINKED_EMPLOYEES";
export const FETCH_TERMINATED_EMPLOYEES = "FETCH_TERMINATED_EMPLOYEES";
export const EMPLOYMENT_TERMINATE_UPDATE = "EMPLOYMENT_TERMINATE_UPDATE";
export const INCREMENT_EMPLOYEE_TABLE_INDEX = "INCREMENT_DISCOVER_TABLE";
export const DECREMENT_EMPLOYEE_TABLE_INDEX = "DECREMENT_DISCOVER_TABLE";

export const GET_TOP_TECHS = "GET_TOP_TECHS";
export const DISCOVER_SEARCH = "DISCOVER_SEARCH";
export const JOB_REQUEST_SENT = "JOB_REQUEST_SENT";
export const DISMISS_DISCOVER = "DISMISS_DISCOVER";
export const SENDING_JOB_REQUEST = "SENDING_JOB_REQUEST";
export const DISCOVER_FORM_UPDATE = "DISCOVER_FORM_UPDATE";
export const DISCOVER_INVALID_NAME = "DISCOVER_INVALID_NAME";
export const DISCOVER_INVALID_PHONE = "DISCOVER_INVALID_PHONE";
export const DISCOVER_INVALID_EMAIL = "DISCOVER_INVALID_EMAIL";
export const OPEN_JOB_REQUEST_MODAL = "OPEN_JOB_REQUEST_MODAL";
export const CLOSE_JOB_REQUEST_MODAL = "CLOSE_JOB_REQUEST_MODAL";
export const DISCOVER_CLICKED_COMPANY = "DISCOVER_CLICKED_COMPANY";
export const SET_REQUESTED_TECHNICIAN = "SET_REQUESTED_TECHNICIAN";
export const CLOSE_DISCOVER_TECH_MODAL = "CLOSE_DISCOVER_TECH_MODAL";
export const CLOSE_DISCOVER_COMP_MODAL = "CLOSE_DISCOVER_COMP_MODAL";
export const JOB_REQUEST_FULLY_COMPLETE = "JOB_REQUEST_FULLY_COMPLETE";
export const DISCOVER_SEARCH_SUGGESTIONS = "DISCOVER_SEARCH_SUGGESTIONS";
export const DISCOVER_CLICKED_TECHNICIAN = "DISCOVER_CLICKED_TECHNICIAN";
export const DISCOVER_RESULTS_CHANGE_VIEW = "DISCOVER_RESULTS_CHANGE_VIEW";
export const DISCOVER_RESULTS_CLOSE_TECHNICIAN = "DISCOVER_RESULTS_CLOSE_TECHNICIAN";
export const DISCOVER_RESULTS_CLICKED_TECHNICIAN = "DISCOVER_RESULTS_CLICKED_TECHNICIAN";

export const FETCH_RANKINGS = "FETCH_RANKINGS";
export const CHANGE_RANKING_VIEW = "CHANGE_RANKING_VIEW";

export const SEARCH_USERS = "SEARCH_USERS";
export const SET_TOTAL_USERS = "SET_TOTAL_USERS";
export const SET_CURRENT_USERS = "SET_CURRENT_USERS";
export const SEARCH_TEXT_UPDATE = "SEARCH_TEXT_UPDATE";
export const SET_CUSTOMER_TRAFFIC = "SET_CUSTOMER_TRAFFIC";
export const DISCOVER_SEARCH_USAGE = "DISCOVER_SEARCH_USAGE";
export const SET_JOB_REQUEST_COUNT = "SET_JOB_REQUEST_COUNT";
export const DISCOVER_PRESSED_USAGE = "DISCOVER_PRESSED_USAGE";

export const ADD_REFERRAL = "ADD_REFERRAL";
export const FETCH_REFERRALS = "FETCH_REFERRALS";
export const UPDATE_REFERRAL = "UPDATE_REFERRAL";
export const SET_REFERRAL_TOTAL = "SET_REFERRAL_TOTAL";
export const ACCEPT_JOB_REQUEST = "ACCEPT_JOB_REQUEST";
export const REJECT_JOB_REQUEST = "REJECT_JOB_REQUEST";

export const SET_HISTOTY_JOBS = "SET_HISTORY_JOBS";
export const SET_HISTORY_REVIEWS = "SET_HISTORY_REVIEWS";
export const SET_HISTORY_TECHNICIAN = "SET_HISTORY_TECHNICIAN";
export const SET_HISTORY_FROM_DISCOVER = "SET_HISTORY_FROM_DISCOVER";
export const SET_HISTORY_COMPLETED_JOBS = "SET_HISTORY_COMPLETED_JOBS";