import Axios from "axios";
import {FETCH_UPDATES, FETCH_UPDATES_ERROR, DISMISS_UPDATE, CREATED_UPDATE, FETCHING_UPDATES, REMOVE_LATEST_UPDATE} from "./types";
import config from "../config";

export const fetch_updates = () => async (dispatch) => {
    dispatch({type: FETCHING_UPDATES});
    const res = await Axios.get(`${config.real_backend_url}/api/user/updates`, {withCredentials: true}).catch((err) => {console.log(err); dispatch({type: FETCH_UPDATES_ERROR, payload: "failed to fetch updates"}) });

    if(!res){
        return null;
    }

    const {data} = res;

    dispatch({type: FETCH_UPDATES, payload: data.updates})
};

export const remove_latest_update = () => {
    return ({type: REMOVE_LATEST_UPDATE});
}

export const create_update = ({review_id, job_id, update_type, text_content}) => async (dispatch) => {

    const res = await Axios.post(`${config.real_backend_url}/api/updates/${job_id}/create`, {review: review_id, text_content, update_type}, {withCredentials: true}).catch((err) => {alert(JSON.stringify(err)); return false} );

    if(res.data.saved){
        //alert("update created");
        dispatch ({type: CREATED_UPDATE, payload: res.data.new_update});
        return true;
    }else{
        return false;
    }
}

export const dismiss_update = (update_id) => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/update/dismiss/${update_id}`, {withCredentials: true}).catch((err) => {alert(JSON.stringify(err))});

    if(res.data.dismissed){
        dispatch({type: DISMISS_UPDATE, payload: update_id});
        return true;
    }else{
        return false;
    }
}