import {FETCH_REFERRALS, LOGOUT, ADD_REFERRAL, ACCEPT_JOB_REQUEST, REJECT_JOB_REQUEST, UPDATE_REFERRAL} from "../actions/types";

const INITIAL_STATE =  {referrals: [], loading: false};

export default (state = INITIAL_STATE, {type, payload}) => {
    let new_state = {...state};
    let i;

    switch(type){

        case FETCH_REFERRALS:
            new_state.referrals = payload;
            new_state.loading = false;
        break;

        case ADD_REFERRAL:
            new_state.referrals = [payload, ...new_state.referrals];
        break;

        case ACCEPT_JOB_REQUEST:
           i = new_state.referrals.findIndex((referral) => referral._id === payload);

           if(i !== -1){
               new_state.referrals[i].accepted = true;
           }

           new_state.referrals = [...new_state.referrals];
        break;
        
        case REJECT_JOB_REQUEST:
           i = new_state.referrals.findIndex((referral) => referral._id === payload);

           if(i !== -1){
               new_state.referrals[i].rejected = true;
           }

           new_state.referrals = [...new_state.referrals];
        break;

        case UPDATE_REFERRAL:
           i = new_state.referrals.findIndex((referral) => referral._id === payload.referral_id);

           if(i !== -1){
               if(payload.accepted){
                new_state.referrals[i].accepted = true;
               }else{
                new_state.referrals[i].rejected = true;
               }
           }

           new_state.referrals = [...new_state.referrals];
        break;

        case LOGOUT:
            new_state = INITIAL_STATE;
        break;

        default:
            return state;

    }

    return new_state;
}