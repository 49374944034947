import React, { Component } from 'react';
import {connect} from "react-redux";

import {fully_complete_job_request} from "../../actions";

import {
    Modal,
    ModalBody,
} from "reactstrap";

class Job_request_success_modal extends Component {
    state = {  }

    toggle_model = () => {
        this.props.fully_complete_job_request();
    }

    render() { 
        const {request_sent, technician} = this.props;
        return (
            <div>
                <Modal isOpen={request_sent} toggle={this.toggle_model}>
                    <ModalBody style={{textAlign: "center"}} className="discover-request-sent-modal">

                        <img src={require("../../assets/images/check.png")} style={{objectFit: "cover", width: "60%", height: "auto", minWidth: 250, minHeight: 250}} />
                        
                        <p>{`Thank you, Your request for ${technician.name.first} ${technician.name.last} has been sent to ${technician.companies[0]?technician.companies[0].comp_name:"someone"}. A representative will reach out to you within minutes.  You can also call the office here here.`}</p>

                        <button className="close-button" onClick={this.toggle_model}>X</button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function map_state_to_props({discover}){
    return ({request_sent: discover.request_sent});
}
 
export default connect(map_state_to_props, {fully_complete_job_request})(Job_request_success_modal);