import {FETCH_EMPLOYEES, FETCH_PENDING_EMPLOYEES, ACCEPT_REQUEST, REJECT_REQUEST, EMPLOYEE_TYPE, FETCH_UNLINKED_EMPLOYEES, FETCH_TERMINATED_EMPLOYEES, TERMINATE_EMPLOYEE, INCREMENT_EMPLOYEE_TABLE_INDEX, DECREMENT_EMPLOYEE_TABLE_INDEX} from "./types";

import Axios from "axios";
import config from "../config";

export const fetch_employees = () => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/employment/employees`, {withCredentials: true}).catch((err) => {
        alert(JSON.stringify(err));
        console.log(JSON.stringify(err));
    });

    if(res.data.success){
        dispatch({type: FETCH_EMPLOYEES, payload: res.data.employees});
        dispatch({type: FETCH_UNLINKED_EMPLOYEES, payload: res.data.unlinked_employees});
        dispatch({type: FETCH_TERMINATED_EMPLOYEES, payload: res.data.terminated_employees});
    }
};

export const fetch_pending_employees = () => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/employment/get/pending_employees`, {withCredentials: true}).catch((err) => {
        alert(JSON.stringify(err));
        console.log(JSON.stringify(err));
    });

    if(res.data.success){
        dispatch({type: FETCH_PENDING_EMPLOYEES, payload: res.data.pending_employees});
    }
}

export const accept_employee = (request_id) => async (dispatch) => {
    const res = await Axios.post(`${config.real_backend_url}/api/employment/request/respond/${request_id}`, {accepted: true}, {withCredentials: true}).catch((err) => {
        alert(JSON.stringify(err));
        console.log(err);
        return null;
    });

    if(res.data.responded){
        dispatch({type: ACCEPT_REQUEST, payload: {employee: res.data.employee, request_id}});
        return true;
    }else{
        return false;
    }
}

export const reject_employee = (request_id) => async (dispatch) => {
    const res = await Axios.post(`${config.real_backend_url}/api/employment/request/respond/${request_id}`, {accepted: false}, {withCredentials: true}).catch((err) => {
        alert(JSON.stringify(err));
        console.log(err);
        return null;
    });

    if(res.data.responded){
        dispatch({type: REJECT_REQUEST, payload: {request_id}});
        return true;
    }else{
        return false;
    }
}

export const terminate_employee = (employee) => async (dispatch) => {
    const res = await Axios.post(`${config.real_backend_url}/api/employee/terminate`, {employee}, {withCredentials: true});

    if(res.data.terminated){
        dispatch({type: TERMINATE_EMPLOYEE, payload: {employment: res.data.employment}});

        return true;
    }

    return false;
}

export const increment_employee_table_index = () => {
    return {type: INCREMENT_EMPLOYEE_TABLE_INDEX};
}

export const decrement_employee_table_index = () => {
    return {type: DECREMENT_EMPLOYEE_TABLE_INDEX};
}

export const switch_employee_view = (view) => {
    return({type: EMPLOYEE_TYPE, payload: view});
}