import React, {Component} from "react";
// react plugin used to create google maps
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

import {Map, GoogleApiWrapper, Marker, InfoWindow} from "google-maps-react";
import config from "../../config";
const loading = () => {
    return <image src="/profile_avatar.png" />
  }
  
  var zoom = 10;
  
  class Map_container extends Component {
    state = {position: {}, bounds: null, position_discovered: false, selected_marker: {}, showInfoWindow: false, user: {}}
    constructor(props){
        super(props)
        
        this.onMapClick = this.onMapClick.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
    }
  
    componentDidMount(){
        
        window.navigator.geolocation.getCurrentPosition((pos) => {
            var points = [
                { lat: pos.coords.longitude, lng: pos.coords.longitude }
            ];
  
            this.setState({position: {lat: pos.coords.latitude, lng: pos.coords.longitude}, position_discovered: true});
  
            let b = new this.props.google.maps.LatLngBounds();
  
            b.extend(points[0]);
  
  
            b.extend({lat: pos.coords.latitude + 0.01, lng: pos.coords.longitude + 0.01});
  
            b.extend({lat: pos.coords.latitude + 0.02, lng: pos.coords.longitude + 0.02});
  
            this.map.map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude})
            this.map.maps.event.trigger(this.map.map, 'recenter');
            this.map.map.fitBounds(b);
            this.map.recenterMap();
        });

        this.map_update_interval = setInterval(()=>{this.forceUpdate()}, 1000);
    }

    componentWillUnmount(){
        clearInterval(this.map_update_interval);
    }
  
    onMarkerClick(props, marker, e){
        console.log(props);
        this.setState({
            showInfoWindow: true,
            selected_marker: marker,
            user: props
        });
    }

    onMapClick(props){
        this.setState({
            showInfoWindow: false,
            selected_marker: null,
        })
    }
    
    render() {
        // var height = document.getElementsByClassName("everything-except-footer")[0].clientHeight - 38
        const {name, type, email, active, current_job, stars} = this.state.user
        return (
            <Map onClick={this.onMapClick} styles={[{featureType: "water", elementType: "all", stylers: [{ color: "#1a3279" }, { visibility: "on" }]}]} style={{height: "600px", margin: "0 auto", borderRadius: "8px"}} google={this.props.google} ref={(map) => {this.map = map;}} zoom={zoom}>
  
                <Marker onClick={this.onMarkerClick} type="Admin" title="Your location" name="You" email="" position={this.state.position} active={true} />


  
                {this.props.markers.map((marker) => {
                    if(marker.email){
                        return (
                            <Marker active={marker.active} onClick={this.onMarkerClick} name={marker.comp_name || `${marker.name.first} ${marker.name.last}`} email={marker.email} type={marker.type==="tech"?"Technician":"Company"} title="user position" position={{lat: marker.lat, lng: marker.lon}} current_job={marker.current_job || {}} stars={marker.stars || 0} />
                        );
                    }else{
                        return (
                            <Marker active={marker.active} onClick={this.onMarkerClick} name="N/A" email="N/A" type="N/A" title="user position" position={{lat: marker.lat, lng: marker.lon}} />
                        );
                    }
                })}

                <InfoWindow visible={this.state.showInfoWindow} marker={this.state.selected_marker} >
                    <div style={{backgroundColor: "white", borderRadius: 10}}>
                        <h2 style={{color: "black"}}>{name} - <span style={{color: "grey", fontSize: 14}}>{type}</span></h2>
                        <h3>{email}</h3>
                        <p className={active?"text-success":"text-warning"}>{active?"active":"inactive"} <span className="text-muted"><i className="fas fa-star" style={{color: "#e7b136"}} /> {stars?stars.toFixed(1):0}</span></p>

                        <p style={{color: "grey", fontSize: 14}}>current_job: <span className={current_job?current_job.title?"text-success":"text-warning":"text-warning"} style={{fontSize: 14}}></span>{current_job?current_job.title?current_job.title:"No active job":"No active job"}</p>
                        
                        {current_job?current_job.image_urls?<img alt="before image" src={`${config.real_backend_url}/uploads/images/${current_job.image_urls.before[0]?current_job.image_urls.before[0].url:""}`} style={{width: 150, height: 150, borderRadius: 8}} />:null:null}
                    </div>
                </InfoWindow>
  
            </Map>
        );
    }
  }
  
  export default GoogleApiWrapper({
    LoadingContainer: loading,
    apiKey: config.map_api_key
  })(Map_container);