import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";
import {connect} from "react-redux";

import App from "./components/App";
import Index from "./components/index";
import About from "./components/About";
import Technician_history from "./components/Technician_history";
import Contact from "./components/Contact";
import Leaderboard from "./components/Leaderboard";
import Discover from "./components/index/discover_search";
import ProtectedRoute from "./components/protected_route";

import {is_logged_in, fetch_user, fetch_companies, fetch_jobs, fetch_company_jobs, fetch_employees, fetch_pending_employees, fetch_requests, fetch_reviews, fetch_updates, set_socket, fetch_referrals, set_current_users, set_total_users, get_top_techs, discover_pressed_usage, discover_search_usage, set_customer_traffic, set_job_request_count, set_attempted_url} from "./actions";

import io from "socket.io-client";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";

var socket = io({
    timeout: 10000,
    jsonp: false,
    transports: ["websocket"],
});
  
socket.on("connect", function(){
console.log("connected to server.");
//socket.connected = true;
//socket.disconnected = false;
});

class Routing extends Component {
    state = {  }

    constructor(props){
        super(props);
        
        props.get_top_techs();
        props.set_socket(socket);
        
        socket.emit("visit");

        console.log(window.innerWidth);

        if(window.location.pathname !== "/" && window.location.pathname !== "/discover" && window.location.pathname !== "/auth/login" && window.location.pathname !== "/auth/register" && window.location.pathname !== "/view_history"){
            props.set_attempted_url(window.location.pathname);
            console.log(window.location.pathname)
        }
        
      }

    async componentWillMount(){
        if((await this.props.is_logged_in())){
          socket.emit("web login");

          await this.props.fetch_user();

          if(this.props.role && this.props.role.indexOf("admin") !== -1){
            await this.props.socket.on("current users", ({amount}) => {
               this.props.set_current_users(amount);
            });

            await this.props.socket.on("total_users", (total_users) => {
                this.props.set_total_users(total_users);
            });

            await this.props.socket.on("discover press usage", (presses) => {
                this.props.discover_pressed_usage(presses);
            })

            await this.props.socket.on("discover_search_usage", (searches) => {
                this.props.discover_search_usage(searches);
            })
            
            await this.props.socket.on("customer traffic", (traffic) => {
                this.props.set_customer_traffic(traffic);
            })
            
            await this.props.socket.on("job_request_count", (request_count) => {
                this.props.set_job_request_count(request_count);
            })
          }

      
          this.props.socket.emit("add client", this.props.email);
          if(this.props.type === "tech"){
              this.props.fetch_jobs();
              this.props.fetch_companies();
              this.props.fetch_updates();
              this.props.fetch_reviews();
              this.props.fetch_requests();
    
              this.props.fetch_referrals();
        
              
          }else if(this.props.type === "comp"){
              this.props.fetch_company_jobs();
              this.props.fetch_employees();
              this.props.fetch_pending_employees();
              this.props.fetch_updates();
              this.props.fetch_reviews();
              this.props.fetch_requests();
              this.props.fetch_referrals();
    
          }
          else if(this.props.type === "cust"){
            this.props.fetch_updates();
          }
        }
    }

    render() { 
        return (
            <div>
            <BrowserRouter ref={(router) => {this.router = router}}>
                <Switch>
                <Route path="/admin" render={props => <AdminLayout {...props} />} />
                <Route path="/auth" render={props => <AuthLayout {...props} />} />
                <Route exact path="/" component={Index} />
                <Route exact path="/discover" component={Discover} />
                <Route exact path="/leaderboard" component={Leaderboard} />
                <Route exact path="/mobile_app" component={App} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/about" component={About} />
                <Route exact path="/view_history" component={Technician_history} />
                <Redirect from="/login" to="/auth/login" />
                <Redirect from="/signup" to="/auth/register" />
                </Switch>
            </BrowserRouter>
            </div>
        );
    }
}

function map_state_to_props({app, user}){
    return {type: app.account_type, socket: app.socket, email: user.email, role: user.role, attempted_url: app.attempted_url}
} 
 
export default connect(map_state_to_props, {is_logged_in, fetch_user, fetch_companies, fetch_jobs, fetch_company_jobs, fetch_employees, fetch_pending_employees, fetch_requests, fetch_reviews, fetch_updates, set_socket, fetch_referrals, set_current_users, set_total_users, get_top_techs, discover_pressed_usage, discover_search_usage, set_customer_traffic, set_job_request_count, set_attempted_url})(Routing);