import React from "react";
// node.js library that concatenates classes (strings)
import {connect} from "react-redux";
// javascipt plugin for creating charts
import Chart from "chart.js";


// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import Header from "components/Headers/Header.jsx";

import Admin_dashboard from "../components/Dashboard/Admin_dashboard";
import Company_dashboard from "../components/Dashboard/Company_dashboard";
import Technician_dashboard from "../components/Dashboard/Technician_dashboard";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  render() {
    const {type} = this.props.user;
    if(type === "tech"){
      return <Technician_dashboard />
    }else if(type === "comp"){
      return <Company_dashboard />
    }else{
      return <Admin_dashboard />
    }
  }
}

function map_state_to_props({user}){
  return {user};
}

export default connect(map_state_to_props, null)(Index);
