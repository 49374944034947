import React, { Component } from 'react';
import Profile_skill from "./profile_skill";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {
    Modal,
    ModalBody,
    UncontrolledTooltip
} from "reactstrap"

import {close_discover_tech_modal} from "../../actions"

import config from '../../config';

class Technician_modal extends Component {

    toggle_tech_modal = () => {
        this.props.close_discover_tech_modal();
    }

    render_technician_image(){
        const {photo_url} = this.props.technician;
        
        if(photo_url){
          return(
            <img
              alt="..."
              className="img-circle rounded-circle img-thumbnail technician-image"
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                alt="..."
                className="img-circle rounded-circle img-thumbnail technician-image"
                src={require("assets/images/profile_avatar.png")}
              />
            )
        }
    }

    onPress_request_job = () => {
        if(this.props.onPress_request_job){
            this.props.onPress_request_job();
        }
    }

    render_star(star, avg_rating){
        if(avg_rating > star){
            return <i className="fas fa-star" style={{color: "#f7bd4b"}} />
        }else{
            if(avg_rating - (star - 1) > 0){
                return <i className="fas fa-star-half-alt" style={{color: "#f7bd4b"}} />
            }else{
                return <i className="fas fa-star" style={{color: "#aaa"}} />
            }
        }
    }

    render() { 
        const {technician, isOpen} = this.props

        if(technician){
            const {reviews} = technician;
            const stars = reviews.map((review)=>review.star_rating).reduce((prev, curr) => {return prev+curr}, 0);
            return (
                <Modal isOpen={isOpen} toggle={this.toggle_tech_modal}>
                    <ModalBody>
                    <div className="modal-body-content">
                        <div className="image-stats-info">
                            {this.render_technician_image()}
                            <div className="stats-info">
                                <h1>{`${technician.name.first} ${technician.name.last}`}</h1>
                                <h3 style={{color: "#44738e"}}>{`${technician.companies && technician.companies.length > 0?technician.companies[0].comp_name:"No Company"}`}</h3>

                                <span className="technician-stats">
                                    {/* {(technician.completed_jobs?(stars/technician.completed_jobs):0).toFixed(1)} <i id={"stars"+technician._id} className="fas fa-star" style={{color: "#f7bd4b", fontSize: 30, marginRight: 15}} />  */}
                                    <span id={"-tech-modal"+technician._id}>
                                    {this.render_star(1, (stars/technician.completed_jobs))}
                                    {this.render_star(2, (stars/technician.completed_jobs))}
                                    {this.render_star(3, (stars/technician.completed_jobs))}
                                    {this.render_star(4, (stars/technician.completed_jobs))}
                                    {this.render_star(5, (stars/technician.completed_jobs))}
                                    </span>

                                    <UncontrolledTooltip delay={0} target={"-tech-modal"+technician._id}>
                                        {(stars||0).toFixed(1)} <i id={"stars"+technician._id} className="fas fa-star" style={{color: "#f7bd4b"}} /> 
                                    </UncontrolledTooltip>
                                
                                    {technician.completed_jobs|| 0} <img id={"jobs"+technician._id} className="tech-modal-spanner-icon" src="/assets/images/spanner_icon@2x.png" /> 

                                    <UncontrolledTooltip delay={0} target={"jobs"+technician._id}>
                                        {technician.completed_jobs} Jobs
                                    </UncontrolledTooltip>
                                </span>

                                <span className="tech-modal-skills">
                                    {technician.categories.map((category) => {
                                        return (
                                            <Profile_skill skill={category} />
                                        );
                                    })}
                                </span>
                            </div>
                        </div>

                        <button onClick={() => {this.props.history.push("/view_history")}} className="button view-history-button"> <img style={{width: 20, height: 20, marginRight: 10, marginLeft: 5}} src={`${config.real_backend_url}/assets/images/icon_graph_bar(blue)@2x.png`} /> {window.innerWidth >= 700?"View History":""}</button>

                        <p className="tech-modal-description">{technician.description || "No Description."}</p>

                        <div className="request-button-container">
                            <button onClick={this.onPress_request_job} className="request-button-slant"></button>
                            <button onClick={this.onPress_request_job} className="request-button">Request Job</button>
                        </div>
                            
                        <button className="close-button" onClick={this.toggle_tech_modal}>X</button>
                    </div>
                    </ModalBody>
                </Modal>
            );
        }else{
            return null;
        }
    }
}

function map_state_to_props({discover}){
    return {technician: discover.clicked_technician, isOpen: discover.technician_clicked};
}
 
export default withRouter(connect(map_state_to_props, {close_discover_tech_modal})(Technician_modal));