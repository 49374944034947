import React, { Component } from 'react';
import config from "../../config";

import "./footer.css";

class Footer extends Component {
    state = {  }
    render() { 
        return (
            <div className="footer-wrapper">

                <div className="footer-content">
                    <div className="footer-title">
                   
                        <img className="footer-logo" src="/assets/images/Group 52@2x.png" style={{cursor: "pointer"}}/>

                  
</div>
                    <div className="footer-links-container">
                        <ul className="footer-links">
                            <li className="footer-link">Home</li>
                            <li className="footer-link">Discover</li>
                            <li className="footer-link">Login</li>
                            <li className="footer-link">Register</li>
                        </ul>
                    </div>

                
                </div>

            </div>
        );
    }
}
 
export default Footer;