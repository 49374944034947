import React, {Component} from "react";
// react plugin used to create google maps
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

import {withRouter} from "react-router-dom";

// reactstrap components
import { Card, Container, Row } from "reactstrap";

import Map_container from "./map_container";

// core components
import Header from "components/Headers/Header.jsx";
// mapTypeId={google.maps.MapTypeId.ROADMAP}

import {connect} from "react-redux";

import config from "../../config";

// const loading = () => {
//   return <image src="/profile_avatar.png" />
// }

// var zoom = 10;

// class Map_contain extends Component {
//   state = {position: {}, bounds: null, position_discovered: false}
//   constructor(props){
//       super(props)
      
//       this.onMarkerClick = this.onMarkerClick.bind(this);
//   }

//   componentDidMount(){
      
//       window.navigator.geolocation.getCurrentPosition((pos) => {
//           var points = [
//               { lat: pos.coords.longitude, lng: pos.coords.longitude }
//           ];

//           this.setState({position: {lat: pos.coords.latitude, lng: pos.coords.longitude}, position_discovered: true});

//           let b = new this.props.google.maps.LatLngBounds();

//           b.extend(points[0]);


//           b.extend({lat: pos.coords.latitude + 0.01, lng: pos.coords.longitude + 0.01});

//           b.extend({lat: pos.coords.latitude + 0.02, lng: pos.coords.longitude + 0.02});

//           this.map.map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude})
//           this.map.maps.event.trigger(this.map.map, 'recenter');
//           this.map.map.fitBounds(b);
//           this.map.recenterMap();
//       })
//   }

//   onMarkerClick(){
//       console.log("clicked", arguments);
//   }
  
//   render() {
//       var height = document.getElementsByClassName("everything-except-footer")[0].clientHeight - 38
//       return (
//           <Map styles={[{featureType: "water", elementType: "all", stylers: [{ color: "#5a72b9" }, { visibility: "on" }]}]} style={{height: "600px", margin: "0 auto"}} google={this.props.google} ref={(map) => {this.map = map;}} zoom={zoom}>

//               <Marker onClick={this.onMarkerClick} title="Current location" name={'Current location'} position={this.state.position} />

//               {this.props.markers.map((marker) => {
//                   return (
//                       <Marker onClick={this.onMarkerClick} title="technician" position={{lat: marker.lat, lng: marker.lon}} />
//                   )
//               })}

//           </Map>
//       );
//   }
// }

// const Map_container = GoogleApiWrapper({
//   LoadingContainer: loading,
//   apiKey: config.map_api_key
// })(Map_contain);

// const MapWrapper = withScriptjs(
//   withGoogleMap(props => (
//     <GoogleMap
//       defaultZoom={12}
//       defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
//       defaultOptions={{
//         scrollwheel: false,
//         styles: [
//           {
//             featureType: "administrative",
//             elementType: "labels.text.fill",
//             stylers: [{ color: "#444444" }]
//           },
//           // {
//           //   featureType: "landscape",
//           //   elementType: "all",
//           //   stylers: [{ color: "#f2f2f2" }]
//           // },
//           {
//             featureType: "poi",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           // {
//           //   featureType: "road",
//           //   elementType: "all",
//           //   stylers: [{ saturation: -100 }, { lightness: 45 }]
//           // },
//           // {
//           //   featureType: "road.highway",
//           //   elementType: "all",
//           //   stylers: [{ visibility: "simplified" }]
//           // },
//           // {
//           //   featureType: "road.arterial",
//           //   elementType: "labels.icon",
//           //   stylers: [{ visibility: "off" }]
//           // },
//           {
//             featureType: "transit",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "water",
//             elementType: "all",
//             stylers: [{ color: "#5a72b9" }, { visibility: "on" }]
//           }
//         ]
//       }}
//     >
//       <Marker position={props.position} />
//       {props.marker.map((marker) => {
//         return <Marker position={marker.pos} />
//       })}
//     </GoogleMap>
//   ))
// );

class Maps extends React.Component {

  state = { markers: [] }

    constructor(){
        super();

        this.connect_admin_socket = this.connect_admin_socket.bind(this);
        this.connect_company_socket = this.connect_company_socket.bind(this);
    }

    connect_admin_socket(){
      const {socket} = this.props;

      socket.on("location", async (location, user) => {
        // console.log(location);
        const i = await this.state.markers.findIndex((marker) => {
          return marker.id === location.id
        })
        if(i === -1){
            console.log("new technician location", this.state.markers.length);
            this.state.markers.push({...location, ...user, active: true});
            // this.forceUpdate();
        }else{
          this.state.markers[i] = {...location, ...user, active: true};
          // this.forceUpdate();
            // console.log("old technician location")
        }
      });

      socket.on("location-disconnect", async ({id}) => {
        let i = await this.state.markers.findIndex((marker) => {
          return marker.id === id;
        });
        this.state.markers[i].active = false;

        setTimeout(async ()=>{
          let j = await this.state.markers.findIndex((marker) => {
            return marker.id === id;
          });
          this.state.markers.splice(j, 1);
        }, 60000);

        // this.state.markers.splice(i, 1);
      });
    }

    connect_company_socket(){
      const {socket} = this.props;

      socket.emit("add client", this.props.user.email);

      socket.on("location company", async (location, user, current_job) => {
        // console.log("location-company");
        const i = await this.state.markers.findIndex((marker) => {
          return marker.id === location.id
        })
        if(i === -1){
            console.log("new technician location", this.state.markers.length);
            this.state.markers.push({...location, ...user, current_job, active: true});
            // this.forceUpdate();
        }else{
          this.state.markers[i] = {...location, ...user, current_job, active: true};
            // console.log("old technician location")
        }
      });

      socket.on("location company disconnect", async ({id}) => {
        let i = await this.state.markers.findIndex((marker) => {
          return marker.id === id;
        });

        this.state.markers[i].active = false;

        setTimeout(async ()=>{
          let j = await this.state.markers.findIndex((marker) => {
            return marker.id === id;
          });
          this.state.markers.splice(j, 1);
        }, 60000);

        // this.state.markers.splice(i, 1);
      });
    }

    componentDidMount(){

      const {socket} = this.props;

      if(this.props.user.role || this.props.user.type === "comp"){

        if(this.props.user.role.indexOf("admin") !== -1){
          this.connect_admin_socket();
          return;
        }else if(this.props.user.type === "comp"){
          this.connect_company_socket();
          return;
        }else{
          if(socket.hasListeners("location")){
              socket.removeAllListeners("location");
              socket.removeAllListeners("location-disconnect");
          }else if(socket.hasListeners("location company")){
            socket.removeAllListeners("location company");
            socket.removeAllListeners("location company disconnect");
          }
          this.props.history.push("/admin/index");
          return;
        }
      }
      return;
    }

    componentDidUpdate(pp, ps){
      const {socket} = this.props;

      if(this.props.user.role || this.props.user.type === "comp"){
          if(this.props.user.role !== pp.user.role || this.props.user.type === "comp"){
              if(this.props.user.role.indexOf("admin") !== -1){
                  if(!socket.hasListeners("location")){
                      this.connect_admin_socket();
                      return;
                  }
              }else if(this.props.user.type === "comp"){
                if(!socket.hasListeners("location-company")){
                    this.connect_company_socket();
                    return;
                }
              }else{
                if(socket.hasListeners("location")){
                  socket.removeAllListeners("location");
                  socket.removeAllListeners("location-disconnect");
                  socket.removeAllListeners("location company");
                  socket.removeAllListeners("location company disconnect");
                }
              this.props.history.push("/admin/index");
              return;
            }   
          }else{
            return;
          }
      }
      this.props.history.push("/admin/index");
    }

    componentWillUnmount(){
      const {socket} = this.props;

      socket.removeAllListeners("location");
      socket.removeAllListeners("location-disconnect");
      socket.removeAllListeners("location company");
      socket.removeAllListeners("location company disconnect");
    }

  render() {
    return (
      <>
        {/* <Header /> */}
        {/* Page content */}
        <Container style={{paddingTop: 78, margin: "25px 0"}} fluid>
          <Row>
            <div className="col">
              <Card className="shadow border-0">
                <div className="map-canvas" style={{ height: `600px`}}>
                  <Map_container markers={this.state.markers} />
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

function map_state_to_props({user, app}){
  return ({user, socket: app.socket});
}

export default withRouter(connect(map_state_to_props)(Maps));
