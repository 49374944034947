import React, { Component } from 'react';
import {connect} from "react-redux";
import RMT_header from "../RMT_header";
import Ranking_item from "./ranking_item";

import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
} from "reactstrap";

import {fetch_rankings, change_ranking_view} from "../../actions";

import "./leaderboard.css"

class Leaderboard extends Component {
    state = {  }

    componentDidMount(){
        this.props.fetch_rankings();
    }

    render() { 
        const {rankings} = this.props;
        return (
            <>
                <div style={{textAlign: "center"}} className="mb-3 mt-2 leaderboard-results-technicians-table">
                <Container className="mt-3 leaderboard-table-container" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <h3 className="mb-0">Rankings</h3>
                            </CardHeader>
                            <table style={{width: "100%"}}>
                            <thead>
                                <th><span className="leaderboard-table-heading"><img style={{width: 30, height: 30, objectFit: "contain", marginRight: 10}} src="/assets/images/icon_edit.png" /> Technician</span></th>
                                <th><span className="leaderboard-table-heading"><i style={{fontSize: 30, marginRight: 10}} className="fas fa-star" style={{color: "#f7bd4b"}} /> Stars</span></th>
                                <th><span className="leaderboard-table-heading"><img style={{width: 30, height: 30, objectFit: "contain", marginRight: 10}} src="/assets/images/spanner_icon@2x.png" /> Jobs</span></th>
                            </thead>

                            <tbody>
                                {rankings.map((ranking, i) => {
                                    return (
                                        <Ranking_item ranking={ranking} i={i} />
                                    );})
                                }
                            </tbody>
                            </table>
                            <CardFooter className="py-4">
                            <nav aria-label="...">
                                <Pagination
                                className="pagination justify-content-end mb-0"
                                listClassName="justify-content-end mb-0"
                                >
                                <PaginationItem className="disabled">
                                    <PaginationLink
                                    onClick={e => e.preventDefault()}
                                    tabIndex="-1"
                                    >
                                    <i className="fas fa-angle-left" />
                                    <span className="sr-only">Previous</span>
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem className="active">
                                    <PaginationLink
                                    onClick={e => e.preventDefault()}
                                    >
                                    1
                                    </PaginationLink>
                                </PaginationItem>
                                </Pagination>
                            </nav>
                            </CardFooter>
                        </Card>
                        </div>
                    </Row>
                </Container>
                </div>
            </>
        );
    }
}

function map_state_to_props({rankings}){
    return ({rankings: rankings.rankings})
}

export default connect(map_state_to_props, {fetch_rankings, change_ranking_view})(Leaderboard);