import {FETCH_RANKINGS, CHANGE_RANKING_VIEW} from "../actions/types";

const INITIAL_STATE = {rankings: [], rank_view: "stars"};

export default (state = INITIAL_STATE, action) => {

    const {type, payload} = action;
    const new_state = {...state};
    switch(type){

        case FETCH_RANKINGS:
            new_state.rankings = payload;
        break;

        case CHANGE_RANKING_VIEW:
            new_state.rank_view = payload;
        break;

        default:
            return state;
    }

    return new_state;
}