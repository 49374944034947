import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom"
import {connect} from "react-redux";
import Technician_modal from "./comp_profile_technician_modal";

import {discover_click_tecnician, increment_employee_table_index, decrement_employee_table_index, set_history_technician, set_history_jobs, set_history_reviews, set_history_completed_jobs_count, set_history_from_discover} from "../../actions";

import {UncontrolledTooltip} from "reactstrap";

import config from '../../config';

class Profile_employees_column extends Component {
    state = {  }

    onPress_technician = (technician) => {
        this.props.discover_click_tecnician(technician);

        const jobs = this.props.jobs.filter((job) => {
            return job.status === "completed" && (job.users.findIndex((user) => user === technician._id) !== -1)
        })

        this.props.set_history_technician(technician);
        this.props.set_history_jobs(jobs);
        this.props.set_history_reviews(technician.reviews);
        this.props.set_history_completed_jobs_count(jobs.length);
        this.props.set_history_from_discover(false);
    }

    render_actions(){
        return (
            <div className="history-column-actions">
 
                <div className="profile-actions">
                    <Link to="/admin/edit-profile" className="edit-button"><img src="/assets/images/icon_edit.png" /> Edit Profile</Link>

                    <button onClick={() => {this.props.history.push("/admin/leaderboard")}} className="stats-button"><img src="/assets/images/icon_graph_bar(blue).png" /> View Statistics</button>

                    <Link to="/admin/requests" className="company-button"><img src="/assets/images/icon_profile_unfocused@2x.png" /> Requests</Link>
                </div>
 
            </div>
        ) 
     }

    render_technician_image(photo_url){
        
        if(photo_url){
          return(
            <img
              alt="..."
              className="img-circle rounded-circle img-thumbnail"
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                alt="..."
                className="img-circle rounded-circle img-thumbnail"
                src={require("assets/images/profile_avatar.png")}
              />
            )
        }
    }

    render_star(star, avg_rating){
        if(avg_rating > star){
            return <i className="fas fa-star" style={{color: "#f7bd4b"}} />
        }else{
            if(avg_rating - (star - 1) > 0){
                return <i className="fas fa-star-half-alt" style={{color: "#f7bd4b"}} />
            }else{
                return <i className="fas fa-star" style={{color: "#aaa"}} />
            }
        }
    }

    onPress_increment = () => {
        this.props.increment_employee_table_index();
    }

    onPress_decrement = () => {
        this.props.decrement_employee_table_index();
    }

    render() { 
        const {employees, jobs, reviews, referrals, table_index, table_rows} = this.props;
        return (
            <>
            <Technician_modal />
            {this.render_actions()}
            <div className="employees-container">

                <table className="employees-table">
                    <thead>
                        <th>
                        <span><img style={{width: 20, height: 20, objectFit: "contain", marginRight: 10}} src="/assets/images/icon_profile_unfocused@2x.png" /> Technician</span>
                        </th>

                        <th>
                        <span><img style={{width: 20, height: 20, objectFit: "contain", marginRight: 10}} src="/assets/images/icon_graph_bar.png" /> Statistics</span>
                        </th>
                    </thead>

                    <tbody>
                        {employees.slice(table_index*table_rows, (table_index*table_rows) + table_rows).map((employee) => {
                            const completed_jobs = jobs.filter((job) => {
                                return job.status === "completed" && (job.users.findIndex((user) => user === employee._id) !== -1);
                            }).length;

                            const total_referrals = referrals.filter((referral) => {
                                return referral.technician === employee._id;
                            }).length;

                            const total_stars = reviews.filter((review) => {
                                return review.user === employee._id;
                            }).map((r) => r.star_rating || 0).reduce((prev_star_rating, curr_star_rating) => {
                                return prev_star_rating + curr_star_rating;
                            }, 0);

                            return (
                                <tr key={employee._id}>
                                    <td className="technician-td">
                                        <div onClick={() => {this.onPress_technician(employee)}} className="technician-table-info">
                                            {this.render_technician_image(employee.photo_url)}
                                            {`${employee.name.first} ${employee.name.last}`}
                                        </div>
                                    </td>

                                    <td className="statistics-td">
                                        <div className="statistics-info">
                                            {/* {(completed_jobs?(total_stars/completed_jobs):0).toFixed(1)} */} {/* <i id={"stars"+employee._id} className="fas fa-star" style={{color: "#f7bd4b"}} /> */}

                                            {window.innerWidth >= 900?<div id={"stars"+employee._id}>
                                            {this.render_star(1, (total_stars/completed_jobs)||0)}
                                            {this.render_star(2, (total_stars/completed_jobs)||0)}
                                            {this.render_star(3, (total_stars/completed_jobs)||0)}
                                            {this.render_star(4, (total_stars/completed_jobs)||0)}
                                            {this.render_star(5, (total_stars/completed_jobs)||0)}
                                            </div>:<>{((total_stars)||0).toFixed(1)} <i id={"stars"+employee._id} className="fas fa-star" style={{color: "#f7bd4b", marginRight: 5}} /></>}

                                            {window.innerWidth >= 900?<UncontrolledTooltip delay={0} target={"stars"+employee._id}>
                                                {((total_stars)||0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b"}} />
                                            </UncontrolledTooltip>:<UncontrolledTooltip delay={0} target={"stars"+employee._id}>
                                            {this.render_star(1, (total_stars/completed_jobs))}
                                            {this.render_star(2, (total_stars/completed_jobs))}
                                            {this.render_star(3, (total_stars/completed_jobs))}
                                            {this.render_star(4, (total_stars/completed_jobs))}
                                            {this.render_star(5, (total_stars/completed_jobs))}
                                            </UncontrolledTooltip>}
                                            
                                            {completed_jobs} <img id={"jobs"+employee._id} className="discover-spanner-icon" src="/assets/images/spanner_icon@2x.png" />{/* <i className="fas fa-wrench" style={{color: "#f7bd4b"}} /> */}

                                            <UncontrolledTooltip delay={0} target={"jobs"+employee._id}>
                                                {completed_jobs} Jobs
                                            </UncontrolledTooltip>
                                            
                                            {total_referrals} <img id={"refs"+employee._id} className="discover-curve-arrow-icon" src="/assets/images/curved_arrow@2x.png" /> {/* <i className="fas fa-redo-alt" style={{color: "#f7bd4b"}} /> */}

                                            <UncontrolledTooltip delay={0} target={"refs"+employee._id}>
                                                {total_referrals} Referals
                                            </UncontrolledTooltip>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div className="table-indexing-buttons-container">

                <div className="table-indexing-buttons">
                    <button onClick={this.onPress_decrement}><i className="fas fa-chevron-circle-left" /></button>
                    <button onClick={this.onPress_increment}><i className="fas fa-chevron-circle-right" /></button>
                </div>

            </div>
            </>
        );
    }
}

function map_state_to_props({user, employees, reviews, jobs, referrals}){
    return ({user, employees: employees.employees, reviews: reviews.reviews, jobs: jobs.data, referrals: referrals.referrals, table_index: employees.employee_table_index, table_rows: employees.employee_table_rows});
}
 
export default withRouter(connect(map_state_to_props, {discover_click_tecnician, increment_employee_table_index, decrement_employee_table_index, set_history_technician, set_history_jobs, set_history_reviews, set_history_completed_jobs_count, set_history_from_discover})(Profile_employees_column));