import {USER_EDIT_FORM_UPDATE, USER_EDIT_INIT_FORMS, SAVE_USER_EDIT, ADD_CITY, ADD_CATEGORY, ADD_STATE, REMOVE_STATE, REMOVE_CITY, REMOVE_CATEGORY, CANCEL_EDIT,} from "../actions/types";

const INITIAL_STATE =  {name: {first: "", last: ""}, comp_name: "", email: "", phone: "", photo_url: "", website: "", categories: [], cities: [], states: [], state:"", city: "", opening: "", closing: "", description: "", editing: false};

export default (state = INITIAL_STATE, {type, payload}) => {
    const new_state = {...state};

    switch(type){

        case USER_EDIT_INIT_FORMS:
            new_state.editing = true;
            new_state.name = payload.name;
            new_state.email = payload.email;
            new_state.phone = payload.phone;
            new_state.website = payload.website;
            new_state.opening = payload.opening;
            new_state.closing = payload.closing;
            new_state.photo_url = payload.photo_url;
            new_state.comp_name = payload.comp_name;
            new_state.description = payload.description;
            new_state.cities = payload.cities;
            new_state.categories = payload.categories;
            new_state.states=payload.states;
            break;
        
        case USER_EDIT_FORM_UPDATE:
            // alert(payload.value);
            if(payload.keys.length === 1){
                new_state[payload.keys[0]] = payload.value;
            }else if(payload.keys.length === 2){
                new_state[payload.keys[0]][payload.keys[1]] = payload.value;
            }
        break;

        case ADD_CITY:
            new_state.cities.push(payload);
        break;

        case ADD_CATEGORY:
            new_state.categories.push(payload);
        break;

        case ADD_STATE:
            new_state.states.push(payload);
        break;

        case REMOVE_CITY:
            new_state.cities.splice(payload.index, 1);
        break;

        case REMOVE_CATEGORY:
            new_state.categories.splice(payload.index, 1);
        break;
        
        case REMOVE_STATE:
            new_state.state.splice(payload.index, 1);
        break;
        case CANCEL_EDIT:
            return INITIAL_STATE;

        case SAVE_USER_EDIT:
            return INITIAL_STATE;

        default:
            return state;

    }

    return new_state;
}