import {JOB_CREATION_FORM_UPDATE, CREATE_JOB, CREATING_JOB, LOGOUT, CREATE_JOB_ERROR, STOP_CREATING_JOB, SHOW_JOB_CREATION_MODAL, DISMISS_JOB_CREATION_MODAL} from "../actions/types";

const INITIAL_STATE =  {finished: false, location: {name: "", lat: null, lon: null}, status: "", name: "", description: "", category: "", company: "", start_time: "", after_image_urls: [], loading: false, show_modal: false};

export default (state = INITIAL_STATE, {type, payload}) => {
    let new_state = {...state};

    switch(type){
        
        case JOB_CREATION_FORM_UPDATE:
            // alert(payload.value);
            new_state[payload.key] = payload.value;
        break;

        case SHOW_JOB_CREATION_MODAL:
            new_state.show_modal = true;
        break;

        case DISMISS_JOB_CREATION_MODAL:
            new_state.show_modal = false;
        break;

        case CREATE_JOB:
            new_state = {finished: true, status: payload, name:"", description:"", category:"",location:"", start_time:""}
            new_state.show_modal = false;
            new_state.loading = false;
            // console.log(payload);
        break;

        case CREATE_JOB_ERROR:
            new_state.loading = false;
        break;

        case CREATING_JOB:
            new_state.loading = true;
        break;

        case STOP_CREATING_JOB:
            new_state.loading = false;
        break;

        case LOGOUT:
            return INITIAL_STATE

        default:
            return state;

    }

    return new_state;
}