import React, { Component } from 'react';
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media
} from "reactstrap";
import {connect} from "react-redux"

import {unlink_company} from "../../actions"

import config from '../../config';

class Company_item extends Component {
    state = {  }

    onPress_unlink(){
        const {company} = this.props;
        this.props.unlink_company(company);
    }

    render_company_image(){
        const {photo_url} = this.props.company;
        if(photo_url){
          return(
                <a
                    className="avatar avatar-sm"
                    onClick={e => e.preventDefault()}
                >
                    <img
                        className="rounded-circle"
                        alt="company image"
                        src={`${config.real_backend_url}/uploads/images/${photo_url}`}
                    />
                </a>
          )
        }else{
            return(
                <a
                    className="avatar avatar-sm"
                    onClick={e => e.preventDefault()}
                >
                    <img
                        className="rounded-circle"
                        alt="company image"
                        src={require("assets/images/company_avatar.png")}
                    />
                </a>
            )
        }
    }

    render() {
        const {comp_name, email, phone, opening, closing} = this.props.company;
        return (
            <tr>
                <th scope="row">
                    <Media className="align-items-center">
                        <a
                        className="avatar rounded-circle mr-3"
                        onClick={e => e.preventDefault()}
                        >
                            {this.render_company_image()}
                        </a>
                        <Media>
                        <span className="mb-0 text-sm">
                            {comp_name}
                        </span>
                        </Media>
                    </Media>
                </th>

                <td>{email}</td>

                <td>{phone}</td>

                <td>{`${opening || "N/A"} - ${closing || "NA"}`}</td>

                <td className="text-right">
                    <UncontrolledDropdown>
                        <DropdownToggle
                        className="btn-icon-only text-light"
                        role="button"
                        size="sm"
                        color=""
                        onClick={e => e.preventDefault()}
                        >
                        <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                            onClick={this.onPress_unlink.bind(this)}
                        >
                            Unlink
                        </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </td>
            </tr>
        );
    }
}
 
export default connect(null, {unlink_company})(Company_item);