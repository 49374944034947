import {NAV_HEADER, BACK_BUTTON, SET_SOCKET, FETCHED_INFO, ADD_REQUEST, ADD_UPDATE, ADD_COMPANY_AND_UPDATE, DISCOVER_PRESSED, CLOSE_DISCOVER, EMPLOYMENT_UNLINK_UPDATE, EMPLOYMENT_TERMINATE_UPDATE, ADD_REFERRAL, UPDATE_REFERRAL, ADD_JOB, SET_ATTEMPTED_URL, OPEN_NAV, CLOSE_NAV,} from "./types";

const add_io_events = (socket, dispatch) => {
    socket.on("employment_request", function(request){
        dispatch({type: ADD_REQUEST, payload: request});
    });

    socket.on("employment_update", function(update){
        dispatch({type: ADD_UPDATE, payload: update});
    });

    socket.on("job_request_update", function(update){
        dispatch({type: ADD_UPDATE, payload: update});
    });
    
    socket.on("new_referral", function(referral){
        dispatch({type: ADD_REFERRAL, payload: referral});
    });

    socket.on("new job", function(new_job){
        dispatch({type: ADD_JOB, payload: new_job});
    });

    socket.on("employment unlink update", function(update, employment){
        dispatch({type: ADD_UPDATE, payload: update});

        dispatch({type: EMPLOYMENT_UNLINK_UPDATE, payload: employment});
    });

    socket.on("employment terminate update", function(update, employment){
        dispatch({type: ADD_UPDATE, payload: update});

        dispatch({type: EMPLOYMENT_TERMINATE_UPDATE, payload: employment});
    });

    socket.on("request accepted", function(data){
        dispatch({type: ADD_COMPANY_AND_UPDATE, payload: data});
    });
    
    socket.on("request rejected", function(update){
        dispatch({type: ADD_UPDATE, payload: update});
    });

    socket.on("job request accept", (update) => {
        dispatch({type: ADD_UPDATE, payload: update});
        dispatch({type: UPDATE_REFERRAL, payload: {referral_id: update.referral, accepted: true}});
    })

    socket.on("job request reject", (update) => {
        dispatch({type: ADD_UPDATE, payload: update});
        dispatch({type: UPDATE_REFERRAL, payload: {referral_id: update.referral, accepted: false}});
    })
}

export const change_nav_title = (title) => {
    return ({type: NAV_HEADER, payload: title});
}

export const toggle_back_button = (boool, press_back) => {
    return ({type: BACK_BUTTON, payload: {has_back: boool, press_back}});
}

export const fetched_info = () => {
    return ({type: FETCHED_INFO});
}

export const set_socket = (socket) => (dispatch) => {
    dispatch({type: SET_SOCKET, payload: socket});
    add_io_events(socket, dispatch);
    return;
}

export const discover_pressed = () => {
    return ({type: DISCOVER_PRESSED});
}

export const close_discover = () => {
    return ({type: CLOSE_DISCOVER});
}

export const set_attempted_url = (url) => {
    return ({type: SET_ATTEMPTED_URL, payload: url})
}

export const open_nav = () => {
    return ({type: OPEN_NAV});
}

export const close_nav = () => {
    return ({type: CLOSE_NAV});
}

