import React, { Component } from 'react';
import {connect} from "react-redux";
import RMT_header from "../RMT_header";

import Discover_search_item from "./discover_search_item";
import Discover_search_table_item from "./discover_search_table_item";

import States from "./states";
import Cities from "./cities";
import Categories from "./categories";

import {close_discover, discover_search, discover_form_update, discover_pressed as press_discover, increment_discover_table_index, decrement_discover_table_index, close_nav} from "../../actions";

class Discover_search extends Component {
    state = { searched: false, show_state_suggestion: false, show_city_suggestion: false, show_category_suggestion: false, state_selected: true, city_selected: true, category_selected: true, current_state_index: -1, current_city_index: -1, current_category_index: -1}

    constructor(props){
        super(props)

        this.onKeyDown_state = this.onKeyDown_state.bind(this);
        this.onKeyDown_city = this.onKeyDown_city.bind(this);
        this.onKeyDown_category = this.onKeyDown_category.bind(this);
    }

    componentDidMount(){
        if(!this.props.discover_pressed){
            this.props.press_discover();
        }

        this.props.close_nav();
    }

    match_states=[];
    match_cities=[];
    match_categories=[];

    onPress_search = () => {
        
        const {city, category, state} = this.props.discover;
        const {socket} = this.props;

        socket.emit("discover search");  

        this.props.discover_search(city, category, state);
        this.setState({searched: true});
    }

    onChange_state = ({target: {value}}) => {
        this.setState({state_selected: false, show_state_suggestion: true});
        this.props.discover_form_update("state", value);
    }

    onChange_city = ({target: {value}}) => {
        this.setState({city_selected: false, show_city_suggestion: true});
        this.props.discover_form_update("city", value);
    }
    
    onChange_category = ({target: {value}}) => {
        this.setState({category_selected: false, show_category_suggestion: true});
        this.props.discover_form_update("category", value);
    }

    onPress_close = () => {
        this.props.close_discover();
    }

    render_technicians(){
        const {technicians} = this.props.discover;
        const {table_index, table_rows} = this.props;
        const {searched} = this.state;
        if(technicians.length > 0){
        return (
        <>
            <div className="discover-results-technicians-table">
      
            <ul className="discover-search-list-wrapper">
                {technicians.slice(table_index*table_rows, (table_index*table_rows)+table_rows).map((technician, i) => {
                    return (
                        <Discover_search_table_item technician={technician} i={i} key={technician._id} />
                        );
                    })
                }
            </ul>

            
          </div>
        
         </>
        )
        }else{
            return <p style={{fontSize: 20}}>No technicians found which match your criteria.</p>
        }
    }

    render_state_suggestions(){
        const {state} = this.props.discover;
        const {show_state_suggestion, state_selected, current_state_index} = this.state;

        if(((state.length > 0 || show_state_suggestion) && state_selected===false) || show_state_suggestion){
            this.match_states = States.filter((s) => {
                return s.toUpperCase().includes(state.toUpperCase());
            });
            return this.match_states.map((st, i) => {
                return (
                    <p className={`suggestion ${i===current_state_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("state", st); this.setState({state_selected: true, show_state_suggestion: false});}} key={st+i}>{st}</p>
                );
            })
        }
      }

    render_city_suggestions(){
        const {city} = this.props.discover;
        const {show_city_suggestion, city_selected, current_city_index} = this.state;

        if(show_city_suggestion){
            this.match_cities = Cities.filter((c) => {
                return c.toUpperCase().includes(city.toUpperCase());
            });
            return this.match_cities.map((ci, i) => {
                return (
                    <p className={`suggestion ${i===current_city_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("city", ci); this.setState({city_selected: true, show_city_suggestion: false});}} key={ci+i}>{ci}</p>
                );
            })
        }
    }

    render_category_suggestions(){
        const {category} = this.props.discover;
        const {show_category_suggestion, category_selected, current_category_index} = this.state;

        if(show_category_suggestion){
            this.match_categories = Categories.filter((c) => {
                return c.label.toUpperCase().includes(category.toUpperCase());
            })
            return this.match_categories.map((ca, i) => {
                return (
                    <p className={`suggestion ${i===current_category_index?"suggestion-select":""}`} onClick={() => {this.props.discover_form_update("category", ca.label); this.setState({category_selected: true, show_category_suggestion: false});}} key={ca+i}>{ca.label}</p>
                );
            })
        }
    }

    onKeyDown_state(e){
        const {current_state_index} = this.state;

        if(e.which === 13 && current_state_index > -1){//enter
            this.props.discover_form_update("state", this.match_states[current_state_index]);
            this.setState({show_state_suggestion: !this.state.show_state_suggestion, state_selected: !this.state.state_selected});
        }else if(e.which === 38){//up
            if(current_state_index <= 0){
                this.setState({current_state_index: this.match_states.length-1});
            }else{
                this.setState({current_state_index: this.state.current_state_index-1});
            }
        }else if(e.which === 40){//down
            if(current_state_index >= this.match_states.length-1){
                this.setState({current_state_index: 0});
            }else{
                this.setState({current_state_index: this.state.current_state_index+1});
            }
        }
      }

    onKeyDown_city(e){
        const {current_city_index} = this.state;

        if(e.which === 13 && current_city_index > -1){//enter
            this.props.discover_form_update("city", this.match_cities[current_city_index]);
            this.setState({show_city_suggestion: !this.state.show_city_suggestion, city_selected: !this.state.city_selected});
        }else if(e.which === 38){//up
            if(current_city_index <= 0){
                this.setState({current_city_index: this.match_cities.length-1});
            }else{
                this.setState({current_city_index: this.state.current_city_index-1});
            }
        }else if(e.which === 40){//down
            if(current_city_index >= this.match_cities.length-1){
                this.setState({current_city_index: 0});
            }else{
                this.setState({current_city_index: this.state.current_city_index+1});
            }
        }
    }

    onKeyDown_category(e){
        const {current_category_index} = this.state;

        if(e.which === 13 && current_category_index > -1){//enter
            this.props.discover_form_update("category", this.match_categories[current_category_index].label);
            this.setState({show_category_suggestion: !this.state.show_category_suggestion, category_selected: !this.state.category_selected});
        }else if(e.which === 38){//up
            if(current_category_index <= 0){
                this.setState({current_category_index: this.match_categories.length-1});
            }else{
                this.setState({current_category_index: this.state.current_category_index-1});
            }
        }else if(e.which === 40){//down
            if(current_category_index >= this.match_categories.length-1){
                this.setState({current_category_index: 0});
            }else{
                this.setState({current_category_index: this.state.current_category_index+1});
            }
        }
    }

    onPress_discover = () => {
        const {category, city, state} = this.props.discover;
          this.props.socket.emit("discover_pressed");
          this.props.press_discover();
          this.props.discover_search(city, category, state);
      }

    onPress_increment = () => {
        this.props.increment_discover_table_index();
    }

    onPress_decrement = () => {
        this.props.decrement_discover_table_index();
    }

    render() { 
        const {discover_pressed} = this.props;
        const {city, category, state} = this.props.discover;
        const {show_state_suggestion, show_category_suggestion, show_city_suggestion, state_selected, city_selected, category_selected} = this.state;
        return (
            <>
            <RMT_header />

            <div className={(discover_pressed?"discover-fade-in":"discover-fade-out")+" col-md-12"} style={{position: "absolute", zIndex: 0, width: "100%", padding: 0,paddingTop: 72, marginLeft: "auto", marginRight: "auto"}}>
                 {/* <div className="input-group right">
                    <button onClick={this.onPress_close} className="btn btn-danger mb-3">close</button>
                </div> */}
                {/*
                <div className="input-group mb3">
                    <div className="mr-3">
                    <span className="form-control" style={{display: "inline-block", paddingRight: 10}}>
                        <input autoComplete="off" onFocus={() => {this.setState({state_selected: false, show_state_suggestion: true})}} onKeyDown={this.onKeyDown_state} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} / onChange={this.onChange_state} value={state} type="text" name="state" placeholder="State" className="" style={{border: "none", padding: 0}} ref={(input) => {this.state_input = input}}  />
                        
                        <i onClick={() => {
                            if(!show_state_suggestion){
                                this.state_input.focus();
                            }
                            this.setState({show_state_suggestion: !this.state.show_state_suggestion/* , state_selected: !this.state.state_selected /});
                        }} style={{verticalAlign: "sub", cursor: "pointer"}} className={`ni ni-bold-${show_state_suggestion/*  || state_selected===false /?"up":"down"} text-muted`} />
                    </span>

                        <div className="suggestion-container-hide-scrollbar"><div className="suggestion-container">{this.render_state_suggestions()}</div></div>
                    </div>
                    <div className="mr-3 ml-3">
                    <span className="form-control" style={{display: "inline-block", paddingRight: 10}}>
                        <input autoComplete="off" onFocus={() => {this.setState({city_selected: false, show_city_suggestion: true})}} onKeyDown={this.onKeyDown_city} /* onBlur={() => {this.setState({city_selected: true, show_city_suggestion: false})}} / onChange={this.onChange_city} value={city} type="text" name="city" placeholder="city" className="" style={{border: "none", padding: 0}} ref={(input) => {this.city_input = input}}  />
                        
                        <i onClick={() => {
                            if(!show_city_suggestion){
                                this.city_input.focus();
                            }
                            this.setState({show_city_suggestion: !this.state.show_city_suggestion/* , city_selected: !this.state.city_selected /});
                        }} style={{verticalAlign: "sub", cursor: "pointer"}} className={`ni ni-bold-${show_city_suggestion/*  || city_selected===false /?"up":"down"} text-muted`} />
                    </span>

                        <div className="suggestion-container-hide-scrollbar"><div className="suggestion-container">{this.render_city_suggestions()}</div></div>
                    </div>
                    <div className="ml-3">
                    <span className="form-control" style={{display: "inline-block", paddingRight: 10}}>
                        <input autoComplete="off" onFocus={() => {this.setState({category_selected: false, show_category_suggestion: true})}} onKeyDown={this.onKeyDown_category} /* onBlur={() => {this.setState({category_selected: true, show_category_suggestion: false})}} / onChange={this.onChange_category} value={category} type="text" name="category" placeholder="category" className="" style={{border: "none", padding: 0}} ref={(input) => {this.category_input = input}} />
                        
                        <i onClick={() => {
                            if(!show_category_suggestion){
                                this.category_input.focus();
                            }
                            this.setState({show_category_suggestion: !this.state.show_category_suggestion/* , category_selected: !this.state.category_selected /});
                        }} style={{verticalAlign: "sub", cursor: "pointer"}} className={`ni ni-bold-${show_category_suggestion/*  || category_selected===false /?"up":"down"} text-muted`} />
                    </span>
                    <div className="suggestion-container-hide-scrollbar"><div className="suggestion-container">{this.render_category_suggestions()}</div></div>
                    </div>
                </div>
                <div className="input-group" style={{textAlign: "center"}}>
                    <button className="button button-discover" onClick={this.onPress_search}>SEARCH</button>
                </div> */}
                <div className="discover-background-container">
                    <div className="discover-background"></div>
                    <div className="discover-background-overlay-container">
                    <div className="discover-background-overlay">
                        <h1>Discover <span className="techs-keyword">HairStylists.</span></h1>
                        <h3>Want to filter by technicians in your area? Enter your City below</h3>

                        {/* <span className="discover-input" style={{display: "inline-block"}}>
                            <span className="discover-search-input-wrapper">
                                <input autoComplete="off" onFocus={() => {this.setState({city_selected: false, show_city_suggestion: true})}} onKeyDown={this.onKeyDown_city} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}}  onChange={this.onChange_city} /* value={state}  type="text" name="city" placeholder="City" className="" ref={(input) => {this.city_input = input}} value={city}  />
                                
                                <i onClick={() => {
                                    if(!show_city_suggestion){
                                        this.city_input.focus();
                                    }
                                    this.setState({show_city_suggestion: !this.state.show_city_suggestion/* , state_selected: !this.state.state_selected });
                                }} style={{verticalAlign: "sub", cursor: "pointer", backgroundColor: "white", height: "100%", display: "inline-block", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_city_suggestion/*  || state_selected===false ?"up":"down"}`} />
                            </span>

                            <span className="discover-search-button-wrapper">
                            <button className="discover-search-button" onClick={this.onPress_discover}>NARROW SEARCH</button>
                            </span>

                            <div className="suggestion-container-hide-scrollbar"><div className="suggestion-container">{this.render_city_suggestions()}</div></div>

                        </span> */}

                        <span className="discover-input">
                            <span className="discover-search-input-wrapper">
                                <input autoComplete="off" onFocus={() => {this.setState({category_selected: false, show_category_suggestion: true, show_city_suggestion: false,show_state_suggestion:false,state_selected:false}); this.category_input.setSelectionRange(0, this.category_input.value.length)}} onKeyDown={this.onKeyDown_category} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} */ onChange={this.onChange_category} /* value={state} */ type="text" name="category" placeholder="All Categories" className="" ref={(input) => {this.category_input = input}} value={category}  />
                                
                                <i onClick={() => {
                                    if(!show_category_suggestion){
                                        this.category_input.focus();
                                    }
                                    if(!category_selected){
                                        this.category_input.value = "";
                                    }
                                    this.setState({show_category_suggestion: !this.state.show_category_suggestion, show_city_suggestion: false});
                                }} style={{verticalAlign: "sub", cursor: "pointer", backgroundColor: "white", height: "100%", display: "inline-block", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_category_suggestion/*  || state_selected===false */?"up":"down"}`} />
                            </span>

                            <span onClick={this.onPress_discover} className="discover-search-button-wrapper">
                                <button style={{color: "#FFFFFF"}} className="discover-search-button" onClick={this.onPress_discover}>SEARCH</button>
                            </span>
                            
                            <span className="discover-search-input-wrapper">
                                <input autoComplete="off" onFocus={() => {this.setState({city_selected: false, show_city_suggestion: true, show_category_suggestion: false}); this.city_input.setSelectionRange(0, this.city_input.value.length)}} onKeyDown={this.onKeyDown_city} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} */ onChange={this.onChange_city} /* value={state} */ type="text" name="city" placeholder="City" className="" ref={(input) => {this.city_input = input}} value={city}  />
                                
                                <i onClick={() => {
                                    if(!show_city_suggestion){
                                        this.city_input.focus();
                                    }
                                    if(!city_selected){
                                        this.city_input.value = "";
                                    }
                                    this.setState({show_city_suggestion: !this.state.show_city_suggestion, show_category_suggestion: false});
                                }} style={{verticalAlign: "sub", cursor: "pointer", backgroundColor: "white", height: "100%", display: "inline-block", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_city_suggestion/*  || state_selected===false */?"up":"down"}`} />
                            </span>
                            <span className="discover-search-input-wrapper">
                                <input autoComplete="off" onFocus={() => {this.setState({state_selected: false, show_state_suggestion: true, }); this.state_input.setSelectionRange(0, this.state_input.value.length)}} onKeyDown={this.onKeyDown_state} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} */ onChange={this.onChange_state} /* value={state} */ type="text" name="state" placeholder="State" className="" ref={(input) => {this.state_input = input}} value={state}  />
                                
                                <i onClick={() => {
                                    if(!show_state_suggestion){
                                        this.state_input.focus();
                                    }
                                    if(!state_selected){
                                        this.state_input.value = "";
                                    }
                                    this.setState({show_state_suggestion: !this.state.show_state_suggestion, });
                                }} style={{verticalAlign: "sub", cursor: "pointer", backgroundColor: "white", height: "100%", display: "inline-block", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_state_suggestion/*  || state_selected===false */?"up":"down"}`} />
                            </span>
                            <div className="suggestion-container-hide-scrollbar"><div className={"suggestion-container " + (show_city_suggestion?"suggestion-open":"suggestion-close")}>{this.render_city_suggestions()}</div></div>
                            <div className="suggestion-container-hide-scrollbar"><div className={"suggestion-container " + (show_state_suggestion?"suggestion-open":"suggestion-close")}>{this.render_state_suggestions()}</div></div>
                            <div className="suggestion-container-hide-scrollbar"><div className={"suggestion-container " + (show_category_suggestion?"suggestion-open":"suggestion-close")}>{this.render_category_suggestions()}</div></div>

                        </span>

                    <div className="discover-search-mobile">

                        <div className="discover-search-input-wrapper-mobile">
                            <input autoComplete="off" onFocus={() => {this.setState({category_selected: false, show_category_suggestion: true, show_city_suggestion: false}); this.mobile_category_input.setSelectionRange(0, this.mobile_category_input.value.length)}} onKeyDown={this.onKeyDown_category} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} */ onChange={this.onChange_category} /* value={state} */ type="text" name="category" placeholder="All Categories" className="" ref={(input) => {this.mobile_category_input = input}} value={category} />

                            <i onClick={() => {
                                if(!show_category_suggestion){
                                    this.mobile_category_input.focus();
                                }
                                if(!category_selected){
                                    this.mobile_category_input.value = "";
                                }
                                this.setState({show_category_suggestion: !this.state.show_category_suggestion, show_city_suggestion: false});
                            }} style={{verticalAlign: "sub", cursor: "pointer", height: "100%", display: "inline-block", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_category_suggestion/*  || state_selected===false */?"up":"down"}`} />
                        </div>

                        <div className="discover-search-input-wrapper-mobile">
                            <input autoComplete="off" onFocus={() => {this.setState({city_selected: false, show_city_suggestion: true, show_category_suggestion: false}); this.mobile_city_input.setSelectionRange(0, this.mobile_city_input.value.length)}} onKeyDown={this.onKeyDown_city} /* onBlur={() => {this.setState({state_selected: true, show_state_suggestion: false})}} */ onChange={this.onChange_city} /* value={state} */ type="text" name="city" placeholder="City" className="" ref={(input) => {this.mobile_city_input = input}} value={city} />

                            <i onClick={() => {
                                if(!show_city_suggestion){
                                    this.mobile_city_input.focus();
                                }
                                if(!city_selected){
                                    this.mobile_city_input.value = "";
                                }
                                this.setState({show_city_suggestion: !this.state.show_city_suggestion, show_category_suggestion: false});
                            }} style={{verticalAlign: "sub", cursor: "pointer", height: "100%", display: "inline-block", color: "#1a3279", fontSize: 30}} className={`fas fa-caret-${show_city_suggestion/*  || state_selected===false */?"up":"down"}`} />
                        </div>

                        <div className="suggestion-container-hide-scrollbar"><div className={"suggestion-container " + (show_city_suggestion?"suggestion-open":"suggestion-close")}>{this.render_city_suggestions()}</div></div>

                        <div className="suggestion-container-hide-scrollbar"><div className={"suggestion-container " + (show_category_suggestion?"suggestion-open":"suggestion-close")}>{this.render_category_suggestions()}</div></div>
                        
                        <div style={{backgroundColor: "#FFBE43"}} className="discover-search-mobile-button-wrapper" onClick={this.onPress_discover}>
                            <button style={{backgroundColor: "#FFFFFF"}} onClick={this.onPress_discover} className="discover-search-mobile-button"><i className="fas fa-search" />SEARCH</button>
                        </div>
                    </div>

                    </div>
                    </div>
                </div>

                <ul className="technicians-list">
                    {this.render_technicians()}
                </ul>

                <div className="table-indexing-buttons-container">

                    <div className="table-indexing-buttons">
                        <button onClick={this.onPress_decrement}><i className="fas fa-chevron-circle-left" /></button>
                        <button onClick={this.onPress_increment}><i className="fas fa-chevron-circle-right" /></button>
                    </div>

                </div>
            </div>

            

            <p style={{textAlign: "center"}}>Web page build by cenedex.com</p>
            </>
        );
    }
}
 
function map_state_to_props({app, discover}){
    return ({discover_pressed: app.discover_pressed, discover, socket: app.socket, table_index: discover.table_index, table_rows: discover.table_rows})
}

export default connect(map_state_to_props, {close_discover, discover_search, discover_form_update, press_discover, increment_discover_table_index, decrement_discover_table_index, close_nav})(Discover_search);