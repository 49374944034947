// assumption: A valid name should only contain alpha characters

const regex = /([0-9]|[^a-z A-Z])/g;

export default (name = "") => {
    if(!regex.test(name)){
        return true;
    }else{
        return false;
    }
}