import React from "react";
import {connect} from "react-redux";
import Update from "./update";

import Header from "components/Headers/Header.jsx";

class Technician_dashboard extends React.Component {
  render() {
    const {updates} = this.props;
    return (
      <>
        {/* <Header /> */}
        {/* Page content */}
        
        <div className="jobs-container" style={{paddingTop: 78}}>
          <ul>
          {updates.map((update, i, updates_arr) => {
            if(i % 2 == 0){
              return (
                <li>
                  <div className="jobs-row">
                    <Update update={update} />
                    {((updates_arr.length - 1) >= (i + 1))?<Update update={updates_arr[i+1]} />:null}
                  </div>
                </li>
              );
            }else{
              return null;
            }
          })}
          </ul>
        </div>

      </>
    );
  }
}

function map_state_to_props({updates}) {
    return ({updates: updates.updates});
}

export default connect(map_state_to_props)(Technician_dashboard);
