import React, { Component } from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {process_input, switch_form_view, login_or_signup, fetch_user, fetch_companies, fetch_jobs, fetch_company_jobs, fetch_employees, fetch_requests, fetch_reviews, fetch_updates, fetch_pending_employees, fetch_referrals, close_nav} from "../../actions";

import "./login.css"

class Login extends Component {
    state = { err:false, show_password: false}

    constructor(props){
        super(props);

        this.onChange_email_phone = this.onChange_email_phone.bind(this);
        this.onChange_password = this.onChange_password.bind(this);
        this.onPress_login = this.onPress_login.bind(this);

    }
    
    componentDidMount(){
        this.props.switch_form_view("l")
        this.email_phone.focus();
        this.props.close_nav();
    }

    onChange_email_phone(e){
        this.props.process_input("email_phone", e.target.value);
    }

    onChange_password(e){
        this.props.process_input("login_password", e.target.value);
    }

    async onPress_login(e){
      e.preventDefault()
      const res = await this.props.login_or_signup({...this.props.auth, login_or_signup: "l"});
      if(res){
          await this.props.fetch_user();
if(this.props.type==="tech" || this.props.type === "comp"){
          this.props.fetch_updates();
          this.props.fetch_reviews();
          this.props.fetch_requests();
          this.props.fetch_referrals();
        
} else if (this.props.type === "cust"){
    this.props.fetch_updates();
}
          // this.props.socket.emit("add client", this.props.email);
          if(this.props.role !== "sudo admin"){
              if(this.props.type === "tech"){
                  this.props.fetch_jobs();
                  this.props.fetch_companies();
              }else if(this.props.type === "comp"){
                  this.props.fetch_company_jobs();
                  this.props.fetch_employees();
                  this.props.fetch_pending_employees();
              }
          }
          this.props.history.push("/admin/user-profile");
      }else{
          this.setState({err: true})
      }
  }

    render_err(){
        if(this.state.err){
            return (
                <p style={{color: "red"}}>There was a problem logging in.</p>
            )
        }
    }

    show_password_timeout = null;
    onPress_show_password = () => {
        const {show_password} = this.state;
        this.setState({show_password: !show_password});
        if(!show_password){
            this.show_password_timeout = setTimeout(()=>{this.setState({show_password: false})}, 20000);
        }else{
            clearTimeout(this.show_password_timeout);
        }
    }

    render() { 
        const {email_phone, login_password, login_error_msg} = this.props.auth;
        const {show_password} = this.state;
        return (
            <div className="login-container">

                <div className="login-background">

                    <div className="login-foreground">

                        <div className="login-foreground-text">
                            <h1> <span style={{color:"black"}}>Take</span> your hair care</h1>
                            <h1>To the next level!</h1>
                            <h3>Get discovered by nationwide employers, explore your numerous opportunities and build your career in quick and easy steps</h3>
                        </div>
 </div>
 </div>
 <div className="login-wrapper">
                        <div className="login-foreground-inputs">
                            <div className="login-card">
                                {login_error_msg?<p style={{fontSize: 14, color: "red"}}>{login_error_msg}</p>:null}
                                <div className="login-inputs-container">
                                    <div className="email-input-wrapper">
                                        <label htmlFor="login-email">Email</label>
                                        
                                        <div className="auth-input-container">
                                            <i className="far fa-envelope" />
                                            
                                            <input ref={(input) => {this.email_phone = input}} id="login-email" type="text" name="email" value={email_phone} onChange={this.onChange_email_phone} />
                                        </div>
                                    </div>
                                    
                                    <div className="password-input-wrapper">
                                        <label htmlFor="login-password">Password</label>

                                        <div className="auth-input-container">
                                            <i className="fas fa-lock" />
                                            
                                            <input id="login-password" type={show_password?"text":"password"} name="password" value={login_password} onChange={this.onChange_password} />

                                            <i className={`far ${show_password?"fa-eye-slash":"fa-eye"}`} style={{left: "unset", right: 20, cursor: "pointer"}} onClick={this.onPress_show_password} />
                                        </div>

                                        <small style={{display: "block", textAlign: "right"}}>Forgot your password?</small>
                                    </div>

                                    <div className="login-button-container">
                                        <button onClick={this.onPress_login}>LOGIN</button>
                                        <small style={{display: "block"}}>Don't have an account? <Link to="/auth/register">register now</Link></small>
                                    </div>
                                </div>
                            </div>
                        </div>
</div>
                    

               
                
            </div>
        );
    }
}

function map_state_to_props({auth, user}){
    return {auth, type: user.type, role: user.role};
  }
  
export default connect(map_state_to_props, {process_input, switch_form_view, login_or_signup, fetch_user, fetch_companies, fetch_jobs, fetch_company_jobs, fetch_employees, fetch_requests, fetch_reviews, fetch_updates, fetch_pending_employees, fetch_referrals, close_nav})(Login);