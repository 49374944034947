import React, { Component } from 'react';
import {connect} from "react-redux";
import  {Link} from "react-router-dom";

import {Row} from "reactstrap"

import {login_or_signup, is_email_verified, existing_email, choose_account_type, verify_pass, verify_firstname, verify_lastname, verify_phone, verify_website_url, verify_birth, invalid_field, process_input, add_category_sign_up, add_city_sign_up, remove_category_sign_up, remove_city_sign_up, close_nav, add_state_sign_up, remove_state_sign_up, upload_profile_image, customer_photo_signup} from "../../actions";

import Search_list from "../Search_list";
import config from "../../config"; 
import Cities from "../index/cities"
import Categories from "../index/categories"
import States from "../index/states"

import "./register.css"

class Register extends Component {
    state = { show_new_password: false, show_confirm_password: false, tech: false, comp: false, cust: false, account_type_err: "", accept_policy: false, done_intial_sign_up: false }

    show_new_password_timeout = null;
    show_confirm_password_timeout = null;

    componentDidMount(){
    //     const {photo_url} = this.props.auth;

    // this.props.customer_photo_signup({photo_url});
        this.props.close_nav();
    }

    async onPress_register(){
        if(!this.state.tech && !this.state.comp && !this.state.cust){
            this.setState({account_type_err: "Please choose an account type to continue!"});
            return;
        }

        if((this.props.auth.email !== "") && (this.props.auth.phone !== "") && (this.props.auth.signup_password !== "") && (this.props.auth.signup_password === this.props.auth.signup_confirm_password) && (this.props.auth.agree_to_policy === true)){
            if(this.props.verify_pass(this.props.auth.signup_password) && this.props.verify_phone(this.props.auth.phone)){
                if((await this.props.existing_email(this.props.auth.email)) === false){
                    // this.props.navigation.navigate(this.props.sign_up_type);
                    this.setState({done_intial_sign_up: true});
                }
            }
        }else{
            if(this.props.auth.signup_password !== this.props.auth.signup_confirm_password){
                this.props.invalid_field("confirm_password");
                
                return;
            }else if(!this.props.auth.agree_to_policy){
                alert("You must agree to our Data & Privacy policy to continue!");
                
                return;
            }
            alert("all fields must be filled")
        }
    }

    async onPress_signup(){
        const {type, name: {first, last}, birth, website} = this.props.auth;

        if(type === "tech"){
            if((await this.props.verify_birth(birth)) && (await this.props.verify_firstname(first)) && (await this.props.verify_lastname(last))){
                const res = await this.props.login_or_signup({...this.props.auth, login_or_signup: "s"});

                if(res){
                    alert("successfull signed up. please confirm your email.");
                }
            }
        }
        else if (type === "cust"){
            if((await this.props.verify_birth(birth)) && (await this.props.verify_firstname(first)) && (await this.props.verify_lastname(last))){
                const res = await this.props.login_or_signup({...this.props.auth, login_or_signup: "s"});

                if(res){
                    alert("successfull signed up. please confirm your email.");
                }
            }
        }
        else if (type === "comp"){
            if(await this.props.verify_website_url(website)){
                const res = await this.props.login_or_signup({...this.props.auth, login_or_signup: "s"});

                if(res){
                    alert("successfull signed up. please confirm your email.");
                }
            }
        }
    }

    onChange_email = ({target: {value: new_text}}) => {
        this.props.process_input("email", new_text);
    }

    onChange_phone = ({target: {value: new_text}}) => {
        this.props.process_input("phone", new_text);
    }

    onChange_password = ({target: {value: new_text}}) =>{
        this.props.process_input("signup_password", new_text);
    }
    
    onChange_confirm_password = ({target: {value: new_text}}) =>{
        this.props.process_input("signup_confirm_password", new_text);
    }

    onChange_firstname = ({target: {value: new_text}}) => {
        this.props.process_input("firstname", new_text);
    }

    onChange_lastname = ({target: {value: new_text}}) => {
        this.props.process_input("lastname", new_text);
    }
    
    onChange_birth = ({target: {value: new_text}}) =>{
        this.props.process_input("birth", new_text);
    }

    on_change_description = ({target: {value: new_text}}) => {
        this.props.process_input("description", new_text);
    }

    onAdd_city = (city) => {
        this.props.add_city_sign_up(city);
    }

    onAdd_category = (category) => {
        this.props.add_category_sign_up(category);
    }
    onAdd_state = (state) => {
        this.props.add_state_sign_up(state);
    }
    onRemove_state = (index, state) => {
        this.props.remove_state_sign_up(index, state);
    }

    onRemove_city = (index, city) => {
        this.props.remove_city_sign_up(index, city);
    }

    onRemove_category = (index, category) => {
        this.props.remove_category_sign_up(index, category);
    }

    onChange_name = ({target: {value: new_text}}) => {
        this.props.process_input("comp_name", new_text);
    }

    onChange_address = ({target: {value: new_text}}) => {
        this.props.process_input("address", new_text);
    }

    onChange_url = ({target: {value: new_text}}) => {
        this.props.process_input("website", new_text);
    }

    onChange_opening = ({target: {value: new_text}}) => {
        this.props.process_input("opening", new_text);
    }

    onChange_closing = ({target: {value: new_text}}) => {
        this.props.process_input("closing", new_text);
    }

    onToggle_data_privacy = () => {
        this.setState({accept_policy: !this.state.accept_policy}, () => {
            this.props.process_input("agree_to_policy", this.state.accept_policy);
        })
    }

    onPress_data_privacy = () => {
        this.setState({show_data_privacy: true});
    }

    onPress_close_data_privacy = () => {
        this.setState({show_data_privacy: false});
    }

    onPress_technician = () => {
        this.props.choose_account_type("tech");
        this.setState({tech: true, comp: false, cust: false });
    }

    onPress_company = () => {
        this.props.choose_account_type("comp");
        this.setState({comp: true, tech: false, cust: false });
    }
    onPress_customer = () => {
        this.props.choose_account_type("cust");
        this.setState({cust: true, tech: false, comp: false});
    }
    onPress_show_new_password = () => {
        const {show_new_password} = this.state;
        this.setState({show_new_password: !show_new_password});
        if(!show_new_password){
            this.show_new_password_timeout = setTimeout(()=>{this.setState({show_new_password: false})}, 20000);
        }else{
            clearTimeout(this.show_new_password_timeout);
        }
    }
    
    onPress_show_confirm_password = () => {
        const {show_confirm_password} = this.state;
        this.setState({show_confirm_password: !show_confirm_password});
        if(!show_confirm_password){
            this.show_confirm_password_timeout = setTimeout(()=>{this.setState({show_confirm_password: false})}, 20000);
        }else{
            clearTimeout(this.show_confirm_password_timeout);
        }
    }
    onChange_profile_image = () => {

        let photoform = {};
        photoform = this.file_input;
    
        // const data = await customer_photo_signup("profile_img", e.target.value, photoform);
        
        // console.log(data);
        // this.props.upload_profile_image(photoform);
      }
      render_profile_image(){
        const {photo_url} = this.props.auth;
        
        if(photo_url){
          return(
            <img
              alt="..."
              className="img-circle rounded-circle img-thumbnail"
              style={{objectFit: "cover", width: 180, height: 180}}
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                alt="..."
                className="img-circle rounded-circle img-thumbnail"
                style={{objectFit: "cover", width: 180, height: 180}}
                src={require("../../assets/images/Bitmap.png")}
              />
            )
        }
    }

    onPress_image = (e) => {
        e.preventDefault();
        this.file_input.click();
      }

    render_tech_signup(){
        const {name, birth, description, cities, categories, states, firstname_validation_err, lastname_validation_err, birth_validation_err} = this.props.auth
        return (
            <div className="register-inputs-container extra">

            <div className="name-input-wrapper">
                    <div className="input-label-wrapper">
                        <label>First Name</label>
                        <input id="first-name-inpuut" type="text" value={name.first} onChange={this.onChange_firstname} />

                        {firstname_validation_err?<p className="signup-error">{firstname_validation_err}</p>:null}
                    </div>

                    <div className="input-label-wrapper">
                        <label>Last Name</label>
                        <input id="last-name-inpuut" type="text" value={name.last} onChange={this.onChange_lastname} />

                        {lastname_validation_err?<p className="signup-error">{lastname_validation_err}</p>:null}
                    </div>
            </div>
            
            <div className="email-input-wrapper">
                <label htmlFor="register-description">Description</label>
                <div className="auth-input-container">
                    <i className="fas fa-book" />

                    <input id="register-description" type="textarea" name="description" onChange={this.on_change_description} value={description} />
                </div>
            </div>
            
            <div className="email-input-wrapper">
                <label htmlFor="register-birth">Birth Date</label>
                <div className="auth-input-container">
                    <i class="far fa-calendar-alt" />

                    <input id="register-birth" type="date" name="birth" onChange={this.onChange_birth} value={birth} />

                    {birth_validation_err?<p className="signup-error">{birth_validation_err}</p>:null}
                </div>
                
            </div>
            
            <div className="state-input-wrapper">
                <label>States</label>
                <div className="auth-input-container">
            {console.log(states)}
                    <Search_list results_style={{maxWidth: 300}} style={{height: 50, backgroundColor: "white", borderRadius: 0, border: "1px solid #ccc", boxShadow: "1px 1px 5px #ddd"}} placeholder="Serving States" items={states} search_array={States} add_func={this.onAdd_state} remove_func={this.onRemove_state} />
                    
                </div>
            </div>
            <div className="city-input-wrapper">
                <label>Cities</label>
                <div className="auth-input-container">
            
                    <Search_list results_style={{maxWidth: 300}} style={{height: 50, backgroundColor: "white", borderRadius: 0, border: "1px solid #ccc", boxShadow: "1px 1px 5px #ddd"}} placeholder="Serving Cities" items={cities} search_array={Cities} add_func={this.onAdd_city} remove_func={this.onRemove_city} />
                    
                </div>
            </div>
            
            <div className="category-input-wrapper">
                <label>Categories</label>

                <div className="auth-input-container">
            
                    <Search_list results_style={{maxWidth: 300}} style={{height: 50, backgroundColor: "white", borderRadius: 0, border: "1px solid #ccc", boxShadow: "1px 1px 5px #ddd"}} placeholder="Serving Categories" items={categories} search_array={Categories} search_property="label" add_func={this.onAdd_category} remove_func={this.onRemove_category} />
                    
                </div>
            </div>

            <div className="register-button-container">
                <button onClick={this.onPress_signup.bind(this)}>Sign Up</button>
            </div>
        </div>  
        );
    }
    render_customer_signup(){
        const {name, birth,  firstname_validation_err, lastname_validation_err, birth_validation_err} = this.props.auth
        return (
            <div className="register-inputs-container extra">
<div className="signup-photo-image">
                      {/* <a  onClick={this.onPress_image}> */}
                        {this.render_profile_image()}
                      {/* </a> */}
{/* 
                      <form id="photo_form" action="/api/user/upload/reg_avatar">
                          <input onChange={this.onChange_profile_image}  style={{display: "none"}} type="file" accept="image/*" id="profile_image_select" ref={(file_input) => {this.file_input = file_input}} name="photo_url" /></form> */}
                    </div>
            <div className="name-input-wrapper">
            
                    <div className="input-label-wrapper">
                        <label>First Name</label>
                        <input id="first-name-inpuut" type="text" value={name.first} onChange={this.onChange_firstname} />

                        {firstname_validation_err?<p className="signup-error">{firstname_validation_err}</p>:null}
                    </div>

                    <div className="input-label-wrapper">
                        <label>Last Name</label>
                        <input id="last-name-inpuut" type="text" value={name.last} onChange={this.onChange_lastname} />

                        {lastname_validation_err?<p className="signup-error">{lastname_validation_err}</p>:null}
                    </div>
            </div>            
            <div className="email-input-wrapper">
                <label htmlFor="register-birth">Birth Date</label>
                <div className="auth-input-container">
                    <i class="far fa-calendar-alt" />

                    <input id="register-birth" type="date" name="birth" onChange={this.onChange_birth} value={birth} />

                    {birth_validation_err?<p className="signup-error">{birth_validation_err}</p>:null}
                </div>
                
            </div>
            <div className="register-button-container">
                <button onClick={this.onPress_signup.bind(this)}>Sign Up</button>
            </div>
        </div>  
        );
    }
    render_comp_signup(){
        const {comp_name, address, website, description, opening, closing, website_validation_err} = this.props.auth;
        return (
            <div className="register-inputs-container extra">
            
            <div className="email-input-wrapper">
                <label htmlFor="register-comp_name">Company Name</label>
                <div className="auth-input-container">
                    <i className="far fa-building" />

                    <input id="register-comp-name" type="text" name="comp-name" onChange={this.onChange_name} value={comp_name} />
                </div>
            </div>
            
            <div className="email-input-wrapper">
                <label htmlFor="register-address">Company Address</label>
                <div className="auth-input-container">
                    <i className="fas fa-location-arrow" />

                    <input id="register-address" type="text" name="address" onChange={this.onChange_address} value={address} />
                </div>
            </div>
            
            <div className="email-input-wrapper">
                <label htmlFor="register-website">Company Website</label>
                <div className="auth-input-container">
                    <i className="fas fa-globe" />

                    <input id="register-website" type="text" name="website" onChange={this.onChange_url} value={website} />

                    {website_validation_err?<p className="signup-error">{website_validation_err}</p>:null}
                </div>
            </div>
            
            <div className="email-input-wrapper">
                <label htmlFor="register-description">Description</label>
                <div className="auth-input-container">
                    <i className="fas fa-book" />

                    <input id="register-description" type="textarea" name="description" onChange={this.on_change_description} value={description} />
                </div>
            </div>

            <div className="name-input-wrapper">
                    <div className="input-label-wrapper">
                        <label>Opening</label>
                        <input id="opening-input" type="text" value={opening} onChange={this.onChange_opening} placeholder="_ _: _ _ AM" />
                    </div>

                    <div className="input-label-wrapper">
                        <label>Closing</label>
                        <input id="clost-input" type="text" value={closing} onChange={this.onChange_closing} placeholder="_ _: _ _ PM" />
                    </div>
            </div>

            <div className="register-button-container">
                <button onClick={this.onPress_signup.bind(this)}>Sign Up</button>
            </div>
        </div>  
        );
    }

    render_extra_signup_forms(){
        if(this.state.done_intial_sign_up && this.props.auth.type === "tech"){
            return this.render_tech_signup();
        }else if(this.state.done_intial_sign_up && this.props.auth.type === "comp"){
            return this.render_comp_signup();
        }
        else if(this.state.done_intial_sign_up && this.props.auth.type === "cust"){
            return this.render_customer_signup();

        }
    }

    render() { 
        const {show_confirm_password, show_new_password, tech, comp, cust, account_type_err, done_intial_sign_up} = this.state;

        const {email, phone, signup_password, signup_confirm_password, agree_to_policy, email_validation_err, phone_validation_err, pass_validation_err, confirm_pass_validation_err} = this.props.auth;
        return (
            <div className="register-container">
  <div className="register-foreground-inputs">
                            <div className="register-card">
                                <div className={"register-inputs-container" + (done_intial_sign_up?" complete":"")}>

                                    {/* <div className="name-input-wrapper">
                                            <div className="input-label-wrapper">
                                                <label>First Name</label>
                                                <input id="first-name-inpuut" type="text" />
                                            </div>

                                            <div className="input-label-wrapper">
                                                <label>First Name</label>
                                                <input id="last-name-inpuut" type="text" />
                                            </div>
                                    </div> */}

                                    <div className="email-input-wrapper">
                                        <label htmlFor="register-email">Email</label>
                                        <div className="auth-input-container">
                                            <i className="far fa-envelope" />

                                            <input autoComplete="new-username" id="register-email" type="text" name="email" onChange={this.onChange_email} value={email} />

                                            {email_validation_err?<p className="signup-error">{email_validation_err}</p>:null}
                                        </div>
                                    </div>
                                    
                                    <div className="phone-input-wrapper">
                                        <label htmlFor="register-phone">Mobile Number</label>
                                        <div className="auth-input-container">
                                            <i className="fas fa-mobile" />

                                            <input autoComplete="phone" id="register-phone" type="text" name="phone" onChange={this.onChange_phone} value={phone} />

                                            {phone_validation_err?<p className="signup-error">{phone_validation_err}</p>:null}
                                        </div>
                                    </div>
                                    
                                    <div className="password-input-wrapper">
                                        <label htmlFor="register-password">Password</label>
                                        <div className="auth-input-container">
                                            <i className="fas fa-lock" />

                                            <input autoComplete="new-password" id="register-password" type={show_new_password?"text":"password"} name="password" onChange={this.onChange_password} value={signup_password} />

                                            <i className={`far ${show_new_password?"fa-eye-slash":"fa-eye"}`} style={{left: "unset", right: 20, cursor: "pointer"}} onClick={this.onPress_show_new_password} />

                                            {pass_validation_err?<p className="signup-error">{pass_validation_err}</p>:null}
                                        </div>
                                        
                                    </div>

                                    <div className="confirm-password-input-wrapper">
                                        <label htmlFor="confirm-password">Confirm Password</label>
                                        <div className="auth-input-container">
                                            <i className="fas fa-lock" />

                                            <input autoComplete="new-password" id="confirm-password" type={show_confirm_password?"text":"password"} name="confirm-password" onChange={this.onChange_confirm_password} value={signup_confirm_password} />

                                            <i className={`far ${show_confirm_password?"fa-eye-slash":"fa-eye"}`} style={{left: "unset", right: 20, cursor: "pointer"}} onClick={this.onPress_show_confirm_password} />

                                            {confirm_pass_validation_err?<p className="signup-error">{confirm_pass_validation_err}</p>:null}
                                        </div>
                                    </div>
                                    
                                    <div className="account-type-wrapper">
                                        <span><input type="radio" name="account_type" id="tech" value="tech" checked={tech} onClick={this.onPress_technician} /> Technician</span>
                                        
                                        <span><input type="radio" name="account_type" id="comp" value="comp" checked={comp} onClick={this.onPress_company} /> Company</span>

                                        <span><input type="radio" name="account_type" id="cust" value="cust" checked={cust} onClick={this.onPress_customer} /> Customer</span>
                                    </div>

                                    <p className="signup-error">{account_type_err}</p>

                                    <div>
                                        <span><input type="checkbox" checked={agree_to_policy} onClick={this.onToggle_data_privacy} name="privacy" id="privacy-policy" /> I agree with My Service Titans' Data & Privacy policy.</span>
                                    </div>

                                    <div className="register-button-container">
                                        <button onClick={this.onPress_register.bind(this)}>Register <i className="fas fa-chevron-right" style={{color: "white"}} /></button>
                                        <small style={{display: "block"}}>Have an account already? <Link to="/auth/login">Log in</Link></small>
                                    </div>
                                </div>

                                {this.render_extra_signup_forms()}
                            </div>
                        </div>
                <div className="register-background">

                    <div className="register-foreground">
                    
                        <div className="register-foreground-text">
                            <h1>ELEVATE YOUR TECH CAREER</h1>
                            <h3>Exhibit your skills and get recruited by some of the best employers around. Register now, Signing up is easy</h3>
                        </div>

                      

                    </div>

                </div>
                
            </div>
        );
    }
}

function map_state_to_props({auth}){
    return ({auth})
}
 
export default connect(map_state_to_props, {login_or_signup, is_email_verified, verify_pass, verify_firstname, verify_lastname, verify_phone, verify_birth, verify_website_url, invalid_field, choose_account_type, process_input, existing_email, add_category_sign_up, add_city_sign_up, remove_category_sign_up, remove_city_sign_up, add_state_sign_up, remove_state_sign_up, close_nav, upload_profile_image, customer_photo_signup})(Register);