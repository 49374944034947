import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Form,
  Input,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.jsx";
import Search_list from "../../components/Search_list";

import {connect} from "react-redux";
import Company_item from "../../components/Profile/company_item";
import Employee_item from "../../components/Profile/employee_item";
import Search_company_item from "../../components/Profile/search_company_item";
import Pending_employee_item from "../../components/Profile/pending_employee_item";
import Unlinked_company_item from "../../components/Profile/unlinked_company_item";
import Unlinked_employee_item from "../../components/Profile/unlinked_employee_item";

import config from "../../config";
import {search_companies, request_employment, add_city, add_category, remove_city, add_state, remove_state,remove_category, user_edit_init_forms, user_edit_form_update, cancel_edit, save_user_edit, upload_profile_image, create_form_data} from "../../actions"

import Cities from "../../components/index/cities";
import Categories from "../../components/index/categories";
import States from "../../components/index/states";

class Profile extends React.Component {

  state = {matches: [], filtering: false, search_value: "", searching_companies: false}

  componentDidMount(){
    const {name, email, phone, photo_url, comp_name, website, cities, categories, states, description, opening, closing} = this.props.user;

    this.props.user_edit_init_forms({name, comp_name, email, phone, photo_url, website, cities, categories, states, description, opening, closing});
  }

  async onSearch_type({target: {value}}){
    const {type} = this.props.user;
    const regex = new RegExp(`^${value}`, "i");
    
    if(type === "tech"){
      const {companies} = this.props;

      const matches = await companies.filter((company) => {
        const regex_matches = company.comp_name.match(regex);
        
        if(regex_matches){
          return true;
        }else{
          return false;
        }
      });

      this.setState({matches, search_value: value})
    }else if(type === "comp"){
      const {employees} = this.props;
      const matches = await employees.filter((employee) => {
        const regex_matches = `${employee.name.first} ${employee.name.last}`.match(regex);
       
        if(regex_matches){
          return true;
        }else{
          return false;
        }
      });

      this.setState({matches, search_value: value});
    }

  }

  onSearch_companies({target: {value}}){
    if(value.length > 0 && value.trim !== ""){
      this.props.search_companies(value, true);
    }else{
      this.props.search_companies(value, false);
    }
  }

  onPress_cancel(){
    this.props.cancel_edit();
    this.props.history.push("/admin/user-profile");
  }

  async onPress_save(){
    const {name, comp_name, email, phone, photo_url, website, cities, categories, states, description, opening, closing} = this.props.user_edit;

    await this.props.save_user_edit({name, comp_name, email, phone, photo_url, website, categories, cities, states, description, opening, closing});

    this.props.history.push("/admin/user-profile");
  }

  on_change_firstname = ({target: {value: new_value}}) => {
    this.props.user_edit_form_update(["name", "first"], new_value);
  }

  on_change_comp_name = ({target: {value: new_value}}) => {
    this.props.user_edit_form_update(["comp_name"], new_value);
  }

  on_change_website = ({target: {value: new_value}}) => {
    this.props.user_edit_form_update(["website"], new_value);
  }

  on_change_lastname = ({target: {value: new_value}}) => {
    this.props.user_edit_form_update(["name", "last"], new_value);
  }

  on_change_email = ({target: {value: new_value}}) => {
    this.props.user_edit_form_update(["email"], new_value);
  }

  on_change_phone = ({target: {value: new_value}}) => {
    this.props.user_edit_form_update(["phone"], new_value);
  }

  on_change_description = ({target: {value: new_value}}) => {
    this.props.user_edit_form_update(["description"], new_value);
  }

  on_change_opening = ({target: {value: new_value}}) => {
    this.props.user_edit_form_update(["opening"], new_value);
  }

  on_change_closing = ({target: {value: new_value}}) => {
    this.props.user_edit_form_update(["closing"], new_value);
  }

  add_city(city){
    this.props.add_city(city);
  }

  add_category(category){
    this.props.add_category(category);
  }
  add_state(state){
    this.props.add_state(state);
  }
  remove_city(i, city){
    this.props.remove_city(i, city);
  }

  remove_category(i, category){
    this.props.remove_category(i, category);
  }
  remove_state(i, state){
    this.props.remove_state(i, state);
  }
  onChange_profile_image = async (e) => {

    this.form.submit();
    // let photoform = {};

    // const data = await create_form_data("profile_img", e.target.value, photoform);
    
    // console.log(data);
    // this.props.upload_profile_image(photoform);
  }

  render_profile_image(){
    const {photo_url, type} = this.props.user;
    
    if(photo_url){
      return(
        <img
          alt="..."
          className="img-circle rounded-circle img-thumbnail"
          style={{objectFit: "cover", width: 180, height: 180}}
          src={`${config.real_backend_url}/uploads/images/${photo_url}`}
        />
      )
    }else{
      if(type === "tech"){
        return(
          <img
            alt="..."
            className="img-circle rounded-circle img-thumbnail"
            style={{objectFit: "cover", width: 180, height: 180}}
            src={require("assets/images/profile_avatar.png")}
          />
        )
      }else if(type === "comp"){
        return(
          <img
            alt="..."
            className="img-circle rounded-circle img-thumbnail"
            style={{objectFit: "cover", width: 180, height: 180}}
            src={require("assets/images/company_avatar.png")}
          />
        )
      }
    }
  }

  onPress_image = (e) => {
    e.preventDefault();
    this.file_input.click();
  }

  render() {
    const {name, comp_name, type, website, email, phone, stars, jobs, description, birth, cities, categories, states, opening, closing} = this.props.user;

    const {companies, employees, pending_employees, searching, search_string, search_results, unlinked_companies, unlinked_employees, user_edit, referrals} = this.props;

    const {editing} = user_edit;

    const {filtering, matches, search_value, searching_companies} = this.state

    return (
      <>
        <Container style={{paddingTop: 78}} fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image rounded-circle">
                      <a  onClick={this.onPress_image}>
                        {this.render_profile_image()}
                      </a>

                      <form id="photo_form" action="http://104.168.170.207/api/user/upload/profile_image"
              method="POST"
            enctype="multipart/form-data" ref={(form => {this.form = form} )}>
                        <input onChange={this.onChange_profile_image} style={{display: "none"}} type="file" accept="image/*" id="profile_image_select" ref={(file_input) => {this.file_input = file_input}} name="profile_img" /></form>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    {/* <Button
                      className="mr-4"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Connect
                    </Button>
                    <Button
                      className="float-right"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Message
                    </Button> */}
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading">{stars}</span>
                          <i className="fas fa-star" style={{color: "#e7b136"}} />
                          <span className="description">stars</span>
                        </div>
                        <div>
                          <span className="heading">{jobs}</span>
                          <i className="fas fa-wrench" style={{color: "#e7b136"}} />
                          <span className="description">jobs</span>
                        </div>
                        {/* <div>
                          <span className="heading">89</span>
                          <span className="description">Comments</span>
                        </div> */}
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      {type==="tech"?`${name.first} ${name.last}`:comp_name}
                      {/* <span className="font-weight-light">, 27</span> */}
                    </h3>
                    {/* <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Bucharest, Romania
                    </div> */}
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      {type==="tech"?"Technician":"Company"}
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      {type==="tech"?new Date(birth).toLocaleDateString():<a target="_blank" href={website} className="text-warning">{website}</a>}
                    </div>
                    <div>
                      {referrals.filter((referral) => referral.accepted === true).length}
                      <p>Referrals</p>
                    </div>
                    <hr className="my-4" />
                    {editing?<Input type="textarea" value={editing?user_edit.description:description} onChange={this.on_change_description.bind(this)} />:<p>
                      {description || "No description."}
                    </p>}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {/* <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Settings
                      </Button> */}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              value={email}
                              placeholder="Email"
                              type="email"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={editing?user_edit.phone:phone}
                              id="input-phone"
                              placeholder="Phone"
                              type="text"
                              disabled={!editing}
                              onChange={editing?this.on_change_phone.bind(this):()=>{}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {type==="tech"?<><Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                              
                            </label>
                            {console.log(name.first)}
                            <Input
                              className="form-control-alternative"
                              value={editing?user_edit.name.first:name.first}
                              id="input-first-name"
                              placeholder="Firstname"
                              type="text"
                              disabled={!editing}
                              onChange={editing?this.on_change_firstname.bind(this):()=>{}}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={editing?user_edit.name.last:name.last}
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                              contentEditable={false}
                              disabled={!editing}
                              onChange={editing?this.on_change_lastname.bind(this):()=>{}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cities"
                            >
                              Cities
                            </label>
                            {/* <Input
                              className="form-control-alternative"
                              value={cities.reduce((prev, cur, i, arr) => {return prev.toString() + cur.toString() + (i!==(arr.length-1)?", ":"")}, "")}
                              id="input-cities"
                              placeholder="Cities"
                              type="text"
                              disabled
                            /> */}
                             <Search_list disabled={!editing} remove_func={this.remove_city.bind(this)} add_func={this.add_city.bind(this)} items={editing?user_edit.cities:cities} search_array={Cities} />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-states"
                            >
                              States
                            </label>
                            {console.log(user_edit.states)}
                            {/* <Input
                              className="form-control-alternative"
                              value={states.reduce((prev, cur, i, arr) => {return prev.toString() + cur.toString() + (i!==(arr.length-1)?", ":"")}, "")}
                              id="input-states"
                              placeholder="states"
                              type="text"
                              disabled
                            /> */}
                             <Search_list disabled={!editing} remove_func={this.remove_state.bind(this)} add_func={this.add_state.bind(this)} items={editing?user_edit.states:states} search_array={States} />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-categories"
                            >
                              Categories
                            </label>
                            {/* <Input
                              className="form-control-alternative"
                              value={categories.reduce((prev, cur, i, arr) => {return prev.toString() + cur.toString() + (i!==(arr.length-1)?", ":"")}, "")}
                              id="input-categories"
                              placeholder="Categories"
                              type="text"
                              disabled
                            /> */}
                            <Search_list disabled={!editing} remove_func={this.remove_category.bind(this)} add_func={this.add_category.bind(this)} items={editing?user_edit.categories:categories} search_array={Categories} search_property="label" />
                          </FormGroup>
                        </Col>
                      </Row></>:<><Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-comp-name"
                            >
                             Company Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={editing?user_edit.comp_name:comp_name}
                              id="input-comp-name"
                              placeholder="Name"
                              type="text"
                              disabled={!editing}
                              onChange={editing?this.on_change_comp_name.bind(this):()=>{}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-opening"
                            >
                              Opening
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={editing?user_edit.opening:opening}
                              id="input-closing"
                              placeholder="_ _:_ _ AM"
                              type="text"
                              disabled={!editing}
                              onChange={editing?this.on_change_opening.bind(this):()=>{}}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-closing"
                            >
                              Closing
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={editing?user_edit.closing:closing}
                              id="input-closing"
                              placeholder="_ _:_ _ PM"
                              type="text"
                              disabled={!editing}
                              onChange={editing?this.on_change_closing.bind(this):()=>{}}
                            />
                          </FormGroup>
                        </Col>
                      </Row></>}

                      {editing?<><Button color="primary" style={{backgroundColor: "#104464", border: "none"}} onClick={this.onPress_cancel.bind(this)}>CANCEL</Button><Button color="warning" onClick={this.onPress_save.bind(this)} style={{backgroundColor: "#e7b136", border: "none"}}>SAVE</Button></>:null}
                    </div>
                    {/* <hr className="my-4" />
                    {/* Address
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                              id="input-address"
                              placeholder="Home Address"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="New York"
                              id="input-city"
                              placeholder="City"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="United States"
                              id="input-country"
                              placeholder="Country"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Postal code
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              placeholder="Postal code"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Description
                    <h6 className="heading-small text-muted mb-4">About me</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          className="form-control-alternative"
                          placeholder="A few words about you ..."
                          rows="4"
                          defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                          Open Source."
                          type="textarea"
                        />
                      </FormGroup>
                    </div> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {type === "tech"?<Row className="mt-5">
              <Col lg="12">
                <div style={{textAlign: "center"}}>
                <Card>
                  <CardHeader className="border-0" style={{position: "relative"}}>
                    <h3>Companies</h3>

                    {/* <div style={{position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)"}}>
                      <div style={{display: "flex"}}>
                        <Input style={{transition: "all 0.2s linear", width: searching_companies?200:0, padding:searching_companies?"0.625rem 0.75rem":0, borderWidth: searching_companies?1:0}} onChange={this.onSearch_companies.bind(this)} innerRef={(input) => {this.search_company_input = input}} type="text" value={search_string} placeholder="Search Companies"/>

                        <button  onClick={() => {
                          if(!searching_companies){
                            this.search_company_input.focus();
                          }

                          this.setState({searching_companies: !searching_companies, filtering: false});
                        }} className="btn btn-warning" style={{backgroundColor: "#e7b136", border: "none",transition: "all 0.2s linear", marginLeft: "0.5rem"}}>{searching_companies?<i className={searching_companies?"fas fa-times":"fas fa-search"} />:"LINK COMPANY"}</button>
                      </div>
                    </div> */}

                    {/* <div style={{position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)"}}>
                      <div style={{display: "flex"}}>
                      <button onClick={() => {
                        if(!filtering){
                          this.search_input.focus();
                          this.onSearch_type({target:{value: search_value}});
                        }

                        this.setState({filtering: !filtering, searching_companies: false});
                        }} className="btn btn-warning" style={{backgroundColor: "#e7b136", border: "none"}}><i className={filtering?"fas fa-times":"fas fa-search"} /></button>

                      <Input style={{transition: "all 0.2s linear", width: filtering?200:0, padding:filtering?"0.625rem 0.75rem":0, borderWidth: filtering?1:0}} onChange={this.onSearch_type.bind(this)} innerRef={(input) => {this.search_input = input}} type="text" value={search_value} placeholder="Search your Companies"/>
                      </div>
                    </div> */}
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Company</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Hours</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {filtering?matches.map((company, i) => {
                        return <Company_item company={company} i={i} key={company._id} />
                      }):searching_companies?search_results.map((company, i) => {
                        return <Search_company_item company={company} i={i} key={company._id} />
                      }):companies.map((company, i) => {
                        return <Company_item company={company} i={i} key={company._id} />
                      })}
                      {unlinked_companies.map((company, i) => {
                        return <Unlinked_company_item company={company} i={i} key={company._id} />
                      })}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
                </Card>
                </div>
              </Col>
          </Row>:<Row className="mt-5">
          <Col lg="12">
            <div style={{textAlign: "center"}}>
            <Card>
              <CardHeader className="border-0" style={{position: "relative"}}>
                <h3>Technicians</h3>

                <div style={{position: "absolute", left: "10px", top: "50%", transform: "translateY(-50%)"}}>
                  <div style={{display: "flex"}}>
                  <button onClick={() => {
                    if(!filtering){
                      this.search_input.focus();
                      this.onSearch_type({target:{value: search_value}});
                    }

                    this.setState({filtering: !filtering});
                    }} className="btn btn-warning" style={{backgroundColor: "#e7b136", border: "none"}}><i className={filtering?"fas fa-times":"fas fa-search"} /></button>

                  {<Input style={{transition: "all 0.2s linear", width: filtering?200:0, padding:filtering?"0.625rem 0.75rem":0, borderWidth: filtering?1:0}} onChange={this.onSearch_type.bind(this)} innerRef={(input) => {this.search_input = input}} type="text" value={search_value} placeholder="Search Employees"/>}
                  </div>
                </div>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Technician</th>
                    <th scope="col">Referrals</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Stars</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {pending_employees.map((employee, i) => {
                    return <Pending_employee_item employee={employee} i={i} key={employee._id} />
                  })}
                  {filtering?matches.map((employee, i) => {
                    return <Employee_item employee={employee} i={i} key={employee._id} />
                  }):employees.map((employee, i) => {
                    return <Employee_item employee={employee} i={i} key={employee._id} />
                  })}
                  {unlinked_employees.map((employee, i) => {
                    return <Unlinked_employee_item employee={employee} i={i} key={employee._id} />
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className="disabled">
                    <PaginationLink
                      onClick={e => e.preventDefault()}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className="active">
                    <PaginationLink
                      onClick={e => e.preventDefault()}
                    >
                      1
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
            </Card>
            </div>
          </Col>
          </Row>}
        </Container>
      </>
    );
  }
}

function map_state_to_props({user, companies, employees, user_edit, referrals}){
  return {user, companies: companies.companies, unlinked_companies: companies.unlinked_companies, employees: employees.employees, unlinked_employees: employees.unlinked_employees, pending_employees: employees.pending_employees, searching: companies.searching, search_string: companies.search_string, search_results: companies.search_results, user_edit, referrals: referrals.referrals}
}

export default connect(map_state_to_props, {search_companies, request_employment, add_city, add_category, remove_city, remove_category, add_state, remove_state, user_edit_init_forms, user_edit_form_update, save_user_edit, cancel_edit, upload_profile_image})(Profile);
