import {SET_HISTORY_TECHNICIAN, SET_HISTOTY_JOBS, SET_HISTORY_COMPLETED_JOBS, SET_HISTORY_REVIEWS, SET_HISTORY_FROM_DISCOVER} from "./types";

export const set_history_technician = (technician) => {
    return ({type: SET_HISTORY_TECHNICIAN, payload: technician});
}

export const set_history_jobs = (jobs) => {
    return ({type: SET_HISTOTY_JOBS, payload: jobs});
}

export const set_history_reviews = (reviews) => {
    return ({type: SET_HISTORY_REVIEWS, payload: reviews})
}

export const set_history_completed_jobs_count = (completed_jobs_count) => {
    return ({type: SET_HISTORY_COMPLETED_JOBS, payload: completed_jobs_count});
}

export const set_history_from_discover = (bool) => {
    return ({type: SET_HISTORY_FROM_DISCOVER, payload: bool});
}