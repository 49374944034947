const regex = /(([A-Z]+[a-z]+[0-9]+)|([A-Z]+[0-9]+[a-z]+)|([a-z]+[0-9]+[A-Z]+)|([a-z]+[A-Z]+[0-9]+)|([0-9]+[A-Z]+[a-z]+)|([0-9]+[a-z]+[A-Z]+))/

export default (password = "") => {

    if(password.length < 5){
        return false;
    }
    
    if(!regex.test(password)){
        return false;
    }else{
        return true;
    }
}