import axios from "axios";
import {PROCESS_INPUT, LOGGED_IN, LOGGING_IN, LOGOUT, FORM_VIEW, LOGIN_ERROR, SIGNUP_ERROR, RETRY, CHOOSE_ACCOUNT_TYPE, /* ACCOUNT_ACTIVATED, */ /* RESEND_EMAIL, */ INVALID_FORMAT, ACCOUNT_TYPE, ADD_CITY_SIGN_UP, ADD_CATEGORY_SIGN_UP, REMOVE_CITY_SIGN_UP, REMOVE_CATEGORY_SIGN_UP, ADD_STATE_SIGN_UP, REMOVE_STATE_SIGN_UP,  CUSTOMER_PHOTO_SIGNUP} from "./types";
import valid_name from "../utils/valid_name";
import valid_email from "../utils/valid-email";
import valid_birth from "../utils/valid_birth";
import valid_password from "../utils/valid_password";
import valid_phone from "../utils/valid_phone_number";
import valid_url from "../utils/valid_website_url";
import config from "../config";

export const verify_pass = (password) => (dispatch) => {
    if(valid_password(password)){
        return true;
    }else{
        dispatch({type: INVALID_FORMAT, payload: "password"});
        return false;
    }
}

export const verify_phone = (phone) => (dispatch) => {
    if(valid_phone(phone)){
        return true;
    }else{
        dispatch({type: INVALID_FORMAT, payload: "phone"});
        return false;
    }
} 

export const verify_firstname = (name) => (dispatch) => {
    if(valid_name(name)){
        return true;
    }else{
        dispatch({type: INVALID_FORMAT, payload: "firstname"});
        return false;
    }
}

export const verify_lastname = (name) => (dispatch) => {
    if(valid_name(name)){
        return true;
    }else{
        dispatch({type: INVALID_FORMAT, payload: "lastname"});
        return false;
    }
}

export const verify_website_url = (url) => (dispatch) => {
    if(valid_url(url)){
        return true;
    }else{
        dispatch({type: INVALID_FORMAT, payload: "website"})
        return false;
    }
}

export const verify_birth = (birth) => (dispatch) => {
    if(valid_birth(birth)){
        return true;
    }else{
        alert("Must be over 18 to use this app.");
        dispatch({type: INVALID_FORMAT, payload: "birth"});
        return false;
    }
}

export const process_input = (key, value) => {
    return ({type: PROCESS_INPUT, payload: {key, value}});
};

export const add_city_sign_up = (city) => {
    return ({type: ADD_CITY_SIGN_UP, payload: city});
}

export const add_category_sign_up = (category) => {
    return ({type: ADD_CATEGORY_SIGN_UP, payload: category});
}
export const add_state_sign_up = (state) => {
    return ({type: ADD_STATE_SIGN_UP, payload: state});
}

export const remove_city_sign_up = (index, city) => {
    return ({type: REMOVE_CITY_SIGN_UP, payload: {index, city}});
}

export const remove_category_sign_up = (index, category) => {
    return ({type: REMOVE_CATEGORY_SIGN_UP, payload: {index, category}});
}
export const remove_state_sign_up = (index, state) => {
    return({type: REMOVE_STATE_SIGN_UP, payload: {index, state}});
}
export const customer_photo_signup = ({ photo_url}) =>{
    return ({type: CUSTOMER_PHOTO_SIGNUP, payload: {photo_url}});
}
export const signup = () => {
    return ({type: LOGGING_IN});
};

export const is_logging_in = (bool) => {
    return({type: LOGGING_IN, payload: bool});
}

export const existing_email = (email) => async (dispatch) => {
    if(valid_email(email)){
        const res = await axios.get(`${config.real_backend_url}/api/${encodeURIComponent(email)}/exists`).catch((err) => {
            alert(JSON.stringify(err));
            return null;
        });
    
        if(res.data.exists === true){
            alert("An account with this email already exist!");
            // dispatch({type: LOGIN_ERROR});
            return true;
        }
        return false;
    }else{
        dispatch({type: INVALID_FORMAT, payload: "email"});
        return null;
    }
}

export const resend_email = () => async (dispatch) => {
    const res = await axios.get(`${config.real_backend_url}/api/verify/email`, {withCredentials: true}).catch((err) => {
        alert(JSON.stringify(err));
        return null;
    });
    
    if(res.data !== null){
        alert("Verification email resent");
    //dispatch({type: RESEND_EMAIL, payload: res.data});
    }else{
        alert("There was a problem resending the email.")
    }
}

export const is_activated = () => async (dispatch) => {
    const res = await axios.get(`${config.real_backend_url}/api/isactivated`, {withCredentials: true}).catch((err) => {
        alert(err);
        return false;
    });
    dispatch({type: ACCOUNT_TYPE, payload: res.data.type})
    //alert(JSON.stringify(res.data));
    return res.data.activated;
}

export const login_or_signup = ({name, email, phone, birth, login_password, signup_password, email_phone, comp_name, /* photo_url, */ type, address, website, login_or_signup}) => async (dispatch) => {
    dispatch({type: LOGGING_IN, payload: true});
    const l_or_s = login_or_signup;
    if(l_or_s === "s"){
        //signup
        if(((name.first === "" || name.last === "") && comp_name === "" && type==="cust") || email === "" || phone === "" || (birth === "" && type === "tech") || signup_password === "" || (website === "" && type === "comp")/*|| email_phone === ""*/){
            // alert(JSON.stringify({name, email, phone, birth, login_password, signup_password, email_phone, comp_name, type, address, website, login_or_signup}));
            alert("Error signing up: all fields must be filled!");
            dispatch({type: LOGGING_IN, payload: false});
            return false;
        }
        const res = await axios.post(`${config.real_backend_url}/api/signup`, {
            name,
            email,
            phone,
            birth: birth,
            password: signup_password,
            type,
            address,
            website,
            comp_name,
        }).catch((err) => {
            alert (JSON.stringify(err));
            dispatch({type: LOGGED_IN});
            return false;
        });/* .then((resp) => {alert(resp)}).catch((err) => {
            //alert(err)
        }); */
        if(res.data.msg[0] === "s" || res.data.msg[0] === "0"){
            dispatch({type: LOGGED_IN});
            return true;
        }else{
            dispatch({type: SIGNUP_ERROR, payload: res.data.msg});
            alert(res.data.msg);
            return false;
        }

        //alert("s");
    }else if(l_or_s === "l"){
        //login
        if(login_password === "" || email_phone === ""){
            alert("Error logging in: all fields must be filled!");
            dispatch({type: LOGGING_IN, payload: false});
            return false;
        }

        if(valid_email(email_phone) || valid_phone(email_phone)){
            //if(valid_password(login_password)){
                const res = await axios.post(`${config.real_backend_url}/api/login`, {
                    name,
                    email_phone,
                    password: login_password
                }).catch((err) => {
                    alert (JSON.stringify(err));
                    dispatch({type: LOGGED_IN, payload: false});
                    return false;
                    });

                if(res.data.msg[0] === "s"){
                    dispatch({type: LOGGED_IN, payload: true});
                    return true;
                }else{
                    dispatch({type: LOGIN_ERROR, payload: res.data.msg});
                    return false;
                }
            // }else{
            //     dispatch({type: INVALID_FORMAT, payload: "password"});
            //     return false;
            // }
        }else{
            dispatch({type: INVALID_FORMAT, payload: "email_phone"});
            return false;
        }

        //alert("l");
    }

    dispatch({type: LOGGED_IN, payload: true});
    //return true;
};

export const choose_account_type = (type) => {
    return ({type: CHOOSE_ACCOUNT_TYPE, payload: type});
}

export const logout = () => async (dispatch) => {
    const res = await axios.get(`${config.real_backend_url}/api/logout`);
    if(res.data){
        console.log(res.data);
        dispatch({type: LOGOUT});
        return true;
    }else{
        return false
    }
};

export const switch_form_view = (choice) => {
    return ({type: FORM_VIEW, payload: {choice} });
};

export const retry = () => {
    return ({type: RETRY});
}

export const is_logged_in = () => async (dispatch) => {
    const res = await axios.get(`${config.real_backend_url}/api/current_user`, {withCredentials: true}).catch((err) => {
        //alert(JSON.stringify(err));
        return null;
    });
    if(!res){
        return null;
    }
    //alert(JSON.stringify(res.data.user));
    if (res.data.user && res.data.user.activated){
        dispatch({type: LOGGED_IN, payload: true});
        dispatch({type: ACCOUNT_TYPE, payload: res.data.user.type});
        return true;
    }else{
        dispatch({type: LOGGED_IN, payload: false});
        return false;
    }
}

export const invalid_field = (field) => {
    return {type: INVALID_FORMAT, payload: field};
}
