import {FETCH_REFERRALS, SET_REFERRAL_TOTAL, JOB_REQUEST_SENT, SENDING_JOB_REQUEST, ACCEPT_JOB_REQUEST, REJECT_JOB_REQUEST} from "./types";
import Axios from "axios";
import config from "../config";


export const fetch_referrals = () => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/user/referrals`, {withCredentials: true}).catch((err) => {
        console.log(err);
    })

    if(!res){
        return null;
    }

    //console.log(res.data.reviews)
    dispatch({type: FETCH_REFERRALS, payload: res.data.referrals});
    dispatch({type: SET_REFERRAL_TOTAL, payload: res.data.referrals.length});
    
    return;
}

export const request_job = (technician, customer_details) => async (dispatch) => {
    dispatch({type: SENDING_JOB_REQUEST});
    const res = await Axios.post(`${config.real_backend_url}/api/discover/technician/refer`, {technician, customer_details});

    if(res.data.request_sent){
        dispatch({type: JOB_REQUEST_SENT});
        return true;
    }
}

export const accept_job_request = (referral_id) => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/job/request/accept/${referral_id}`, {withCredentials: true});

    if(res.data.accepted){
        dispatch({type: ACCEPT_JOB_REQUEST, payload: referral_id});
    }
}

export const reject_job_request = (referral_id) => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/job/request/reject/${referral_id}`, {withCredentials: true});

    if(res.data.rejected){
        dispatch({type: REJECT_JOB_REQUEST, payload: referral_id});
    }
}