import {DISCOVER_FORM_UPDATE, DISCOVER_SEARCH, DISCOVER_SEARCH_SUGGESTIONS, CLOSE_DISCOVER, JOB_REQUEST_SENT, SENDING_JOB_REQUEST, DISCOVER_CLICKED_TECHNICIAN, CLOSE_DISCOVER_TECH_MODAL, DISCOVER_CLICKED_COMPANY, CLOSE_DISCOVER_COMP_MODAL, GET_TOP_TECHS, INCREMENT_DISCOVER_TABLE_INDEX, DECREMENT_DISCOVER_TABLE_INDEX, JOB_REQUEST_FULLY_COMPLETE, SET_REQUESTED_TECHNICIAN, OPEN_JOB_REQUEST_MODAL, CLOSE_JOB_REQUEST_MODAL, DISCOVER_INVALID_EMAIL, DISCOVER_INVALID_NAME, DISCOVER_INVALID_PHONE, DISCOVER_RESULTS_CLICKED_TECHNICIAN, DISCOVER_RESULTS_CLOSE_TECHNICIAN, DISCOVER_RESULTS_CHANGE_VIEW} from "../actions/types";

const INITIAL_STATE = {technicians: [], city_suggestions: [], category_suggestions: [], state_suggestions:[], name: "", state: "", city: "", category: "", email: "", phone: "", description: "", request_sent: null, technician_clicked: false, clicked_technician: null, company_clicked: false, clicked_company: null, top_techs: [], table_index: 0, table_rows: 10, requested_technician_id: null, open_job_request_modal: false, phone_validation_error: "", email_validation_error: "", name_validation_error: "", discover_results_clicked_technician: null, tab: "history"};

export default (state = INITIAL_STATE, {type, payload}) => {
    let new_state = {...state};
    let MAX_INCREMENT;
    
    switch(type){
        case DISCOVER_FORM_UPDATE:
            if(payload.key === "name"){
                new_state.name_validation_error = "";
            }else if(payload.key === "email"){
                new_state.email_validation_error = "";
            }else if(payload.key === "phone"){
                new_state.phone_validation_error = "";
            }

            new_state[payload.key] = payload.value;
        break;

        case SENDING_JOB_REQUEST:
            new_state.request_sent = false;
        break;

        case DISCOVER_SEARCH:
            new_state.discover_results_clicked_technician = null;
            new_state.tab = "history";
            let sorted_technicians = payload.technicians.sort((a, b) => {
                return b.reviews.map((review)=>review.star_rating).reduce((prev, curr) => {return prev+curr}, 0) - a.reviews.map((review)=>review.star_rating).reduce((prev, curr) => {return prev+curr}, 0);
            });

            new_state.technicians = sorted_technicians;
        break;

        case SET_REQUESTED_TECHNICIAN:
            new_state.requested_technician_id = payload;
        break;
            
        case DISCOVER_SEARCH_SUGGESTIONS:
            new_state[payload.key] = payload.suggestions;
        break;

        case DISCOVER_INVALID_EMAIL:
            new_state.email_validation_error = "Please enter a valid email!";
        break;

        case DISCOVER_INVALID_NAME:
            new_state.name_validation_error = "A valid name contains letters only!"
        break;

        case DISCOVER_INVALID_PHONE:
            new_state.phone_validation_error = "A valid phone number contains 9 to 10 digits only!"
        break;

        case JOB_REQUEST_SENT:
            new_state = {...INITIAL_STATE, technicians: state.technicians, city: state.city, category: state.category, state: state.state, request_sent: true}
        break;

        case JOB_REQUEST_FULLY_COMPLETE:
            new_state.request_sent = null;
        break;

        case DISCOVER_CLICKED_TECHNICIAN:
            new_state.clicked_technician = payload;
            new_state.technician_clicked = true;
        break;

        case DISCOVER_RESULTS_CLICKED_TECHNICIAN:
            if(state.discover_results_clicked_technician){
                new_state.discover_results_clicked_technician = null;
                new_state.tab = "history";
            }else{
                new_state.discover_results_clicked_technician = payload;
            }
        break;

        case DISCOVER_RESULTS_CLOSE_TECHNICIAN:
            new_state.discover_results_clicked_technician = null;
        break;

        case DISCOVER_RESULTS_CHANGE_VIEW:
            new_state.tab = payload;
        break;

        case DISCOVER_CLICKED_COMPANY:
            new_state.clicked_company = payload;
            new_state.company_clicked = true;
        break;

        case CLOSE_DISCOVER_TECH_MODAL:
            new_state.technician_clicked = false;
            new_state.clicked_technician = null;
        break;

        case CLOSE_DISCOVER_COMP_MODAL:
            new_state.company_clicked = false;
            new_state.clicked_company = null;
        break;

        case GET_TOP_TECHS:
            new_state.top_techs = payload;
        break;

        case INCREMENT_DISCOVER_TABLE_INDEX:
            MAX_INCREMENT = (new_state.technicians.length / (new_state.table_rows))-1;
            new_state.table_index = new_state.table_index < MAX_INCREMENT ? new_state.table_index + 1 : new_state.table_index;
        break;

        case DECREMENT_DISCOVER_TABLE_INDEX:
            new_state.table_index = new_state.table_index === 0 ? 0 : new_state.table_index - 1;
        break;

        case OPEN_JOB_REQUEST_MODAL:
            new_state.open_job_request_modal = true;
        break;

        case CLOSE_JOB_REQUEST_MODAL:
            new_state.open_job_request_modal = false;
        break;

        case CLOSE_DISCOVER:
            return INITIAL_STATE;

        default:
            return state
    }

    return new_state;
}