import {USER_EDIT_FORM_UPDATE, USER_EDIT_INIT_FORMS, SAVE_USER_EDIT, ADD_CITY, ADD_CATEGORY, REMOVE_CITY, REMOVE_CATEGORY, CANCEL_EDIT, ADD_STATE, REMOVE_STATE} from "../actions/types";
import Axios from "axios";
import config from "../config";

export const user_edit_init_forms = ({name, email, phone, photo_url, comp_name, website, cities, categories, states, opening, closing, description}) =>{
    return ({type: USER_EDIT_INIT_FORMS, payload: {name, email, phone, photo_url, comp_name, website, cities, categories, states ,opening, closing, description}});
}

export const user_edit_form_update = (keys, value) => {
    return ({type: USER_EDIT_FORM_UPDATE, payload: {keys, value}});
}

export const add_city = (city) => {
    return ({type: ADD_CITY, payload: city})
}

export const add_category = (category) => {
    return ({type: ADD_CATEGORY, payload: category})
}

export const add_state = (state) =>{
    return ({type: ADD_STATE, payload: state})
}

export const remove_city = (index, city) => {
    return ({type: REMOVE_CITY, payload: {index, city}})
}

export const remove_category = (index, category) => {
    return ({type: REMOVE_CATEGORY, payload: {index, category}})
}

export const remove_state = (index, state) => {
    return ({type: REMOVE_STATE, payload: {index, state}})
}
export const cancel_edit = () => {
    return ({type: CANCEL_EDIT});
}

export const save_user_edit = ({name, comp_name, email, phone, photo_url, website, cities, categories, states, opening, closing, description}) => async (dispatch) => {

    const res = await Axios.post(`${config.real_backend_url}/api/user/edit`, {name, comp_name, phone, cities, categories, states, website, opening, closing, description}, {withCredentials: true})

    if(res.data.edited){
        dispatch ({type: SAVE_USER_EDIT, payload: {name, comp_name, phone, website, cities, categories, states, opening, closing, description}});
        return true;
    }else{
        return false;
    }

}