import React, { Component } from 'react';
import {connect} from "react-redux";

import {job_creation_form_update, create_job, show_job_creation_modal, dismiss_job_creation_modal} from "../../actions";

import Categories from "../../components/index/categories";

import {Modal,
    ModalBody,
    Input,
    InputGroup,
    InputGroupAddon,
    Row,
    Col,
    Label } from "reactstrap";
import config from '../../config';

class Job_create_modal extends Component {
    state = {show_category_suggestion: false, category_selected: true, current_category_index: -1, job_create_error: "", show_job_create_error: false }

    componentDidMount(){
        const company = this.props.companies[0];
        this.props.job_creation_form_update("company", company?company._id:null)
    }

    dismiss_job_creation_modal = () => {
        this.props.dismiss_job_creation_modal();
    }

    onChange_category = ({target: {value}}) => {
        this.setState({category_selected: false, show_category_suggestion: true});
        this.props.job_creation_form_update("category", value);
    }

    onChange_job_name = ({target: {value}}) => {
        this.props.job_creation_form_update("name", value);
    }

    onChange_description = ({target: {value}}) => {
        this.props.job_creation_form_update("description", value);
    }

    render_company_image(){
        const company = this.props.companies[0];
        if(company){
            const {photo_url} = company;

            if(photo_url){
                return(
                    <img
                    alt="..."
                    className="img-circle rounded-circle img-thumbnail"
                    src={`${config.real_backend_url}/uploads/images/${photo_url}`}
                    />
            )
            }else{
                return(
                    <img
                        alt="..."
                        className="img-circle rounded-circle img-thumbnail"
                        src={require("assets/images/company_avatar.png")}
                    />
                )
            }
        }else{
            return null;
        }
    }

    render_category_suggestions(){
        const {category} = this.props.job_create;
        const {show_category_suggestion, category_selected, current_category_index} = this.state;

        if(((category.length > 0 || show_category_suggestion) && category_selected===false) || show_category_suggestion){
            this.match_categories = Categories.filter((c) => {
                return c.label.toUpperCase().includes(category.toUpperCase());
            })
            return this.match_categories.map((ca, i) => {
                return (
                    <p className={`suggestion ${i===current_category_index?"suggestion-select":""}`} onClick={() => {this.props.job_creation_form_update("category", ca.label); this.setState({category_selected: true, show_category_suggestion: false});}} key={ca+i}>{ca.label}</p>
                );
            })
        }
    }

    onKeyDown_category = (e) => {
        const {current_category_index} = this.state;

        if(e.which === 13 && current_category_index > -1){//enter
            this.props.job_creation_form_update("category", this.match_categories[current_category_index].label);
            this.setState({show_category_suggestion: !this.state.show_category_suggestion, category_selected: !this.state.category_selected});
        }else if(e.which === 38){//up
            if(current_category_index <= 0){
                this.setState({current_category_index: this.match_categories.length-1});
            }else{
                this.setState({current_category_index: this.state.current_category_index-1});
            }
        }else if(e.which === 40){//down
            if(current_category_index >= this.match_categories.length-1){
                this.setState({current_category_index: 0});
            }else{
                this.setState({current_category_index: this.state.current_category_index+1});
            }
        }
    }

    onPress_create_job = async () => {
        const company = this.props.companies[0];
        if(!company){
            this.setState({show_job_create_error: true, job_create_error: "You must be employed by a company to create a job!"});
            return;
        }

        await this.props.create_job({...this.props.job_create, location: {name: "unknown", lat: null, lon: null}});
        this.dismiss_job_creation_modal();
    }

    render() { 
        const {show_job_create_error, job_create_error, show_category_suggestion, category_selected, } = this.state;
        const {show_modal, job_create: {category, name, company:company_id, loading, description}} = this.props;
        const company = this.props.companies[0];
        return (
            <Modal isOpen={show_modal} toggle={this.dismiss_job_creation_modal}>
                <ModalBody className="create-job-modal">
                    <div>
                        <Modal toggle={()=>{}} isOpen={loading}>
                            <ModalBody>

                            </ModalBody>
                        </Modal>
                        <div>
                            <h1 style={{marginBottom: 20}}><span style={{borderBottom: "2px solid gray", paddingBottom: 10}}>Cre</span>ate A New Job</h1>

                            <h2 style={{marginBottom: 40}}>Tell us a bit about the job</h2>
                        </div>

                        {show_job_create_error?<p style={{color: "red", textAlign: "center"}}>{job_create_error}</p>:null}

                        <Row>
                            <Col className="job-form-col">
                                <Label>Job Name</Label>
                                <Input type="text" name="input" placeholder="Job Name" value={name} onChange={this.onChange_job_name} />

                                <div style={{position: "relative"}}>
                                    <Label>Category</Label>
                                    <span className="form-control" style={{display: "inline-block", position: "relative"}}>
                                    <input autoComplete="off" onFocus={() => {this.setState({category_selected: false, show_category_suggestion: true})}} type="text" name="category" placeholder="Category" onKeyDown={this.onKeyDown_category} value={category} onChange={this.onChange_category} ref={(input) => {this.category_input = input}} style={{width: "100%", height: "100%", border: "none"}} />
                                    
                                    <i onClick={() => {
                                        if(!show_category_suggestion){
                                            this.category_input.focus();
                                        }
                                        this.setState({show_category_suggestion: !this.state.show_category_suggestion , category_selected: !this.state.category_selected});
                                    }} style={{verticalAlign: "sub", cursor: "pointer", position: "absolute", right: 10, top: 15}} className={`ni ni-bold-${show_category_suggestion  || category_selected===false?"up":"down"} text-muted`} />
                                    </span>

                                    <div className="suggestion-container-hide-scrollbar"><div className="suggestion-container" style={{border: show_category_suggestion?"1px solid #aaa":"none", marginTop: 5, borderRadius: "0.375rem"}}>{this.render_category_suggestions()}</div></div>
                                </div>
                            </Col>

                            <Col className="job-form-col">
                                <Label>Company</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend" className="job-creation-company-input-prepend">
                                        <span className="job-creation-company-input-img">
                                            {this.render_company_image()}
                                        </span>
                                    </InputGroupAddon>
                                    <Input disabled  name="input" placeholder="company" defaultValue={company?company.comp_name:"no company"} />
                                </InputGroup>

                                <Label>Description</Label>
                                <Input type="textarea" name="Description" placeholder="Description" value={description} onChange={this.onChange_description} />
                            </Col>
                        </Row>

                        <div className="create-button-container">
                            <button onClick={this.onPress_create_job} className="create-button-slant"></button>
                            <button onClick={this.onPress_create_job} className="create-button">Create Job</button>
                        </div>

                        <button className="close-button" onClick={this.dismiss_job_creation_modal}>X</button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

function map_state_to_props({companies, job_create, jobs, user}) {
    return ({jobs: jobs.data, user, companies: companies.companies, job_create, show_modal: job_create.show_modal});
}
 
export default connect(map_state_to_props, {job_creation_form_update, create_job, show_job_creation_modal, dismiss_job_creation_modal})(Job_create_modal);