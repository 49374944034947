import React, { Component } from 'react';
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";

import {set_attempted_url} from "../actions";

class Protected_Route extends Component {
    state = {  }
    render() {
        return (
            <>
                {this.props.logged_in?<Route {...this.props}/>:<Redirect to={`/`} />}
            </>
        );
    }
}

function map_state_to_props({auth, app}){
    return {logged_in: auth.logged_in, attempted_url: app.attempted_url}
}

export default connect(map_state_to_props, {set_attempted_url})(Protected_Route);