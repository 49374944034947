import Index from "views/Index.jsx";
// import Profile from "views/examples/Profile.jsx";
import Maps from "views/examples/Maps.jsx";
// import Register from "views/examples/Register.jsx";
import Register from "./components/Register";
// import Login from "views/examples/Login.jsx";
import Login from "./components/Login";
import Tables from "views/examples/Tables.jsx";
import Icons from "views/examples/Icons.jsx";
import Jobs from "./views/examples/Jobs";
import Profile from "./components/Profile/Index";
import LeaderBoard from "./components/Leaderboard";
import Requests from "./components/Requests";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: Index,
    layout: "/admin"
  },
  // {
  //   path: "/icons",
  //   name: "Jobs",
  //   icon: "ni ni-planet text-white",
  //   component: Icons,
  //   layout: "/admin"
  // },
  {
    path: "/jobs",
    name: "Jobs",
    icon: "ni ni-briefcase-24",
    component: Jobs,
    layout: "/admin"
  },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/leaderboard",
    name: "LeaderBoard",
    icon: "ni ni-trophy",
    component: LeaderBoard,
    layout: "/admin"
  },
  // {
  //   path: "/requests",
  //   name: "request",
  //   icon: "ni ni-single-02 text-white",
  //   component: Requests,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-white",
  //   component: Tables,
  //   layout: "/admin"
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  }
];
export default routes;
