import {FETCH_USER, FETCHING_USER, FETCH_USER_ERROR, SET_JOBS, UPLOAD_PROFILE_IMG, ACCOUNT_TYPE} from "./types";
import config from "../config";
import Axios from "axios";

export const fetch_user = () => async (dispatch) => {
  dispatch({type: FETCHING_USER});
    const res = await Axios.get(`${config.real_backend_url}/api/user`, {withCredentials: true}).catch((err) => {
      console.log(err);
      dispatch({type: FETCH_USER_ERROR});
    });
    //alert(res);
    if(!res){
      return null;
    }
    if(res.data){
      get_completed_jobs(dispatch);
      // console.log(res.data);

      dispatch({type: ACCOUNT_TYPE, payload: res.data.user.type});
      dispatch({type: FETCH_USER, payload: res.data.user});
    }
}

export const upload_profile_image = (photo) => async (dispatch) => {
  const res = await Axios.post(`${config.real_backend_url}/api/user/upload/profile_image`, photo, {withCredentials: true}).catch((err) => {
    alert(JSON.stringify(err))
  });
console.log(res);
  if(res.data.uploaded){
    dispatch({type: UPLOAD_PROFILE_IMG, payload: res.data.url});
  }
}

const get_completed_jobs = async (dispatch) => {
  const res = await Axios.get(`${config.real_backend_url}/api/completed_jobs/amount`, {withCredentials: true}).catch((err) => {
    console.log(err);
    dispatch({type: FETCH_USER_ERROR});
  });

  dispatch({type: SET_JOBS, payload: res.data.amount});
}