import React, { Component } from 'react';
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media
} from "reactstrap";
import {connect} from "react-redux";

import config from '../../config';

class Pending_employee_item extends Component {
    state = {  }

    render_technician_image(){
        const {photo_url} = this.props.employee;
        if(photo_url){
            return(
              <img
                  className="img-circle rounded-circle img-thumbnail"
                  style={{height: "inherit", objectFit: "cover", padding: 0}}
                  alt="profile image"
                  src={`${config.real_backend_url}/uploads/images/${photo_url}`}
              />
            )
          }else{
              return(
                <img
                  className="img-circle rounded-circle img-thumbnail"
                  style={{height: "inherit", objectFit: "cover", padding: 0}}
                  alt="profile image"
                  src={require("assets/images/profile_avatar.png")}
                />
              )
          }
    }

    render() {
        const {name, email, phone} = this.props.employee;
        const {stars} = this.state;
        return (
            <tr>
                <th scope="row">
                    <Media className="align-items-center">
                        <a
                        className="avatar rounded-circle mr-3"
                        onClick={e => e.preventDefault()}
                        >
                            {this.render_technician_image()}
                        </a>
                        <Media>
                        <span className="mb-0 text-sm">
                            {`${name.first} ${name.last}`}
                        </span>
                        </Media>
                    </Media>
                </th>

                <td>N/A</td>

                <td>{email}</td>

                <td>{phone}</td>

                <td>
                    <i className="fas fa-star" style={{color: "#e7b136"}} /> N/A
                </td>

                <td className="text-right">
                    <div className="align-items-center">
                        <button className="btn btn-warning" style={{backgroundColor: "#e7b136", border: "none"}}>ACCEPT</button>
                        
                        <button disabled className="btn btn-danger">REJECT</button>
                    </div>
                </td>
            </tr>
        );
    }
}

function map_state_to_props({reviews}){
    return {reviews: reviews.reviews}
}
 
export default connect(map_state_to_props)(Pending_employee_item);