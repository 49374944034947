import React, { Component } from 'react';
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media
} from "reactstrap";
import {connect} from "react-redux";

import config from '../../config';

import {request_employment} from '../../actions';

class Search_company_item extends Component {
    state = {  }

    onPress_link(){
        const {company: {comp_name, email, _id:company_id}, name, _id:user_id, request_employment} = this.props;

        request_employment(comp_name, email, user_id, company_id, name);
    }

    render_company_image(){
        const {photo_url} = this.props.company;
        if(photo_url){
          return(
                <a
                    className="avatar avatar-sm"
                    onClick={e => e.preventDefault()}
                >
                    <img
                        className="rounded-circle"
                        alt="company image"
                        src={`${config.real_backend_url}/uploads/images/${photo_url}`}
                    />
                </a>
          )
        }else{
            return(
                <a
                    className="avatar avatar-sm"
                    onClick={e => e.preventDefault()}
                >
                    <img
                        className="rounded-circle"
                        alt="company image"
                        src={require("assets/images/company_avatar.png")}
                    />
                </a>
            )
        }
    }

    render() {
        const {comp_name, email, phone, opening, closing, _id:company_id} = this.props.company;
        const {requests} = this.props;

        let already_linked = requests.findIndex((req) => {
            return req.employer === company_id;
        }) !== -1;

        return (
            <tr>
                <th scope="row">
                    <Media className="align-items-center">
                        <a
                        className="avatar rounded-circle mr-3"
                        onClick={e => e.preventDefault()}
                        >
                            {this.render_company_image()}
                        </a>
                        <Media>
                        <span className="mb-0 text-sm">
                            {comp_name}
                        </span>
                        </Media>
                    </Media>
                </th>

                <td>{email}</td>

                <td>{phone}</td>

                <td>{`${opening || "N/A"} - ${closing || "NA"}`}</td>

                <td>
                {!already_linked?<button onClick={this.onPress_link.bind(this)} className="btn btn-warning" style={{backgroundColor: "#e7b136", border: "none"}}>LINK</button>:<button disabled className="btn btn-default" style={{backgroundColor: "grey", border: "none"}}>LINKED</button>}
                </td>
            </tr>
        );
    }
}

function map_state_to_props({user, requests}){
    return ({name: user.name, _id: user._id, requests: requests.requests});
}
 
export default connect(map_state_to_props, {request_employment})(Search_company_item);