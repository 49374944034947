import {JOB_REVIEW_FROM_UPDATE, SET_STAR_RATING, START_REVIEW, REVIEW_JOB, SET_TOTAL_ARR} from "../actions/types";

const INITIAL_STATE =  {review: "", rating: 0, job_id: null, company: null, title: "", total_ratings: []};

export default (state = INITIAL_STATE, {type, payload}) => {
    const new_state = {...state};

    switch(type){
        
        case JOB_REVIEW_FROM_UPDATE:
            // alert(payload.value);
            new_state[payload.key] = payload.value;
        break;

        case SET_STAR_RATING:
            new_state.rating = payload;
        break;

        case SET_TOTAL_ARR:
            new_state.total_ratings = payload;
        break;

        case START_REVIEW:
            new_state.job_id = payload.job_id;
            new_state.company = payload.company;
            new_state.title = payload.title;
        break;

        case REVIEW_JOB:
            return INITIAL_STATE;

        default:
            return state;

    }

    return new_state;
}