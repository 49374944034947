import {ACCEPT_REQUEST, REJECT_REQUEST, FETCH_REQUESTS, REQUEST_EMPLOYMENT, ADD_REQUEST} from "../actions/types";

const INITIAL_STATE = {requests: []};

export default (state = INITIAL_STATE, action) => {

    const {type, payload} = action;
    const new_state = {...state};
    let i;
    switch(type){

        case FETCH_REQUESTS:
            new_state.requests = payload;
        break;

        case ACCEPT_REQUEST:
            i = new_state.requests.findIndex((req) => {
                return req._id === payload.request_id;
            });

            if(i > -1){
                new_state.requests[i].accepted = true
                //employees.push(payload.employee);
            }

        break;

        case REJECT_REQUEST:
            i = new_state.requests.findIndex((req) => {
                return req._id === payload.request_id;
            });

            if(i > -1){
                new_state.requests[i].rejected = true;
            }
        break;

        case REQUEST_EMPLOYMENT:
            new_state.requests.push({
                employee: payload.employee,
                employer: payload.employer,
                accepted: false,
                rejected: false,
                created_at: Date.now()
            });

            new_state.requests = [...new_state.requests];
        break;

        case ADD_REQUEST:
            new_state.requests = [payload, ...new_state.requests];
        break;

        default:
            return state;
    }

    return new_state;
}