import React, { Component } from 'react';
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom"
import Job_creation_modal from "../../views/examples/Job_creation_modal"
import Employer_modal from "./employer_company_modal";

import config from '../../config';

import Categories from "../index/categories"

import {job_creation_form_update, create_job, show_job_creation_modal, show_employer_company_modal} from "../../actions"

import {Modal,
        ModalBody,
        Input,
        InputGroup,
        InputGroupAddon,
        Row,
        Col,
        Label } from "reactstrap"

class Profile_history_column extends Component {
    state = { show_job_create_modal: false, show_category_suggestion: false, category_selected: true, current_category_index: -1 };

    show_job_create_modal = () => {
        this.props.show_job_creation_modal();
    }

    date_string(date){
        const options = {weekday: "long", month: "short", day: "2-digit"};
        return new Date(date).toLocaleString("default", options);
    }

    render_company_image(photo_url){

        if(photo_url){
          return(
            <img
              alt="..."
              className="img-circle rounded-circle img-thumbnail"
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
            return(
              <img
                alt="..."
                className="img-circle rounded-circle img-thumbnail"
                src={require("assets/images/company_avatar.png")}
              />
            )
        }
    }

    onPress_company = () => {
        this.props.show_employer_company_modal();
    }

    render_actions(){
       return (
           <div className="history-column-actions">

               <div className="profile-actions">
                   <Link to="/admin/edit-profile" className="edit-button"><img src="/assets/images/icon_edit.png" /> Edit Profile</Link>

                   <button onClick={() => {this.props.history.push("/admin/leaderboard")}} className="stats-button"><img src="/assets/images/icon_graph_bar(blue).png" /> View Statistics</button>

                   <a className="company-button" onClick={this.onPress_company}><img src="/assets/images/icon_company_location.png" /> Company</a>
               </div>

               <div className="job-create-action">
                    <button className="create-job-button" onClick={this.show_job_create_modal}><img src="/assets/images/icon_jobs.png" /> Create New Job</button>
               </div>

           </div>
       ) 
    }

    render_jobs_history(){
        const {jobs, reviews, companies} = this.props;
        return jobs.filter((j) => {
            return j.status === "completed"
        }).map((job) => {
            const review = reviews.find((r) => {
                return r.job === job._id;
            });

            const company = companies.find((comp) => {
                return comp._id === job.company;
            });

            return (
                <li key={job._id}>
                    <div className="history-job">
                        <div className="job-company-info-container">
                            <div className="job-company-info">
                                {this.render_company_image(company?company.photo_url:"")}

                                <div className="job-info">
                                    <h3 className="job-title">{job.title}</h3>
                                    <small className="job-category">{job.category}</small>
                                </div>
                            </div>

                            <div className="job-rating-date">

                                {review?<div className="job-rating">
                                    {window.innerWidth >= 1200?[null, null, null, null, null].map((n, index) => {
                                        if(review.star_rating >= (index+1)){
                                            return <i key={index} className="fas fa-star" style={{color: "#f7bd4b"}} />
                                        }else{
                                            if(review.star_rating - index > 0){
                                                return <i key={index} className="fas fa-star-half" style={{color: "#f7bd4b"}} />
                                            }else{
                                                return <i key={index} className="fas fa-star" style={{color: "#efefef"}} />
                                            }
                                        }
                                    }):<>{(review.star_rating || 0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b"}} /></>}
                                </div>:<p>No Review</p>}

                                <div className="job-date">
                                    <small>{this.date_string(job.start_time)}</small>
                                </div>

                            </div>

                        </div>

                        <div className="job-images">
                            <img  className="before-image" alt="before" src={`${config.real_backend_url}/uploads/images/${job.image_urls.before[0]?job.image_urls.before[0].url:'null'}`} />

                            <img className="after-image" alt="after" src={`${config.real_backend_url}/uploads/images/${job.image_urls.after[0]?job.image_urls.after[0].url:'null'}`} />
                        </div>

                        <div className="job description">
                            <p>{job.description}</p>
                        </div>
                    </div>
                </li>
            )
        })
    }

    onChange_category = ({target: {value}}) => {
        this.setState({category_selected: false, show_category_suggestion: true});
        this.props.job_creation_form_update("category", value);
    }

    render_category_suggestions(){
        const {category} = this.props.job_create;
        const {show_category_suggestion, category_selected, current_category_index} = this.state;

        if(((category.length > 0 || show_category_suggestion) && category_selected===false) || show_category_suggestion){
            this.match_categories = Categories.filter((c) => {
                return c.label.toUpperCase().includes(category.toUpperCase());
            })
            return this.match_categories.map((ca, i) => {
                return (
                    <p className={`suggestion ${i===current_category_index?"suggestion-select":""}`} onClick={() => {this.props.job_creation_form_update("category", ca.label); this.setState({category_selected: true, show_category_suggestion: false});}} key={ca+i}>{ca.label}</p>
                );
            })
        }
    }

    onKeyDown_category = (e) => {
        const {current_category_index} = this.state;

        if(e.which === 13 && current_category_index > -1){//enter
            this.props.job_creation_form_update("category", this.match_categories[current_category_index].label);
            this.setState({show_category_suggestion: !this.state.show_category_suggestion, category_selected: !this.state.category_selected});
        }else if(e.which === 38){//up
            if(current_category_index <= 0){
                this.setState({current_category_index: this.match_categories.length-1});
            }else{
                this.setState({current_category_index: this.state.current_category_index-1});
            }
        }else if(e.which === 40){//down
            if(current_category_index >= this.match_categories.length-1){
                this.setState({current_category_index: 0});
            }else{
                this.setState({current_category_index: this.state.current_category_index+1});
            }
        }
    }

    render() {
        const company = this.props.companies[0];
        const {show_category_suggestion, category_selected} = this.state;
        const {category} = this.props.job_create;

        return (
            <div className="hisory-column-content-wrapper">
                {/* <Modal isOpen={this.state.show_job_create_modal} toggle={this.toggle_job_create_modal}>
                    <ModalBody className="create-job-modal">
                        <div>
                            <div>
                                <h1 style={{marginBottom: 20}}><span style={{borderBottom: "2px solid gray", paddingBottom: 10}}>Cre</span>ate A New Job</h1>

                                <h2 style={{marginBottom: 40}}>Tell us a bit about the job</h2>
                            </div>


                            <Row>
                                <Col className="job-form-col">
                                    <Label>Job Name</Label>
                                    <Input type="text" name="input" placeholder="Job Name" />

                                    <div style={{position: "relative"}}>
                                        <Label>Category</Label>
                                        <span className="form-control" style={{display: "inline-block", position: "relative", padding: "0 15px"}}>
                                        <input autoComplete="off" onFocus={() => {this.setState({category_selected: false, show_category_suggestion: true})}} type="text" name="category" placeholder="Category" onKeyDown={this.onKeyDown_category} value={category} onChange={this.onChange_category} ref={(input) => {this.category_input = input}} style={{width: "100%", height: "100%", border: "none"}} />
                                        
                                        <i onClick={() => {
                                            if(!show_category_suggestion){
                                                this.category_input.focus();
                                            }
                                            this.setState({show_category_suggestion: !this.state.show_category_suggestion , category_selected: !this.state.category_selected});
                                        }} style={{verticalAlign: "sub", cursor: "pointer", position: "absolute", right: 10, top: 15}} className={`ni ni-bold-${show_category_suggestion  || category_selected===false?"up":"down"} text-muted`} />
                                        </span>

                                        <div className="suggestion-container-hide-scrollbar"><div className="suggestion-container" style={{border: show_category_suggestion?"1px solid #aaa":"none", marginTop: 5, borderRadius: "0.375rem"}}>{this.render_category_suggestions()}</div></div>
                                    </div>
                                </Col>

                                <Col className="job-form-col">
                                    <Label>Company</Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend" className="job-creation-company-input-prepend">
                                            <span className="job-creation-company-input-img">
                                                {this.render_company_image()}
                                            </span>
                                        </InputGroupAddon>
                                        <Input disabled  name="input" placeholder="company" defaultValue={company?company.comp_name:"no company"} />
                                    </InputGroup>

                                    <Label>Description</Label>
                                    <Input type="textarea" name="Description" placeholder="Description" />
                                </Col>
                            </Row>

                            <div className="create-button-container">
                                <button className="create-button-slant"></button>
                                <button className="create-button">Create Job</button>
                            </div>

                            <button className="close-button" onClick={this.toggle_job_create_modal}>X</button>
                        </div>
                    </ModalBody>
                </Modal> */}
                <Job_creation_modal />

                <Employer_modal />

                {this.render_actions()}

                <h2 style={{textAlign: "center", margin: "20px 0", color: "#888"}}>History</h2>

                <div className="jobs-history">
                    <ul>
                        {this.render_jobs_history()}
                    </ul>
                </div>
            </div>
        );
    }
}

function map_state_to_props({jobs, user, reviews, companies, job_create}) {
    return ({jobs: jobs.data, user, reviews: reviews.reviews, companies: companies.companies, job_create});
}
 
export default withRouter(connect(map_state_to_props, {job_creation_form_update, create_job, show_job_creation_modal, show_employer_company_modal})(Profile_history_column));