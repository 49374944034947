import {FETCH_COMPANIES, SEARCH_COMPANIES, SEARCH_STRING_UPDATE, SEARCHING_COMPANIES, ADD_COMPANY_AND_UPDATE, FETCH_UNLINKED_COMPANIES, UNLINK_COMPANY, SHOW_EMPLOYER_COMPANY_MODAL, CLOSE_EMPLOYER_COMPANY_MODAL} from "../actions/types";

const INITIAL_STATE = {companies: [], unlinked_companies: [], search_results: [], search_string: "", searching: false, show_company_modal: false};

export default (state = INITIAL_STATE, action) => {

    const {type, payload} = action;
    const new_state = {...state};

    switch(type){

        case ADD_COMPANY_AND_UPDATE:
            new_state.companies.push(payload.company)
        break;

        case FETCH_COMPANIES:
            new_state.companies = payload;
        break;

        case FETCH_UNLINKED_COMPANIES:
            new_state.unlinked_companies = payload;
        break;

        case SEARCH_COMPANIES:
            new_state.search_results = payload;
            new_state.searching = false;
        break;

        case SEARCH_STRING_UPDATE:
            new_state.search_string = payload;
        break;

        case SEARCHING_COMPANIES:
            new_state.searching = payload;
        break;

        case SHOW_EMPLOYER_COMPANY_MODAL:
            new_state.show_company_modal = true;
        break;

        case CLOSE_EMPLOYER_COMPANY_MODAL:
            new_state.show_company_modal = false;
        break;

        case UNLINK_COMPANY:
            const i = new_state.companies.findIndex((company) => {
                return company._id === payload.employer;
            });

            const unlinked_employee = new_state.companies.splice(i, 1);

            new_state.unlinked_companies.push(unlinked_employee[0]);
        break;

        default:
            return state;

    }

    return new_state;
}