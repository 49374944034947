import {FETCH_COMPANIES, SEARCH_COMPANIES, REQUEST_EMPLOYMENT, SEARCH_STRING_UPDATE, SEARCHING_COMPANIES, FETCH_REQUESTS, UNLINK_COMPANY, FETCH_UNLINKED_COMPANIES, SHOW_EMPLOYER_COMPANY_MODAL, CLOSE_EMPLOYER_COMPANY_MODAL} from "./types";

import Axios from "axios";
import config from "../config";

export const fetch_companies = () => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/employment/companies`, {withCredentials: true}).catch((err) => {
        alert(JSON.stringify(err));
        console.log(JSON.stringify(err));
    });

    if(res.data.success){
        // alert(JSON.stringify(res.data.companies))
        dispatch({type: FETCH_COMPANIES, payload: res.data.companies});
        dispatch({type: FETCH_UNLINKED_COMPANIES, payload: res.data.unlinked_companies});
    }
} 

export const search_companies = (search, should_search) => async (dispatch) => {
    dispatch({type: SEARCHING_COMPANIES, payload: true});
    dispatch({type: SEARCH_STRING_UPDATE, payload: search});
    if(search && should_search){
        const res = await Axios.get(`${config.real_backend_url}/api/companies/search?search=${search.trim()}`, {withCredentials: true}).catch((err) => {
            alert(JSON.stringify(err));
            console.log(JSON.stringify(err));
        });

        if(res.data.searched){
            dispatch({type: SEARCH_COMPANIES, payload: res.data.companies});
        }
    }
    dispatch({type: SEARCHING_COMPANIES, payload: false});

}

export const fetch_requests = () => async (dispatch) => {
    const res = await Axios.get(`${config.real_backend_url}/api/employment/get/requests`, {withCredentials: true});

    if(res.data.success){
        //alert(JSON.stringify(res.data.requests));
        dispatch({type: FETCH_REQUESTS, payload: res.data.requests});
        return;
    }
}

export const request_employment = (comp_name, email, _id, company_id, name) => async (dispatch) => {
    const res = await Axios.post(`${config.real_backend_url}/api/employment/request`, {comp_name, email, name, company_id}, {withCredentials: true}).catch((err) => {
        alert(JSON.stringify(err));
        console.log(JSON.stringify(err));
        return null;
    });

    if(res.data.requested){
        dispatch({type: REQUEST_EMPLOYMENT, payload: {
            employee: _id,
            employer: company_id,
        }});

        return true;
    }else{
        return false;
    }
}

export const show_employer_company_modal = () => {
    return ({type: SHOW_EMPLOYER_COMPANY_MODAL});
}

export const close_employer_company_modal = () => {
    return ({type: CLOSE_EMPLOYER_COMPANY_MODAL});
}

export const unlink_company = (company) => async (dispatch) => {
    const res = await Axios.post(`${config.real_backend_url}/api/company/unlink`, {company}, {withCredentials: true});

    if(res.data.unlinked){
        dispatch({type: UNLINK_COMPANY, payload: res.data.employment});
    }
}