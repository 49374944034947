//            1 sec    1 hour   1 day  1 year
const YEARS = (1000 * (60 * 60) * 24 * 365.25);

export default (birth) => {
    const date_diff = Date.now() - new Date(birth).getTime();

    if(date_diff < (18 * YEARS)){
        return false;
    }else{
        return true;
    }
}