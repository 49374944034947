import React, { Component } from 'react';
import {connect} from "react-redux";
import config from '../../config';

import "./profile.css"

import {
  UncontrolledTooltip
} from "reactstrap"

class Profile_header extends Component {
    state = {  }

    render_profile_image(){
        const {photo_url, type} = this.props.user;
        
        if(photo_url){
          return(
            <img
              alt="..."
              className={"img-circle rounded-circle img-thumbnail" + (type==="tech"?" technician-image":" company-image")}
              style={{objectFit: "cover", maxWidth: 180, maxHeight: 180, minWidth: 100, minHeight: 100, height: 180}}
              src={`${config.real_backend_url}/uploads/images/${photo_url}`}
            />
          )
        }else{
          if(type === "tech"){
            return(
              <img
                alt="..."
                className={"img-circle rounded-circle img-thumbnail" + (type==="tech"?" technician-image":" company-image")}
                style={{objectFit: "cover", maxWidth: 180, maxHeight: 180, minWidth: 100, minHeight: 100, height: 180}}
                src={require("assets/images/profile_avatar.png")}
              />
            )
          }else if(type === "comp"){
            return(
              <img
                alt="..."
                className={"img-circle rounded-circle img-thumbnail" + (type==="tech"?" technician-image":" company-image")}
                style={{objectFit: "cover", maxWidth: 180, maxHeight: 180, minWidth: 100, minHeight: 100, height: 180}}
                src={require("assets/images/company_avatar.png")}
              />
            )
          }
          else if(type === "cust"){
            return(
              <img
                alt="..."
                className={"img-circle rounded-circle img-thumbnail" + (type==="cust")}
                style={{objectFit: "cover", maxWidth: 180, maxHeight: 180, minWidth: 100, minHeight: 100, height: 180}}
                src={require("assets/images/Bitmap.png")}
              />
            )
          }
        }
    }

    render_name(){
        const {user: {type, name, comp_name}, companies} = this.props;

        if(type === "tech"){
            return (
                <>
                    <h1 className="profile-name">{`${name.first} ${name.last}`}</h1>
                    <p className="profile-comp-name">{companies[0]?companies[0].comp_name:"No Company"}</p>
                </>
            )
        }else if(type === "comp"){
            return (
                <h3 className="profile-name">{comp_name}</h3>
            )
        }
        else if(type === "cust"){
          return (
            <h1 className="profile-name">{`${name.first} ${name.last}`}</h1>
          )
      }
    }

    render_star(star, avg_rating){
      if(avg_rating > star){
          return <i className="fas fa-star" style={{color: "#f7bd4b"}} />
      }else{
          if(avg_rating - (star - 1) > 0){
              return <i className="fas fa-star-half-alt" style={{color: "#f7bd4b"}} />
          }else{
              return <i className="fas fa-star" style={{color: "#aaa"}} />
          }
      }
    }
    
    render_tech_profile() { 
      
        const {user: {stars, jobs, type, _id}, referrals} = this.props;
        return (
         
            <div className="profile-header">
              <div className="profile-header-overlay">
                <div className="profile-info-and-image">
                    {this.render_profile_image()}
                    <span className="profile-info">
                        {this.render_name()}
                    </span>
                </div>

                <div className="profile-stats">
                    {/* {(jobs?(stars/jobs):0).toFixed(1)} <i className="fas fa-star" color="#f7bd4b"/> */}

                    {window.innerWidth >= 900?<span id={"header-stars"+_id} style={{marginRight: 20}}>
                    {this.render_star(1, (stars/jobs))}
                    {this.render_star(2, (stars/jobs))}
                    {this.render_star(3, (stars/jobs))}
                    {this.render_star(4, (stars/jobs))}
                    {this.render_star(5, (stars/jobs))}
                    </span>:<span id={"header-stars"+_id}>{(stars||0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b", fontSize: 20}} /></span>}

                    {window.innerWidth >= 900?<UncontrolledTooltip delay={0} target={"header-stars"+_id}>
                      {(stars||0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b"}} />
                    </UncontrolledTooltip>:<UncontrolledTooltip delay={0} target={"header-stars"+_id}>
                      {this.render_star(1, (stars/jobs))}
                      {this.render_star(2, (stars/jobs))}
                      {this.render_star(3, (stars/jobs))}
                      {this.render_star(4, (stars/jobs))}
                      {this.render_star(5, (stars/jobs))}
                    </UncontrolledTooltip>}

                    <span>{jobs} <img className="spanner-icon" src="/assets/images/spanner_icon@2x.png" /> {/* <i className="fas fa-wrench" color="#f7bd4b"/> */}</span>

                    <span>{type === "tech"?referrals.filter((ref) =>{
                      return ref.accepted === true;
                    }).length:referrals.length} <img className="curve-arrow-icon" src="/assets/images/curved_arrow@2x.png" /> {/* <i className="fas fa-redo-alt" color="#f7bd4b"/> */}</span>
                </div>
                </div>
            </div>
        );
    }
    render_comp_profile() { 
      
      const {user: {stars, jobs, type, _id}, referrals} = this.props;
      return (
       
          <div className="profile-header">
            <div className="profile-header-overlay">
              <div className="profile-info-and-image">
                  {this.render_profile_image()}
                  <span className="profile-info">
                      {this.render_name()}
                  </span>
              </div>

              <div className="profile-stats">
                  {/* {(jobs?(stars/jobs):0).toFixed(1)} <i className="fas fa-star" color="#f7bd4b"/> */}

                  {window.innerWidth >= 900?<span id={"header-stars"+_id} style={{marginRight: 20}}>
                  {this.render_star(1, (stars/jobs))}
                  {this.render_star(2, (stars/jobs))}
                  {this.render_star(3, (stars/jobs))}
                  {this.render_star(4, (stars/jobs))}
                  {this.render_star(5, (stars/jobs))}
                  </span>:<span id={"header-stars"+_id}>{(stars||0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b", fontSize: 20}} /></span>}

                  {window.innerWidth >= 900?<UncontrolledTooltip delay={0} target={"header-stars"+_id}>
                    {(stars||0).toFixed(1)} <i className="fas fa-star" style={{color: "#f7bd4b"}} />
                  </UncontrolledTooltip>:<UncontrolledTooltip delay={0} target={"header-stars"+_id}>
                    {this.render_star(1, (stars/jobs))}
                    {this.render_star(2, (stars/jobs))}
                    {this.render_star(3, (stars/jobs))}
                    {this.render_star(4, (stars/jobs))}
                    {this.render_star(5, (stars/jobs))}
                  </UncontrolledTooltip>}

                  <span>{jobs} <img className="spanner-icon" src="/assets/images/spanner_icon@2x.png" /> {/* <i className="fas fa-wrench" color="#f7bd4b"/> */}</span>

                  <span>{type === "tech"?referrals.filter((ref) =>{
                    return ref.accepted === true;
                  }).length:referrals.length} <img className="curve-arrow-icon" src="/assets/images/curved_arrow@2x.png" /> {/* <i className="fas fa-redo-alt" color="#f7bd4b"/> */}</span>
              </div>
              </div>
          </div>
      );
  }
  render_cust_profile() { 
    const {user: {stars, jobs, type, _id}, referrals} = this.props;
    
    return (
     
        <div className="profile-header">
          <div className="profile-header-overlay">
            <div className="profile-info-and-image">
                {this.render_profile_image()}
                <span className="profile-info">
                    {this.render_name()}
                </span>
            </div>

            <div className="profile-stats">
                {/* {(jobs?(stars/jobs):0).toFixed(1)} <i className="fas fa-star" color="#f7bd4b"/> */}

            </div>
            </div>
        </div>
    );
}
render(){
  if(this.props.user.type  === "tech"){
      return this.render_tech_profile();
  }else if( this.props.user.type === "comp"){
      return this.render_comp_profile();
  }
  else if(this.props.user.type  === "cust"){
      return this.render_cust_profile();

  }
}
}

function map_state_to_props({user, companies, referrals}){
    return ({user, companies: companies.companies, referrals: referrals.referrals});
}
 
export default connect(map_state_to_props, null)(Profile_header);